import { TUser } from 'store/config/types';

const initial = {
  isLogin: false,
  token: '',
  user: undefined
};

const UserStore = (state = initial, action) => {
  switch (action.type) {
    case TUser.IS_LOGIN:
      return action.payload
        ? {
            ...state,
            isLogin: action.payload
          }
        : {
            ...state,
            ...initial
          };

    case TUser.TOKEN:
      return {
        ...state,
        token: action.payload
      };

    case TUser.USER_DATA:
      return {
        ...state,
        user: action.payload
      };

    case TUser.SET_PERSONAL_INFO: {
      const user = {
        ...state.user,
        [action.payload.name]: action.payload.value
      };
      return {
        ...state,
        user
      };
    }

    case TUser.PATCH_USER_DATA: {
      action.payload = Object.entries(action.payload).reduce((acm, [k, v]) => {
        if (v) return { ...acm, [k]: v };
        else return acm;
      }, {});
      const user = {
        ...state.user,
        ...action.payload
      };
      return {
        ...state,
        user
      };
    }

    default:
      return state;
  }
};

export default UserStore;
