import Styled from 'components/DashboardStock/styles';
import React, { useEffect, useState } from 'react';
import { DEFAULTS } from 'components/Stock/constants';
import { useTranslation } from 'react-i18next';
import useToast from 'hooks/useToast';
import moment from 'moment-jalaali';
import Modules from 'components/DashboardStock/modules';
import { useSelector } from 'react-redux';
import StocksAPI from 'services/apis/StocksAPI';
import { Spacer } from 'components/Register/styles';
import CustomTable from '../../../uikit/table';

moment.loadPersian({ dialect: 'persian-modern' });

const Tab1Module = ({ stats }) => {
  const { t } = useTranslation('dashboardStock');
  const [user, setUser] = useState(DEFAULTS.user);
  const userType = useSelector(state => state.User.user?.userType);
  const localStocksAPI = new StocksAPI();
  const toast = useToast();

  const [recentTransactionData, setRecentTransactionData] = useState([]);
  const [pendingStockData, setPendingStockData] = useState([]);
  const [pendingStocksTableQuery, setPendingStocksTableQuery] = useState({
    page: 0,
    size: 5,
    sort: 'date',
    asc: false
  });

  const [pendingStockLoading, setPendingStockLoading] = useState(true);
  const [recentTransactionLoading, setRecentTransactionLoading] =
    useState(true);

  const [recentTransactionTotalElements, setRecentTransactionTotalElements] =
    useState(0);
  const [pendingStockTotalElements, setPendingStockTotalElements] = useState(0);

  const pendingTransactionStatuses = {
    ISSUE: 'ساخت توکن',
    CREATE: 'ساخت تراکنش',
    TRANSFER_BUY: 'خرید سهم',
    TRANSFER_SELL: 'درخواست خروج از سهامداری',
    BURN: 'درخواست خروج از سهامداری'
  };
  const recentTransactionColumns = [
    {
      title: 'تاریخ آخرین سرمایه‌گذاری',
      key: 'lastDate',
      dataIndex: 'lastDate',
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs']
    },
    {
      title: 'مبلغ سرمایه‌گذاری',
      dataIndex: 'investmentPrice',
      key: 'investmentPrice',
      responsive: ['xxl', 'xl', 'lg', 'md'],
      render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'تعداد سهم',
      dataIndex: 'amount',
      key: 'amount',
      responsive: ['xxl', 'xl', 'lg', 'md'],
      render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'اقساط سرمایه‌گذاری',
      key: 'period',
      dataIndex: 'period',
      responsive: ['xxl', 'xl', 'lg']
    }
  ];
  const pendingStocksColumns = [
    {
      title: 'نوع سهام‌داری',
      key: 'userType',
      dataIndex: 'userType',
      responsive: ['xxl', 'xl', 'lg']
    },
    {
      title: 'تاریخ خرید',
      dataIndex: 'date',
      key: 'date',
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs']
    },
    {
      title: 'نوع درخواست',
      dataIndex: 'status',
      key: 'status',
      responsive: ['xxl', 'xl', 'lg'],
      render: status => <span>{pendingTransactionStatuses[status]}</span>
    },
    {
      title: 'تعداد سهم',
      key: 'amount',
      dataIndex: 'amount',
      responsive: ['xxl', 'xl', 'lg', 'md'],
      render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'مبلغ پرداختی (ریال)',
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      responsive: ['xxl', 'xl', 'lg', 'md'],
      render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'وضعیت تایید',
      key: 'status',
      dataIndex: 'status',
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
      render: status => (
        <>
          <span style={{ color: '#F461B7' }}>{statuses[status]}</span>
        </>
      )
    }
  ];

  const statuses = {
    CREATED: 'ساخته شده',
    MODIFIED: 'ویرایش شده',
    CANCELED: 'ملغی',
    EXPIRED: 'منقضی',
    PENDING_FOR_PAY: 'در انتظار پرداخت',
    PENDING_FOR_VERIFY_RECEIPT: 'در انتظار تایید فیش',
    PENDING_FOR_VERIFY: 'در انتظار تایید اوپراتور',
    PENDING_FOR_VERIFY_DOCS: 'در انتظار تایید مدارک',
    PENDING_FOR_VERIFY_MEMBERSHIP: 'در انتظار تایید عضویت',
    PENDING_FOR_VERIFY_TRANSACTION: 'در انتظار تایید تراکنش',
    PENDING_FOR_CREATE_IN_BC: 'در انتظار ساخت در بلاکچین',
    PENDING_FOR_CEO_SIGN: 'در انتظار امضا',
    PENDING_FOR_SIGN_IN_BC: 'در انتظار امضا در بلاکچین',
    REJECT_BC_SIGN: 'رد امضا بلاکچین',
    REJECT_BC: 'رد بلاکچین',
    APPROVED_BC: 'تایید بلاکچین',
    REJECT_SIGN: 'رد امضا',
    REJECT_MEMBERSHIP: 'رد عضویت'
  };

  const onCalcBar = (_precent, _type) => {
    const _perimeter = 2 * Math.PI * 45;
    const _fill = _perimeter - (_perimeter * _precent) / 100;
    return _type ? _perimeter : _fill;
  };

  const onCalcDeg = _precentBefore => (360 * _precentBefore) / 100;

  useEffect(() => {
    onGetUserStocks(userType !== 'None');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  useEffect(() => {
    if (!user.flag) onGetUserTypeStocks();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    loadRecentTransaction();
    loadPendingStocks({ page: 0 });
  }, []);

  const onGetUserStocks = (_flag = true) => {
    localStocksAPI.fetchUser().then(response => {
      if (response && response.parValue) {
        setUser({ ...response, flag: _flag });
      } else {
        toast(response.msg, 'error');
      }
    });
  };

  const onGetUserTypeStocks = () => {
    localStocksAPI.fetchUserType().then(response => {
      if (
        response &&
        response.maxMember &&
        response.minMember &&
        response.maxNonMember &&
        response.minNonMember
      ) {
        setUser({
          ...user,
          // eslint-disable-next-line
          max: response.maxNonMember,
          // eslint-disable-next-line
          min: response.minNonMember,
          flag: true
        });
      } else {
        toast(response.msg, 'error');
      }
    });
  };

  const loadRecentTransaction = () => {
    setRecentTransactionLoading(true);
    setRecentTransactionTotalElements(0);
    localStocksAPI
      .fetchStats()
      .then(response => {
        let recentTrans = {};

        if (Object(response).hasOwnProperty('investment')) {
          recentTrans.lastDate = response['investment'].lastDate.replace(
            /-/g,
            '/'
          );
          recentTrans.investmentPrice = response['investment'].investmentPrice;
          recentTrans.amount = response['investment'].amount;
          recentTrans.period = response['investment'].period;
          recentTrans.payedPrice = response['investment'].payedPrice;
          recentTrans.month = response['investment'].month;

          setRecentTransactionTotalElements(1);
          setRecentTransactionData([recentTrans]);
        }
        setRecentTransactionLoading(false);
      })
      .catch(response => {
        toast(response.msg, 'error');
        setRecentTransactionLoading(false);
      });
  };

  const loadPendingStocks = (
    { page, size, sort, asc } = {
      page: 0,
      size: 5,
      sort: 'date',
      asc: false
    }
  ) => {
    const query = {
      page: page ? page : 0,
      size: size ? size : 5,
      sort: sort ? sort : 'date',
      asc: asc ? asc : false
    };
    setPendingStockLoading(true);
    setPendingStockTotalElements(0);
    setPendingStocksTableQuery(query);
    localStocksAPI
      .fetchPendingStocks(query)
      .then(response => {
        setPendingStockData(
          response.content.map(item => ({
            date: item.date.replace(/-/g, '/'),
            userType: item.userType ? t(`userType.${item.userType}`) : '',
            amount: item.amount,
            parValuePerStock: item.parValuePerStock,
            registeredCapital: item.registeredCapital,
            totalPrice: item.totalPrice,
            sellPrice: item.sellPrice,
            status: item.status
          }))
        );
        setPendingStockTotalElements(response.totalElements);
        setPendingStockLoading(false);
      })
      .catch(response => {
        toast(response.msg, 'error');
        setPendingStockLoading(false);
      });
  };

  const reloadTable = () => {
    loadPendingStocks(pendingStocksTableQuery);
  };

  return (
    <>
      <Styled.StatsContainer>
        <div className={'text'}>
          <h3 className={'title'}>{t('box.mystat.title')}</h3>
          <Styled.Spacer size={80} />
          <div className={'data'}>
            <div className={'item'}>
              <span className={'label'}>{t('label.stat.1')}</span>
              <span className={'space'} />
              <span className={'value'}>
                {t(`user.type.${stats && stats.userType}`) ||
                  t('user.type.None')}
              </span>
            </div>
            <div className={'item'}>
              <span className={'label'}>{t('label.stat.2')}</span>
              <span className={'space'} />
              <span className={'value'}>
                {stats && stats.lastTime
                  ? stats.lastTime?.toString().replace(/-/g, '/')
                  : t('subtitle.stat.none')}
              </span>
            </div>
            <div className={'item'}>
              <span className={'label'}>{t('label.stat.3')}</span>
              <span className={'space'} />
              <span className={'value'}>
                {(stats && stats.totalStock) || 0}
              </span>
            </div>
            <div className={'item'}>
              <span className={'label'}>{t('label.stat.4')}</span>
              <span className={'space'} />
              <span className={'value'}>
                {stats && stats.totalPaid
                  ? stats.totalPaid
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : 0}
              </span>
            </div>
          </div>
        </div>
        <Styled.Chart>
          <svg viewBox="0 0 100 100" width="200" height="200">
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="transparent"
              strokeWidth="10"
              stroke="#008BFF"
              strokeDasharray={
                onCalcBar(
                  (user.max / (user.max + user.owned + user.pending)) * 100,
                  true
                ) || 0
              }
              strokeDashoffset={
                onCalcBar(
                  (user.max / (user.max + user.owned + user.pending)) * 100,
                  false
                ) || 0
              }
              style={{
                transform: `rotate(${onCalcDeg(0)}deg)`,
                transformOrigin: 'center'
              }}
            />

            <circle
              cx="50"
              cy="50"
              r="45"
              fill="transparent"
              strokeWidth="10"
              stroke="#9C9CBD"
              strokeDasharray={
                onCalcBar(
                  (user.owned / (user.max + user.owned + user.pending)) * 100,
                  true
                ) || 0
              }
              strokeDashoffset={
                onCalcBar(
                  (user.owned / (user.max + user.owned + user.pending)) * 100,
                  false
                ) || 0
              }
              style={{
                transform: `rotate(${onCalcDeg(
                  (user.max / (user.max + user.owned + user.pending)) * 100
                )}deg)`,
                transformOrigin: 'center'
              }}
            />

            <circle
              cx="50"
              cy="50"
              r="45"
              fill="transparent"
              strokeWidth="10"
              stroke="#F461B7"
              strokeDasharray={
                onCalcBar(
                  (user.pending / (user.max + user.owned + user.pending)) * 100,
                  true
                ) || 0
              }
              strokeDashoffset={
                onCalcBar(
                  (user.pending / (user.max + user.owned + user.pending)) * 100,
                  false
                ) || 0
              }
              style={{
                transform: `rotate(${onCalcDeg(
                  ((user.max + user.owned) /
                    (user.max + user.owned + user.pending)) *
                    100
                )}deg)`,
                transformOrigin: 'center'
              }}
            />
          </svg>
          <Styled.Spacer size={50} />
          <div className={'colors'}>
            <div className={'item total'}>
              <span className={'color'}>
                {user.max + user.owned + user.pending}
              </span>
              <span className={'text'}>{t('subtitle.chart_total')}</span>
            </div>
            <div className={'item reserved'}>
              <span className={'color'}>{user.pending}</span>
              <span className={'text'}>{t('subtitle.chart_reserved')}</span>
            </div>
            <div className={'item owned'}>
              <span className={'color'}>{user.owned}</span>
              <span className={'text'}>{t('subtitle.chart_owned')}</span>
            </div>
            <div className={'item enabled'}>
              <span className={'color'}>{user.max}</span>
              <span className={'text'}>{t('subtitle.chart_enabled')}</span>
            </div>
          </div>
        </Styled.Chart>
      </Styled.StatsContainer>

      <Spacer size={50} />

      <Modules.Stats stats={stats} />

      <Styled.StatsContainer>
        <div className={'text'}>
          <h3 className={'title'}>{t('box.yourInvestment.title')}</h3>
          <div style={{ width: '100%' }}>
            <CustomTable
              paginationBottom={'none'}
              data={recentTransactionData}
              columns={recentTransactionColumns}
              loading={recentTransactionLoading}
              onChange={loadRecentTransaction}
              total={recentTransactionTotalElements}
              defaultSort={'id'}
            />
          </div>
        </div>
      </Styled.StatsContainer>

      <Spacer size={50} />
      <Styled.StatsContainer>
        <div className={'text'}>
          <h3 className={'title'}>{t('box.pendingStock.title')}</h3>
          <div style={{ width: '100%' }}>
            <CustomTable
              paginationBottom={'bottomCenter'}
              data={pendingStockData}
              columns={pendingStocksColumns}
              loading={pendingStockLoading}
              onChange={loadPendingStocks}
              total={pendingStockTotalElements}
              defaultSort={'id'}
              onFinish={reloadTable}
            />
          </div>
        </div>
      </Styled.StatsContainer>
    </>
  );
};

export default Tab1Module;
