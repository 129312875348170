import {
  DEFAULTS,
  FETCH_WORDPRESS_DEFAULTS,
  FETCH_WORDPRESS_URLS
} from 'components/Stock/constants';
import Styled from 'components/Stock/styles';
import useToast from 'hooks/useToast';
import Loading from 'layouts/Loading/loading';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StocksAPI from 'services/apis/StocksAPI';
import Format from 'utils/Formatter';
import { Button, ConfigProvider, Modal, TimePicker } from 'antd';
import BankAPI from 'services/apis/BankAPI';
import 'antd/dist/antd.css';
import Uploader from '../../../uikit/Uploader';
import PlaceholderPhoto from 'assets/images/receipt.svg';
import fa_IR from 'antd/lib/locale/fa_IR';
import { DatePicker as DatePickerJalali } from 'antd-jalali';
import { useSelector } from 'react-redux';

const InvoiceModule = ({
  tab,
  price,
  min,
  max,
  enabled,
  owned,
  reserved,
  rule,
  onRule
}) => {
  const { t } = useTranslation('stock');
  const [data, setData] = useState({ amount: 0, price: 0, total: 0 });
  const [payment, setPayment] = useState({
    orderId: '',
    refId: '',
    mobNo: '',
    payUrl: '',
    method: '',
    amount: 0
  });
  const [isLoading, setIsLoading] = useState(false);
  const localStocksAPI = new StocksAPI();
  const PayForm = useRef(null);
  const toast = useToast();
  const [modalVisible, setModalVisible] = useState(false);
  const [payTypeModalVisible, setPayTypeModalVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [acceptedRules, setAcceptedRules] = useState(false);
  const [bankReceipt, setBankReceipt] = useState(DEFAULTS.bankReceipt);
  const [file, setFile] = useState('');
  const [banks, setBanks] = useState([]);
  const [payDate, setPayDate] = useState(null);
  const [payTime, setPayTime] = useState(null);
  const [canBuy, setCanBuy] = useState({
    can: false,
    reason: 'بارگیری سوابق...'
  });
  const [canSubmitReceipt, setCanSubmitReceipt] = useState(false);
  const [showCancelReceiptModal, setShowCancelReceiptModal] = useState(false);
  const [canShowSubmitReceipt, setCanShowSubmitReceipt] = useState(false);
  const [showReceiptGreenDialog, setShowReceiptGreenDialog] = useState(false);
  const [greenDialogText, setGreenDialogText] = useState('');
  const [receiveReceiptData, setReceiveReceiptData] = useState({
    date: '',
    amount: 0,
    totalPrice: 0,
    status: '',
    orderId: 0,
    refId: ''
  });
  const user = useSelector(state => state.User.user);
  const localBankAPI = new BankAPI();
  useEffect(() => {
    setData({ ...data, amount: min, price });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, min, max]);

  useEffect(() => {
    let _mounted = true;
    if (_mounted) {
      setBankReceipt(DEFAULTS.bankReceipt);
      onReceiveWaitingReceipt();
      onGetBanks();
      fetchRulesFromWP();
    }

    return () => {
      _mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   timeLeft > 0 &&
  //     setTimeout(() => {
  //       // if (timeLeft === 1) setTimeoutFinished(true);
  //       // !timeoutFinished && setTimeLeft(timeLeft - 1);
  //     }, 1000);
  // }, [timeLeft]);
  useEffect(() => {
    if (
      canShowSubmitReceipt &&
      bankReceipt?.bankId !== '' &&
      banks.length > 0
    ) {
      setBankReceipt({ ...bankReceipt, bankId: banks[0]?.id + '' });
    }
  }, [canShowSubmitReceipt]);
  useEffect(() => {
    if (data.amount) {
      setData({
        ...data,
        total:
          Math.round((data.amount * data.price + Number.EPSILON) * 1000) / 1000
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.amount]);
  useEffect(() => {
    if (payment.refId !== '') {
      setTimeout(() => {
        if (payment.method === 'ONLINE') {
          PayForm.current?.submit();
        } else {
          onReceiveWaitingReceipt();
        }
      }, 2000);
    }
  }, [payment]);
  useEffect(() => {
    if (bankReceipt) onReceiptValidation();
  }, [bankReceipt]);
  useEffect(() => {
    setBankReceipt({ ...bankReceipt, date: payDate + '' });
  }, [payDate]);
  useEffect(() => {
    setBankReceipt({ ...bankReceipt, time: payTime + '' });
  }, [payTime]);
  useEffect(() => {
    setBankReceipt({ ...bankReceipt, receiptImageId: file + '' });
  }, [file]);
  useEffect(() => {
    if (paymentMethod !== '') onSubmitPayment();
  }, [paymentMethod]);
  useEffect(() => {
    if (receiveReceiptData && receiveReceiptData.amount !== 0) {
      setShowReceiptGreenDialog(true);
      setCanShowSubmitReceipt(false);
      createGreenText();
    }
  }, [receiveReceiptData]);
  const onReceiptValidation = () => {
    if (
      bankReceipt.refId?.length > 0 &&
      bankReceipt.receiptImageId?.length > 0 &&
      bankReceipt.time?.length > 0 &&
      bankReceipt.date?.length > 0 &&
      bankReceipt.bankCode?.length > 0 &&
      bankReceipt.bankId?.length > 0 &&
      bankReceipt.orderId > -1 &&
      bankReceipt.saleReferenceId > -1 &&
      bankReceipt.amount > -1
    ) {
      setCanSubmitReceipt(true);
    } else {
      setCanSubmitReceipt(false);
    }
  };
  const onCalcBar = (_precent, _type) => {
    const _perimeter = 2 * Math.PI * 45;
    const _fill = _perimeter - (_perimeter * _precent) / 100;
    return _type ? _perimeter : _fill;
  };

  const onGetBanks = () => {
    localBankAPI.fetchBanks().then(response => {
      if (response && Array.isArray(response)) setBanks(response);
      else toast(t('notification.bank.error'), 'error');
    });
  };

  const onCalcDeg = _precentBefore => {
    return (360 * _precentBefore) / 100;
  };

  const onHandleChange = event => {
    if (!event.target.disabled) {
      const _mode = event.target.inputMode;
      const _name = event.target.name;
      const _value =
        _mode === 'numeric'
          ? parseInt(
              Format.changeNumberToEn(event.target.value).replace(/\D/, '')
            )
          : event.target.value;

      if (_name.includes('bankId')) {
        setBankReceipt({ ...bankReceipt, bankId: _value + '' });
      } else if (_name.includes('bankCode')) {
        setBankReceipt({
          ...bankReceipt,
          bankCode: document.getElementById('bankCode').value + ''
        });
      } else if (_name.includes('saleReferenceId')) {
        setBankReceipt({
          ...bankReceipt,
          saleReferenceId: document.getElementById('saleReferenceId').value
        });
      } else
        setData({
          ...data,
          [_name]:
            _name === 'amount' && _value > enabled
              ? enabled
              : (_value.length === max.toString().length && _value < min) ||
                isNaN(_value)
              ? min
              : _value
        });
    }
  };

  const onHandleBlur = event => {
    if (!event.target.disabled) {
      const _mode = event.target.inputMode;
      const _name = event.target.name;
      const _value =
        _mode === 'numeric'
          ? parseInt(
              Format.changeNumberToEn(event.target.value).replace(/\D/, '')
            )
          : event.target.value;

      setData({
        ...data,
        [_name]: _name === 'amount' && _value < min ? min : _value
      });
    }
  };

  const onHandleUp = () => {
    setData({
      ...data,
      amount: data.amount < enabled ? data.amount + 1 : enabled
    });
  };

  const onHandleDown = () => {
    setData({ ...data, amount: data.amount > min ? data.amount - 1 : min });
  };
  const fetchRulesFromWP = () => {
    fetch(FETCH_WORDPRESS_URLS[tab])
      .then(resp => resp.json())
      .then(data => {
        localStorage.setItem('publicRules', data.content.rendered);
      })
      .catch();
  };
  const onSubmitPayment = () => {
    setIsLoading(true);
    const _url =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
        ? `http://${window.location.hostname}:${window.location.port}`
        : process.env.REACT_APP_BASE_URL;

    const _body = {
      amount: data.amount,
      user_type: tab,
      payment_method: paymentMethod,
      success_url: `${_url}/dashboard/stock/result/1/`,
      failed_url: `${_url}/dashboard/stock/result/0/`
    };
    localStocksAPI.buyStock(_body).then(response => {
      setIsLoading(false);
      setAcceptedRules(false);
      if (response && response.refId) {
        setPayment({
          orderId: response.id,
          refId: response.refId,
          mobNo: response.mobNo,
          payUrl: response.payUrl,
          method: response.method,
          amount: response.amount
        });
      } else {
        toast(response.msg || t('notification.payment.error'), 'error');
      }
    });
  };
  const onSubmitReceipt = () => {
    // setIsLoading(true);
    const _body = bankReceipt;
    localStocksAPI.submitReceipt(_body).then(response => {
      if (response) {
        toast(response.msg, 'success');
        setCanShowSubmitReceipt(false);
        setCanSubmitReceipt(false);
        setCanSubmitReceipt(false);
        setBankReceipt(DEFAULTS.bankReceipt);
      } else {
        toast(response.msg || 'خطایی پیش آمده است', 'error');
      }
    });
  };
  const onCancelReceipt = orderId => {
    setIsLoading(true);
    localStocksAPI
      .cancelReceipt(orderId)
      .then(response => {
        response.msg = 'خطا در دریافت اطلاعات';
        if (response && response.code === 200) {
          setIsLoading(false);
          toast('پیش خرید با موفقیت لغو گردید', 'success');
          setCanSubmitReceipt(false);
          setReceiveReceiptData({
            date: '',
            amount: 0,
            totalPrice: 0,
            status: '',
            orderId: 0,
            refId: ''
          });
          setShowCancelReceiptModal(false);
          setBankReceipt(DEFAULTS.bankReceipt);
          setCanShowSubmitReceipt(false);
          setCanBuy({ can: true, reason: '' });
          setShowReceiptGreenDialog(false);
        } else {
          toast(response.msg, 'error');
          setIsLoading(false);
        }
      })
      .catch(resp => {
        toast(resp.msg, 'error');
        setIsLoading(false);
      });
  };

  const onReceiveWaitingReceipt = (
    { page, size, sort, asc } = {
      page: 0,
      size: 10,
      sort: 'date',
      asc: false
    }
  ) => {
    setCanBuy({ can: false, reason: 'بارگیری سوابق...' });
    const query = {
      page: page ? page : 0,
      size: size ? size : 10,
      sort: sort ? sort : 'date',
      asc: asc ? asc : false
    };
    setReceiveReceiptData({
      date: '',
      amount: 0,
      totalPrice: 0,
      status: '',
      orderId: 0,
      refId: ''
    });
    localStocksAPI
      .receiveWaitingReceipt(query)
      .then(response => {
        response.msg = 'خطا در دریافت اطلاعات';
        if (response && response.content) {
          if (response.content && response.content.length > 0) {
            setCanBuy({ can: false, reason: 'وجود سفارش باز' });
            let _data = response.content[0];
            setBankReceipt({
              ...bankReceipt,
              orderId: _data.orderId,
              refId: _data.refId,
              amount: _data.amount
            });
            setReceiveReceiptData({
              date: _data.date?.toString().replace(/-/g, '/'),
              amount: _data.amount,
              totalPrice: _data.totalPrice,
              status: _data.status,
              orderId: _data.orderId,
              refId: _data.refId
            });
          } else setCanBuy({ can: true, reason: '' });
        } else {
          toast(response.msg, 'error');
          setIsLoading(false);
          setCanBuy({ can: false, reason: 'نیاز به تازه سازی صفحه' });
        }
      })
      .catch(resp => {
        toast(resp.msg, 'error');
        setIsLoading(false);
      });
  };
  const createGreenText = () => {
    let gender = user?.companyName
      ? 'شرکت محترم'
      : user?.gender === 'male'
      ? 'جناب آقای'
      : 'سرکار خانم';
    let name = user?.companyName
      ? user?.companyName
      : user?.firstName + ' ' + user?.lastName;

    setGreenDialogText(
      gender +
        ' ' +
        name +
        '<br>' +
        ' تعداد ' +
        '<b>' +
        receiveReceiptData?.amount +
        '</b>' +
        ' سهم برای شما در تاریخ ' +
        '<b>' +
        receiveReceiptData?.date.replace('-', '/') +
        '</b>' +
        ' به مدت ' +
        '<b>8</b>' +
        ' ساعت رزرو گردیده است.'
    );
  };
  return (
    <>
      {canShowSubmitReceipt && (
        <Styled.StatsContainer>
          <div style={{ width: '100%' }}>
            <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>
              <b> ثبت فیش بانکی</b>
            </h3>
            <br />
            <div className={'items'}>
              <Styled.Fields>
                <Styled.Field size={'onThree'} field={'select'}>
                  <label htmlFor={'bankId0'} className={'label'}>
                    {t('receipt.bank')}
                  </label>
                  <select
                    id={'bankId'}
                    name={'bankId'}
                    className={'input'}
                    value={bankReceipt?.bankId}
                    disabled={false}
                    data-row={0}
                    onChange={onHandleChange}
                  >
                    <option value={0} disabled={true} hidden={true} />
                    {banks.map((_bank, _index) => {
                      return (
                        <option
                          name={'bankId_item_0' + _index}
                          key={'bankId_item_0' + _index}
                          value={_bank.id}
                        >
                          {_bank.name}
                        </option>
                      );
                    })}
                  </select>
                </Styled.Field>
                <Styled.Field size={'onThree'}>
                  <input
                    placeholder={t('receipt.bank.code')}
                    id={'bankCode'}
                    name={'bankCode'}
                    onChange={onHandleChange}
                    className={'input'}
                    inputMode={'numeric'}
                    maxLength={18}
                    disabled={false}
                  />
                </Styled.Field>
                <Styled.Field size={'onThree'}>
                  <input
                    placeholder={t('receipt.saleReferenceId')}
                    id={'saleReferenceId'}
                    name={'saleReferenceId'}
                    onChange={onHandleChange}
                    inputMode={'numeric'}
                    maxLength={18}
                    className={'input'}
                    disabled={false}
                  />
                </Styled.Field>
                <Styled.Field
                  size={'oneThree'}
                  style={{ height: '280px', border: 'none' }}
                >
                  <Styled.Fields>
                    <Styled.Field size={'full'}>
                      <ConfigProvider locale={fa_IR} direction="rtl">
                        <DatePickerJalali
                          format={'YYYY/MM/DD'}
                          id={'saleDate'}
                          name={'saleDate'}
                          placeholder={t('receipt.date')}
                          className={'params-settings-datepicker'}
                          onChange={date => setPayDate(date)}
                          style={{
                            width: '100%',
                            height: '100%',
                            border: 'none'
                          }}
                        />
                      </ConfigProvider>
                    </Styled.Field>
                    <Styled.Field size={'full'}>
                      <ConfigProvider locale={fa_IR} direction="rtl">
                        <TimePicker
                          format={'h:m'}
                          id={'saleDate'}
                          name={'saleTime'}
                          placeholder={t('receipt.time')}
                          className={'params-settings-datepicker'}
                          onChange={time => setPayTime(time)}
                          style={{
                            width: '100%',
                            height: '100%',
                            border: 'none'
                          }}
                        />
                      </ConfigProvider>
                    </Styled.Field>
                  </Styled.Fields>
                </Styled.Field>
                <Styled.Field
                  size={'half'}
                  style={{ height: '280px', border: 'none' }}
                >
                  <div size={'full'} style={{ width: '100%' }}>
                    <Uploader
                      key={'upload_item_receipt_' + payment?.orderId}
                      label={t('receipt.upload.title')}
                      placeholder={PlaceholderPhoto}
                      onSelect={_image => setFile(_image)}
                    />
                  </div>
                </Styled.Field>
              </Styled.Fields>
              <div style={{ direction: 'ltr' }}>
                <Button
                  id={'later'}
                  key="later"
                  style={{ marginRight: '10px', width: 'auto' }}
                  onClick={() => {
                    setShowReceiptGreenDialog(true);
                    setCanShowSubmitReceipt(false);
                  }}
                >
                  بعدا
                </Button>
                <Button
                  id={'send'}
                  key="send"
                  type="primary"
                  disabled={!canSubmitReceipt}
                  onClick={() => {
                    onSubmitReceipt();
                  }}
                >
                  ثبت
                </Button>
              </div>
            </div>
          </div>
        </Styled.StatsContainer>
      )}
      {showReceiptGreenDialog && (
        <Styled.StatsContainer
          style={{
            backgroundColor: 'rgb(208,255,208)',
            height: 'auto',
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingTop: '10px',
            paddingBottom: '0px',
            justifyContent: 'right'
          }}
        >
          <div className={'items'}>
            <b
              className={'item'}
              style={{ color: '#134602', opacity: '1', marginBottom: '50px' }}
            >
              ثبت فیش بانکی
            </b>
            <p
              className={'item'}
              style={{ color: '#464646', opacity: '1', marginTop: '5px' }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: greenDialogText
                }}
              />
            </p>

            <p className={'item'} style={{ color: '#ffc107', opacity: '1' }}>
              عدم ثبت و بارگزاری فیش در این بازه به منزله انصراف از پیش خرید می
              باشد
            </p>
            <div className={'item'} style={{ marginBottom: '15px' }}>
              <Button
                key="sendReceiptBtn"
                type="primary"
                style={{ marginLeft: '15px' }}
                onClick={() => {
                  setCanShowSubmitReceipt(true);
                  setShowReceiptGreenDialog(false);
                }}
              >
                ثبت و بارگزاری
              </Button>
              <Button
                key="cancelReceiptBtn"
                type="danger"
                onClick={() => {
                  setShowCancelReceiptModal(true);
                }}
              >
                انصراف از پیش خرید
              </Button>
            </div>
          </div>
        </Styled.StatsContainer>
      )}
      {(canShowSubmitReceipt || showReceiptGreenDialog) && (
        <Styled.Spacer size={50} />
      )}
      <Styled.Invoice>
        <Styled.Chart>
          <svg viewBox="0 0 100 100" width="200" height="200">
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="transparent"
              strokeWidth="10"
              stroke="#008BFF"
              strokeDasharray={onCalcBar((enabled / max) * 100, true) || 0}
              strokeDashoffset={onCalcBar((enabled / max) * 100, false) || 0}
              style={{
                transform: `rotate(${onCalcDeg(0)}deg)`,
                transformOrigin: 'center'
              }}
            />

            <circle
              cx="50"
              cy="50"
              r="45"
              fill="transparent"
              strokeWidth="10"
              stroke="#9C9CBD"
              strokeDasharray={onCalcBar((owned / max) * 100, true) || 0}
              strokeDashoffset={onCalcBar((owned / max) * 100, false) || 0}
              style={{
                transform: `rotate(${onCalcDeg((enabled / max) * 100)}deg)`,
                transformOrigin: 'center'
              }}
            />

            <circle
              cx="50"
              cy="50"
              r="45"
              fill="transparent"
              strokeWidth="10"
              stroke="#F461B7"
              strokeDasharray={onCalcBar((reserved / max) * 100, true) || 0}
              strokeDashoffset={onCalcBar((reserved / max) * 100, false) || 0}
              style={{
                transform: `rotate(${onCalcDeg(
                  ((enabled + owned) / max) * 100
                )}deg)`,
                transformOrigin: 'center'
              }}
            />
          </svg>
          <Styled.Spacer size={50} />
          <Styled.Divider />
          <Styled.Spacer size={20} />
          <div className={'colors'}>
            <div className={'item total'}>
              <span className={'color'}>{max}</span>
              <span className={'text'}>{t('subtitle.chart_total')}</span>
            </div>
            <div className={'item reserved'}>
              <span className={'color'}>{reserved}</span>
              <span className={'text'}>{t('subtitle.chart_reserved')}</span>
            </div>
            <div className={'item owned'}>
              <span className={'color'}>{owned}</span>
              <span className={'text'}>{t('subtitle.chart_owned')}</span>
            </div>
            <div className={'item enabled'}>
              <span className={'color'}>{enabled}</span>
              <span className={'text'}>{t('subtitle.chart_enabled')}</span>
            </div>
          </div>
        </Styled.Chart>
        <Styled.InvoiceContent>
          <div className={'items'}>
            <div className={'item'}>
              <div className={'key'}>{t('title.stock_count')}</div>
              <div className={'value'}>
                <input
                  id={'amount'}
                  name={'amount'}
                  className={'input'}
                  value={data.amount}
                  disabled={false}
                  maxLength={max.toString().length}
                  inputMode={'numeric'}
                  onBlur={onHandleBlur}
                  onChange={onHandleChange}
                />
                <div className={'icons'}>
                  <svg
                    className={`icon up ${
                      data.amount >= enabled ? 'disabled' : ''
                    }`}
                    viewBox="0 0 14 10"
                    onClick={onHandleUp}
                  >
                    <path d="M6.30694 0.348979L0.287339 7.6672C-0.0957792 8.13272 -0.0957792 8.88726 0.287338 9.35255C0.670115 9.81788 1.29094 9.81788 1.67369 9.35255L7.00002 2.87727L12.3263 9.35255C12.7093 9.81788 13.3301 9.81788 13.7128 9.35255C14.0957 8.88722 14.0957 8.1325 13.7128 7.66698L7.6931 0.348754C7.50162 0.116201 7.25093 -5.90022e-07 7.00002 -6.11957e-07C6.74921 -6.33884e-07 6.49833 0.116426 6.30694 0.348979Z" />
                  </svg>

                  <svg
                    className={`icon down ${
                      data.amount <= min ? 'disabled' : ''
                    }`}
                    viewBox="0 0 14 10"
                    onClick={onHandleDown}
                  >
                    <path d="M7.69306 9.43129L13.7127 2.11307C14.0958 1.64755 14.0958 0.893016 13.7127 0.427722C13.3299 -0.0376101 12.7091 -0.0376101 12.3263 0.427722L6.99998 6.90301L1.67367 0.427722C1.29071 -0.0376101 0.669944 -0.0376101 0.287199 0.427722C-0.0957328 0.893053 -0.0957328 1.64778 0.287199 2.1133L6.3069 9.43152C6.49838 9.66407 6.74907 9.78027 6.99998 9.78027C7.25079 9.78027 7.50167 9.66385 7.69306 9.43129Z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className={'item'}>
              <div className={'key'}>
                {t('title.stock_price')}
                <span>{t('subtitle.toman')}</span>
              </div>
              <div className={'value disabled'}>
                <input
                  id={'price'}
                  name={'price'}
                  className={'input'}
                  value={Format.thousandSeparator(data.price)}
                  disabled={true}
                  inputMode={'numeric'}
                  onChange={onHandleChange}
                />
              </div>
            </div>
            <div className={'item'}>
              <div className={'key'}>
                {t('title.stock_total_price')}
                <span>{t('subtitle.toman')}</span>
              </div>
              <div className={'value'}>
                <input
                  id={'total'}
                  name={'total'}
                  className={'input'}
                  value={Format.thousandSeparator(data.total)}
                  disabled={true}
                  inputMode={'numeric'}
                  onChange={onHandleChange}
                />
              </div>
            </div>
          </div>
          <Modal
            //Rule Dialog
            style={{ maxHeight: '60px' }}
            visible={modalVisible}
            onCancel={() => {
              setModalVisible(false);
              setAcceptedRules(false);
              setIsLoading(false);
            }}
            title={<h4 style={{ textAlign: 'center' }}>شرایط و قوانین</h4>}
            footer={[
              <Button
                key="back"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  setModalVisible(false);
                  setAcceptedRules(false);
                }}
              >
                لغو
              </Button>,
              <Button
                key="pay"
                type="primary"
                disabled={!acceptedRules}
                onClick={() => {
                  setModalVisible(false);
                  setPayTypeModalVisible(true);
                  setIsLoading(false);
                }}
              >
                ثبت سفارش
              </Button>
            ]}
          >
            <Styled.RuleScroll>
              <div>
                <p
                  style={{
                    textAlign: 'justify',
                    lineHeight: '20px',
                    textSize: '12px'
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: localStorage.getItem('publicRules')
                        ? localStorage.getItem('publicRules')
                        : FETCH_WORDPRESS_DEFAULTS[tab]
                    }}
                  />
                </p>
                <br />
                <p
                  style={{
                    textAlign: 'justify',
                    lineHeight: '20px',
                    textSize: '12px',
                    color: '#ff0000'
                  }}
                >
                  * برای ادامه سه رقم آخر کد ملی خود را وارد نمایید
                </p>
                <div>
                  <input
                    style={{
                      marginLeft: '5px',
                      marginRight: '5px',
                      maxWidth: '40px',
                      textAlign: 'center'
                    }}
                    type="text"
                    pattern="[0-9]+"
                    maxLength={'3'}
                    onChange={event => {
                      event.target.value === user?.nationalCode.substr(7, 3)
                        ? setAcceptedRules(true)
                        : setAcceptedRules(false);
                    }}
                  />
                  {user?.nationalCode.substr(0, 7)}
                </div>
              </div>
            </Styled.RuleScroll>
          </Modal>
          <Modal
            //Pay Type Select Dialog
            style={{ maxHeight: '60px' }}
            visible={payTypeModalVisible}
            onCancel={() => {
              setModalVisible(true);
              setAcceptedRules(true);
              setPayTypeModalVisible(false);
              setIsLoading(false);
            }}
            title={<h4 style={{ textAlign: 'center' }}>شیوه پرداخت</h4>}
            footer={[
              <Button
                key="receipt"
                type="primary"
                disabled={data.total <= 50000000}
                style={{ marginRight: '10px' }}
                onClick={() => {
                  setPayTypeModalVisible(false);
                  setPaymentMethod('BANK_RECEIPT');
                }}
              >
                ثبت فیش بانکی
              </Button>,
              <Button
                key="submit"
                type="primary"
                disabled={data.total > 50000000}
                onClick={() => {
                  setPayTypeModalVisible(false);
                  setPaymentMethod('ONLINE');
                }}
              >
                درگاه پرداخت
              </Button>
            ]}
          >
            {' '}
            <Styled.RuleNotScroll>
              <div>
                <p
                  style={{
                    textAlign: 'justify',
                    lineHeight: '20px',
                    textSize: '12px'
                  }}
                >
                  برای مبالغ بیش از 50 میلیون تومان بعلت وجود محدودیت های بانکی
                  تنها میتوانید از روش ثبت فیش بانکی اقدام نمایید همچنین برای
                  مبالغ کمتر از 50 میلیون تومان تنها میتوانید از طریق پرداخت
                  آنلاین از درگاه اینترنتی اقدام نمایید.
                </p>
              </div>
            </Styled.RuleNotScroll>
          </Modal>
          <Modal
            //cancel receipt Dialog
            style={{ maxHeight: '60px' }}
            visible={showCancelReceiptModal}
            onCancel={() => {
              setShowCancelReceiptModal(false);
            }}
            title={
              <h4 style={{ textAlign: 'center' }}>
                از لغو پیش خرید خود اطمینان دارید؟
              </h4>
            }
            footer={[
              <Button
                key="yesAndCancel"
                type="primary"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  onCancelReceipt(receiveReceiptData.orderId);
                }}
              >
                بله
              </Button>,
              <Button
                key="noNotCancel"
                type="primary"
                onClick={() => {
                  setShowCancelReceiptModal(false);
                }}
              >
                خیر
              </Button>
            ]}
          >
            <Styled.RuleNotScroll>
              <div>
                <p
                  style={{
                    textAlign: 'justify',
                    lineHeight: '20px',
                    textSize: '12px'
                  }}
                >
                  توجه نمایید در صورت لغو پیش پرداخت ممکن است ظرفیت فروش تکمیل
                  شده باشد و امکان خرید مجدد برای شما وجود نداشته باشد.
                </p>
              </div>
            </Styled.RuleNotScroll>
          </Modal>
          <div
            className={canBuy.can ? 'action' : 'action disabled'}
            onClick={() => {
              if (canBuy.can) setModalVisible(true);
            }}
          >
            {isLoading ? (
              <Loading color={'#ffffff'} />
            ) : canBuy.can ? (
              t('action.buy_final')
            ) : (
              canBuy.reason
            )}
          </div>
          <form
            ref={PayForm}
            method={'post'}
            action={payment.payUrl}
            target={'_self'}
            hidden={true}
          >
            <input
              id={'RefId'}
              name={'RefId'}
              hidden={true}
              defaultValue={payment.refId}
            />
            <input
              id={'mobileNo'}
              name={'MobileNo'}
              hidden={true}
              defaultValue={payment.mobNo}
            />
          </form>
        </Styled.InvoiceContent>
      </Styled.Invoice>
    </>
  );
};

export default InvoiceModule;
