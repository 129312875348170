export const Auth = {
  loginStep1: 'auth/login/user/step/1',
  loginStep2: 'auth/login/user/step/2',
  registerStep1: 'auth/step/1',
  registerStep2: 'auth/step/2',
  verifyMobile: 'auth/verify/mobile',
  StockDataIndex: 'auth/info_one'
};

export const User = {
  fetchProfile: 'user/profile',
  setPersonalInfo: 'user/profile/personal-info',
  setCompanyInfo: 'user/profile/company-info',
  changePassword: 'user/profile/change-password',
  setUserType: 'user/type',
  fetchMaritalStatus: 'user/values/marital-status',
  fetchEducationLevels: 'user/values/education-levels',
  fetchSacrificeStatus: 'user/values/sacrifice-status'
};

export const Location = {
  fetchProvinces: 'public/locations/ostan',
  fetchShahrestans: 'public/locations/shahrestan',
  fetchBakhshs: 'public/locations/bakhsh'
};

export const Bank = {
  fetchBanks: 'public/banks/all',
  fetchBank: 'user/bank/account',
  addBank: 'user/bank/account',
  deleteBank: 'user/bank/account/delete',
  editBank: 'user/bank/account/edit'
};

export const Files = {
  viewFiles: 'files',
  uploadFiles: 'files/upload'
};

export const Docs = {
  submitDocs: 'user/docs/submit',
  fetchTypes: 'user/docs/types',
  fetchDocs: 'user/docs/view',
  fetchStatus: 'user/docs/view/auth'
};

export const Stocks = {
  fetchUser: 'stock/statistics/user',
  fetchUserType: 'stock/statistics/user-type',
  fetchTotal: 'stock/statistics/total',
  buyStock: 'user/buy/stock',
  submitReceipt: 'user/payment/bank-receipt/submit',
  sellStock: 'user/sell/stock',
  fetchOfferings: 'user/transaction/list/offerings',
  receiveWaitingReceipt: 'user/transaction/bank-receipt/pending',
  cancelBankReceipt: 'user/payment/bank-receipt/cancel',
  fetchStats: 'user/dashboard/assets',
  fetchPendingStocks: 'user/transaction/list/pending',
  fetchHistory: 'user/transaction/list/history',
  fetchPapersList: 'user/paper/list',
  paperRequest: 'user/paper/request'
};

export const Payment = {
  generatePayment: 'pay',
  verifyPayment: 'pay/verify'
};
