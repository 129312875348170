import styled, { css, keyframes } from 'styled-components';

const ImageLoading = keyframes`
  0% , 100% {
    opacity: 1;
  }
  25%{
    opacity: 0.25;
  }
`;

const DotLoading = keyframes`
  0% , 10% {
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'absolute')};
  width: ${({ isRelative }) => (isRelative ? '100%' : 'auto')};
  padding: ${({ isRelative }) => (isRelative ? '50px' : '0 20px')};

  ${({ isFull }) =>
    isFull &&
    css`
      height: 100vh;

      img {
        height: 71px;
        width: 52px;
        animation: ${ImageLoading} 1s ease infinite;
      }
    `};
`;

const Content = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;

  & > span {
    width: 10px;
    height: 10px;
    background: ${({ color }) => (color ? color : '#5d55a1')};
    border-radius: 10px;
    animation: ${DotLoading} 1s ease infinite;
    &.dot1 {
      animation-delay: 0.25s;
    }
    &.dot2 {
      animation-delay: 0.5s;
    }
    &.dot3 {
      animation-delay: 0.75s;
    }
  }
`;

const Styled = {
  Container,
  Content
};

export default Styled;
