import { DEFAULTS } from 'components/Dashboard/constants';
import Styled from 'components/Dashboard/styles';
import useToast from 'hooks/useToast';
import Loading from 'layouts/Loading/loading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationAPI from 'services/apis/LocationsAPI';
import UserAPI from 'services/apis/UserAPI';
import Format from 'utils/Formatter';

const CompanyModule = ({ userData, provinces: ostans, onUpdate }) => {
  const { t } = useTranslation('dashboard');
  const [company, setCompany] = useState(DEFAULTS.company);
  const [companySelect, setCompanySelect] = useState(DEFAULTS.companySelect);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const [shahrestans, setShahrestans] = useState([]);
  const localLocationAPI = new LocationAPI();
  const localUserAPI = new UserAPI();
  const toast = useToast();

  useEffect(() => {
    setCompany({
      companyName: userData.companyName,
      companyRegisterNumber: userData.companyRegisterNumber || '',
      companySSNumber: userData.companySSNumber,
      companyEcoNumber: userData.companyEcoNumber,
      companyOstan: userData.companyAddress?.ostan?.id || 0,
      companyShahr: userData.companyAddress?.shahrestan?.id || 0,
      companyAddressDetail: userData.companyAddress?.detail,
      companyPostalCode: userData.companyAddress?.postalCode
    });
    setCompanySelect({
      companyOstan: userData.companyAddress?.ostan?.name || '',
      companyShahr: userData.companyAddress?.shahrestan?.name || ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (company.companyOstan !== 0) {
      setCompanySelect({ ...companySelect, companyShahr: '' });
      setCompany({ ...company, companyShahr: 0 });
      getSharestans(company.companyOstan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.companyOstan]);

  const onHandleChange = event => {
    if (!event.target.disabled) {
      const _mode = event.target.inputMode;
      const _name = event.target.name;
      const _value =
        _mode === 'numeric'
          ? Format.changeNumberToEn(event.target.value).replace(/\D/, '')
          : event.target.value;

      setCompany({ ...company, [_name]: _value });
      if (['companyOstan', 'companyShahr'].includes(_name)) {
        const _option =
          event.target.options[event.target.options.selectedIndex].text;
        setCompanySelect({ ...companySelect, [_name]: _option });
      }
    }
  };

  const getSharestans = _province => {
    localLocationAPI.fetchShahrestans(_province).then(response => {
      if (response && Array.isArray(response)) setShahrestans(response);
      else toast(t('notification.shahrestan.error'), 'error');
    });
  };

  const onSaveCompany = () => {
    if (isAddress) {
      setIsLoading(true);
      const _body = {
        ...company,
        companyOstan: parseInt(company.companyOstan),
        companyShahr: parseInt(company.companyShahr)
      };

      localUserAPI.setPersonalInfo(_body).then(response => {
        if (response && response.id) {
          if (onUpdate) onUpdate();
          setTimeout(() => {
            toast(t('notification.company.success'), 'success');
            setIsLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            toast(response.msg || t('notification.company.error'), 'error');
            setIsLoading(false);
          }, 1000);
        }
      });
    }
  };

  return (
    <Styled.Content>
      <Styled.Title>{t('form.title.company')}</Styled.Title>
      <Styled.Spacer size={15} />
      <Styled.Fields>
        <Styled.Field>
          <label htmlFor={'companyName'} className={'label'}>
            {t('form.label.companyName')}
          </label>
          <input
            id={'companyName'}
            name={'companyName'}
            className={'input'}
            value={userData?.companyName}
            disabled={true}
          />
        </Styled.Field>

        <Styled.Field>
          <label htmlFor={'companySSNumber'} className={'label'}>
            {t('form.label.companySSNumber')}
          </label>
          <input
            id={'companySSNumber'}
            name={'companySSNumber'}
            className={'input wide'}
            value={userData?.companySSNumber}
            disabled={true}
          />
        </Styled.Field>

        <Styled.Field>
          <label htmlFor={'companyRegisterNumber'} className={'label'}>
            {t('form.label.companyRegisterNumber')}
          </label>
          <input
            id={'companyRegisterNumber'}
            name={'companyRegisterNumber'}
            className={'input wide'}
            value={company.companyRegisterNumber}
            disabled={true}
          />
        </Styled.Field>

        <Styled.Field size={'half'}>
          <input
            id={'companyType'}
            name={'companyType'}
            className={'input'}
            value={t(`form.companyType.${userData?.companyType}`)}
            disabled={true}
          />
        </Styled.Field>

        <Styled.Field size={'quarter'}>
          <label htmlFor={'address'} className={'label'}>
            {t('form.label.address')}
          </label>
          <input
            id={'address'}
            name={'address'}
            className={`input ${isAddress ? 'active' : ''}`}
            value={
              !company.companyAddressDetail
                ? ''
                : t('form.full_address', {
                    ostan: companySelect.companyOstan,
                    shahrestan: companySelect.shahrestan,
                    detail: company.companyAddressDetail,
                    postalCode: company.companyPostalCode
                  })
            }
            disabled={true}
          />
          <span
            className={`action ${isAddress ? 'active' : ''}`}
            onClick={() => setIsAddress(!isAddress)}
          >
            <svg viewBox="0 0 26 26">
              <g clipPath="url(#clip0)">
                <path d="M23.997 16.333C23.6393 16.333 23.3494 16.6229 23.3494 16.9805V22.7299C23.3482 23.8024 22.4793 24.6715 21.4068 24.6725H3.23767C2.16519 24.6715 1.29633 23.8024 1.29507 22.7299V5.85584C1.29633 4.78361 2.16519 3.9145 3.23767 3.91323H8.98707C9.34474 3.91323 9.63461 3.62336 9.63461 3.2657C9.63461 2.90829 9.34474 2.61816 8.98707 2.61816H3.23767C1.45038 2.62019 0.00202355 4.06854 0 5.85584V22.7302C0.00202355 24.5175 1.45038 25.9658 3.23767 25.9679H21.4068C23.1941 25.9658 24.6425 24.5175 24.6445 22.7302V16.9805C24.6445 16.6229 24.3546 16.333 23.997 16.333Z" />
                <path d="M24.3824 0.853494C23.2444 -0.284498 21.3994 -0.284498 20.2614 0.853494L8.70899 12.4059C8.62982 12.4851 8.57265 12.5832 8.54281 12.691L7.02363 18.1756C6.96115 18.4004 7.02464 18.6412 7.18956 18.8064C7.35473 18.9713 7.59553 19.0348 7.8204 18.9726L13.305 17.4531C13.4127 17.4233 13.5109 17.3661 13.59 17.287L25.1422 5.73429C26.2784 4.59554 26.2784 2.75209 25.1422 1.61334L24.3824 0.853494ZM10.1199 12.8268L19.5747 3.3718L22.6239 6.42103L13.1689 15.876L10.1199 12.8268ZM9.51082 14.049L11.9469 16.4854L8.57721 17.419L9.51082 14.049ZM24.2266 4.81863L23.5398 5.50537L20.4903 2.45589L21.1773 1.76915C21.8094 1.13704 22.8344 1.13704 23.4665 1.76915L24.2266 2.52899C24.8576 3.16185 24.8576 4.18602 24.2266 4.81863Z" />
                {isAddress && (
                  <path d="M10.1258 12.9255L19.5805 3.47043L22.6298 6.51966L13.1747 15.9747L10.1258 12.9255Z" />
                )}
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="26" height="26" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </Styled.Field>

        {isAddress && (
          <>
            <Styled.Field field={'select'} isActive={true}>
              <label htmlFor={'companyOstan'} className={'label'}>
                {t('form.label.ostan')}
              </label>
              <select
                id={'companyOstan'}
                name={'companyOstan'}
                className={'input center'}
                value={company.companyOstan}
                disabled={!isAddress}
                onChange={onHandleChange}
              >
                <option value={0} disabled={true} hidden={true} />
                {ostans.map((_province, _index) => (
                  <option key={`state_item_${_index}`} value={_province.id}>
                    {_province.name}
                  </option>
                ))}
              </select>
            </Styled.Field>

            <Styled.Field field={'select'} isActive={true}>
              <label htmlFor={'companyShahr'} className={'label'}>
                {t('form.label.shahrestan')}
              </label>
              <select
                id={'companyShahr'}
                name={'companyShahr'}
                className={'input center'}
                value={company.companyShahr}
                disabled={company.companyOstan === 0}
                onChange={onHandleChange}
              >
                <option value={0} disabled={true} hidden={true} />
                {shahrestans.map((shahrestan, _index) => (
                  <option
                    key={`shahrestan_item_${_index}`}
                    value={shahrestan.id}
                  >
                    {shahrestan.name}
                  </option>
                ))}
              </select>
            </Styled.Field>

            <Styled.Field isActive={true}>
              <label htmlFor={'companyPostalCode'} className={'label'}>
                {t('form.label.postalCode')}
              </label>
              <input
                id={'companyPostalCode'}
                name={'companyPostalCode'}
                className={'input wide center'}
                value={company.companyPostalCode}
                disabled={!isAddress}
                maxLength={10}
                inputMode={'numeric'}
                onChange={onHandleChange}
              />
            </Styled.Field>

            <Styled.Field size={'full'} isActive={true}>
              <label htmlFor={'companyAddressDetail'} className={'label'}>
                {t('form.label.detail')}
              </label>
              <input
                id={'companyAddressDetail'}
                name={'companyAddressDetail'}
                className={'input center'}
                value={company.companyAddressDetail}
                disabled={!isAddress}
                onChange={onHandleChange}
              />
            </Styled.Field>
          </>
        )}

        <Styled.Action size={'full'}>
          <Styled.SaveButton isDisabled={!isAddress} onClick={onSaveCompany}>
            {isLoading ? <Loading color={'#fff'} /> : t('action.save_changes')}
          </Styled.SaveButton>
        </Styled.Action>
      </Styled.Fields>
    </Styled.Content>
  );
};

export default CompanyModule;
