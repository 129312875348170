import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { ColumnProps } from 'antd/lib/table/Column';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';

const CustomTable = ({
  paginationBottom,
  current,
  data,
  columns,
  className,
  forceSM,
  loading,
  total,
  onChange,
  rowClass = '',
  rowClassCounterPart = '',
  rowClassTriggerProperty = '',
  rowClassCounterTriggerProp = '',
  filters = {},
  asc = false,
  defaultSort
}: {
  paginationBottom: string;
  current?: number;
  data: Record<string, string>[];
  columns: ColumnProps<Record<string, string>>[];
  className?: string;
  forceSM?: boolean;
  loading: boolean;
  total?: number;
  onChange: Function;
  rowClass?: string;
  rowClassCounterPart?: string;
  rowClassTriggerProperty?: string;
  rowClassCounterTriggerProp?: string;
  filters?: {};
  asc?: boolean;
  defaultSort: string;
}) => {
  const [iData, setIData] = useState<Record<string, string>[]>([]);
  const [iColumns, setIColumns] = useState<
    ColumnProps<Record<string, string>>[]
  >([]);
  const [iResponsive, setIResponsive] = useState<boolean>(false);
  const [iHideColumns, setIHideColumns] = useState<string[]>([]);
  const breakpoints = useBreakpoint();

  useEffect(() => {
    setIData(
      data
        ? data.map((item, index) => {
            item.index = (index + 1).toString();
            item.key = index.toString();
            return item;
          })
        : []
    );
    let responsive = false;
    let hideColumns: string[] = [];
    let cols = [...(columns ? columns : [])].filter((column, index) => {
      if (!column.responsive) return true;
      const bString: Breakpoint[] = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];
      for (let breakpoint of bString) {
        if (breakpoints[breakpoint]) {
          if (column.responsive.includes(breakpoint)) return true;
          else break;
        }
      }
      responsive = true;
      if (column.dataIndex) hideColumns.push(column.dataIndex.toString());
      return false;
    });

    setIResponsive(responsive);
    setIHideColumns(hideColumns);
    cols.unshift({ title: '#', dataIndex: 'index' });
    setIColumns(cols);
  }, [breakpoints, data, columns]);

  const handleChange = (data1: any, data2: any) => {
    data2.order === undefined
      ? onChange({
          page: data1.current - 1,
          size: data1.pageSize,
          sort: defaultSort,
          asc,
          ...filters
        })
      : onChange({
          page: data1.current - 1,
          size: data1.pageSize,
          sort: data2.field,
          asc: data2.order === 'ascend',
          ...filters
        });
  };
  return (
    <>
      <Table
        size="small"
        rowClassName={(record: Record<string, any>) =>
          record[rowClassTriggerProperty]
            ? rowClass
            : record[rowClassCounterTriggerProp]
            ? rowClassCounterPart
            : ''
        }
        columns={iColumns}
        className={`${className} general-styled-table`}
        pagination={{
          //@ts-ignore
          position: ['none', paginationBottom],
          total: total,
          pageSize: 5,
          showSizeChanger: false,
          current
        }}
        //@ts-ignore
        dataSource={iData}
        loading={loading}
        //@ts-ignore
        onChange={(data1, data, data2) => handleChange(data1, data2)}
        //@ts-ignore
        expandable={
          iResponsive
            ? {
                //@ts-ignore
                expandedRowRender: (record: Record<string, string>) => (
                  <Row gutter={10}>
                    {iHideColumns.map(colDataIndex => {
                      let column = columns.find(
                        col => col.dataIndex == colDataIndex
                      );
                      if (!column) return <></>;
                      return (
                        <>
                          {column.dataIndex !== 'index' && (
                            <>
                              <Col
                                span={24}
                                xs={24}
                                style={{
                                  backgroundColor: '#fefefe',
                                  textAlign: 'right',
                                  maxWidth: '350px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  padding: '5px'
                                }}
                              >
                                <b>{column?.title?.toString()}</b> :{' '}
                                {column.dataIndex
                                  ? record[column.dataIndex.toString()]
                                  : ''}
                              </Col>
                            </>
                          )}
                        </>
                      );
                    })}
                  </Row>
                )
              }
            : undefined
        }
      />
    </>
  );
};

export default CustomTable;
