import PlaceholderDocs from 'assets/images/upload_docs.svg';
import PlaceholderPhoto from 'assets/images/upload_photo.svg';
import { STEPS, UPLOAD } from 'components/Authentication/constants';
import Styled from 'components/Authentication/styles';
import useToast from 'hooks/useToast';
import Loading from 'layouts/Loading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DocsAPI from 'services/apis/DocsAPI';
import UserAPI from 'services/apis/UserAPI';
import Steps from 'uikit/Steps';
import Uploader from 'uikit/Uploader';

const Step3Module = ({ userData, authStep, onStep }) => {
  const { t } = useTranslation('authentication');
  const userType = useSelector(state => state.User.user?.userType);
  const localUserAPI = new UserAPI();
  const [current, setCurrent] = useState(0);
  const [file, setFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const localDocsAPI = new DocsAPI();
  const toast = useToast();

  let FILTERED =
    userData.accountType === 'Natural'
      ? UPLOAD.filter(_upload => _upload.isPerson)
      : UPLOAD.filter(_upload => _upload.isLegal);
  /*for duty status*/
  for (let iii = 0; iii < FILTERED.length; iii++) {
    if (
      FILTERED[iii].maleType &&
      userData.gender === 'male' &&
      !FILTERED[iii]?.type.includes('DUTY_CARD_P_1') &&
      !FILTERED[iii]?.type.includes('DUTY_CARD_P_2')
    ) {
      //فقط کاربرعضو
      if (userType === 'Member') {
        if (userData.dutyStatus === 'AcademicExemption') {
          FILTERED[iii]?.files.push(
            'form.label.duty_card_front_academic_exemption'
          );
          FILTERED[iii]?.type.push('DUTY_CARD_P_1');
        } else {
          FILTERED[iii]?.files.push('form.label.duty_card_front_end_of_duty');
          FILTERED[iii]?.type.push('DUTY_CARD_P_1');
          FILTERED[iii]?.files.push('form.label.duty_card_back_end_of_duty');
          FILTERED[iii]?.type.push('DUTY_CARD_P_2');
        }
      }
    }
    // فقط کاربر عضو.
    if (
      FILTERED[iii].educationType &&
      !userData.educationLevel.includes('Illiterate') &&
      !userData.educationLevel.includes('ElementarySchool') &&
      !userData.educationLevel.includes('HighSchool') &&
      !userData.educationLevel.includes('MiddleSchool') &&
      !FILTERED[iii]?.type.includes('LAST_EDUCATION') &&
      userType === 'Member'
    ) {
      FILTERED[iii]?.files.push('form.label.last_education');
      FILTERED[iii]?.type.push('LAST_EDUCATION');
    }
  }
  // کاربر غیرعضو
  if (userType === 'NonMember') {
    FILTERED = FILTERED.map(upload => {
      if (upload.name === 'UPLOAD_DOC_ID_CARD') {
        upload.files = ['form.label.id_card_1_2'];
        upload.type = ['ID_CARD_P_1_2'];
      }
      return upload;
    });
    // کاربرعضو
  } else if (userType === 'Member') {
    FILTERED = FILTERED.map(upload => {
      if (upload.name === 'UPLOAD_DOC_ID_CARD') {
        upload.files = [
          'form.label.id_card_1_2',
          'form.label.id_card_3_4',
          'form.label.id_card_5_6'
        ];
        upload.type = ['ID_CARD_P_1_2', 'ID_CARD_P_3_4', 'ID_CARD_P_5_6'];
      }
      return upload;
    });
  }

  useEffect(() => {
    onGetUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (current !== 0) {
      onGenerateFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  const onValidation = () =>
    file.length === FILTERED[current - 1]?.files.length &&
    file.every(
      _image =>
        _image !== null && _image.file_id && typeof _image.file_id === 'string'
    );

  const onGenerateFiles = () => {
    const _file = [];
    _file[FILTERED[current - 1]?.files.length - 1] = null;
    _file.fill(null);
    setFile(_file);
  };

  const onHandleImage = (_image, _index, _type) => {
    const _file = [...file];
    _file[_index] =
      _image === null ? null : { file_id: _image, doc_type: _type };
    setFile(_file);
  };

  const onBackStep = () => {
    if (current > 1) {
      setCurrent(current - 1);
    } else {
      onStep(t(STEPS.find(_step => _step.id === 2)?.name));
    }
  };

  const onHandleNextStep = () => {
    if (onValidation()) {
      setIsLoading(true);
      onSubmitDocument();
    }
  };

  const onGetUserData = () => {
    localUserAPI.fetchProfile().then(response => {
      if (response && response.id) {
        let curr =
          FILTERED.findIndex(_step => _step.name === response.authStep) === -1
            ? 0
            : FILTERED.findIndex(_step => _step.name === response.authStep);
        setCurrent(curr + 1);
      }
    });
  };

  const onSubmitDocument = () => {
    const _body = {
      docs: file,
      auth: true
    };

    localDocsAPI.submitDocs(_body).then(response => {
      if (!!response & Array.isArray(response)) {
        if (!!onStep && current === FILTERED.length) {
          onStep(t(STEPS.find(_step => _step.id === 4)?.name));
        } else {
          setCurrent(current <= FILTERED.length ? current + 1 : 1);
          setIsLoading(false);
        }
      } else {
        toast(t('notification.upload.error'), 'error');
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <Styled.Box>
        <Styled.Content space={60}>
          <Styled.Description>
            <Steps total={FILTERED.length} current={current} />
            <Link to={'/'}>
              {t('form.upload.help')}
              <span className={'icon'} />
            </Link>
          </Styled.Description>
          <Styled.Spacer size={10} />

          {FILTERED[current - 1]?.files.map((_file, _index) => (
            <Uploader
              key={'upload_item_' + current + _index}
              label={t(_file)}
              placeholder={current === 1 ? PlaceholderPhoto : PlaceholderDocs}
              onSelect={_image =>
                onHandleImage(
                  _image,
                  _index,
                  FILTERED[current - 1].type[_index]
                )
              }
            />
          ))}
        </Styled.Content>
      </Styled.Box>

      <Styled.StepButtons>
        <Styled.StepButton onClick={onBackStep} isDisabled={false}>
          {t('action.prev_step')}
        </Styled.StepButton>
        <Styled.StepButton
          onClick={onHandleNextStep}
          isDisabled={!onValidation()}
        >
          {isLoading ? <Loading /> : t('action.next_step')}
        </Styled.StepButton>
      </Styled.StepButtons>
    </>
  );
};

export default Step3Module;
