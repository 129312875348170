import useToast from 'hooks/useToast';
import Header from 'layouts/Header';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import StocksAPI from 'services/apis/StocksAPI';
import { DEFAULTS, TABS } from './constants';
import Module from './modules';
import Styled from './styles';
import { ConfigProvider } from 'antd';
import faIR from 'antd/lib/locale/fa_IR';

const StockBuy = () => {
  const { t } = useTranslation('stock');
  const history = useHistory();
  const { tab } = useParams();
  const [user, setUser] = useState(DEFAULTS.user);
  const [rule, setRule] = useState({ dialog: false, accept: false });
  const userType = useSelector(state => state.User.user?.userType);
  const localStocksAPI = new StocksAPI();
  const toast = useToast();

  useEffect(() => {
    if (userType !== 'None') {
      const _tab = Object.keys(TABS).find(key => TABS[key] === userType);
      if (_tab !== tab) history.push(`/stock/buy/${_tab}`);
      else onGetUserStocks();
    } else {
      onGetUserStocks(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, userType]);

  useEffect(() => {
    if (!user.flag) onGetUserTypeStocks();
    // eslint-disable-next-line
  }, [user]);

  const onGetUserStocks = (_flag = true) => {
    localStocksAPI.fetchUser().then(response => {
      if (response && response.parValue) {
        setUser({ ...response, flag: _flag });
      } else {
        toast(response.msg, 'error');
        history.push('/stock/');
      }
    });
  };

  const onGetUserTypeStocks = () => {
    localStocksAPI.fetchUserType().then(response => {
      if (
        response &&
        Object(response).hasOwnProperty('maxMember') &&
        Object(response).hasOwnProperty('minMember') &&
        Object(response).hasOwnProperty('maxNonMember') &&
        Object(response).hasOwnProperty('minNonMember') &&
        Object(response).hasOwnProperty('maxNonMemberInvestor') &&
        Object(response).hasOwnProperty('minNonMemberInvestor')
      ) {
        if (tab == 1) {
          setUser({
            ...user,
            max: response.maxNonMember,
            min: response.minNonMember,
            parValue: response.parValueNonMember,
            flag: true
          });
        } else if (tab == 2) {
          setUser({
            ...user,
            max: response.maxNonMemberInvestor,
            min: response.minNonMemberInvestor,
            parValue: response.parValueNonMemberInvestor,
            flag: true
          });
        } else if (tab == 3) {
          setUser({
            ...user,
            max: response.maxMember,
            min: response.minMember,
            parValue: response.parValueMember,
            flag: true
          });
        }
      } else {
        toast(response.msg, 'error');
        history.push('/stock/');
      }
    });
  };

  return (
    <ConfigProvider locale={faIR} direction="rtl">
      <Styled.Container>
        <Header
          title={{
            color: rule.dialog
              ? t('title.rules.color')
              : t('title.select.color')
          }}
          subtitle={rule.dialog ? t('subtitle.rules') : t('subtitle.select')}
          star={true}
        />
        {!rule.dialog ? (
          <>
            <Styled.HelpLink href={'#'} target={'_blank'} rel={'noreferrer'}>
              {t('action.steps_help')}
            </Styled.HelpLink>
            <Styled.Spacer size={60} />
            <Module.Tabs tab={parseInt(tab) || 1} />
            <Styled.Spacer size={18} />
            <Module.Invoice
              tab={tab}
              price={user.parValue}
              min={user.min}
              max={user.max + user.owned + user.pending}
              enabled={user.max}
              owned={user.owned}
              reserved={user.pending}
              rule={rule}
              onRule={(_dialog, _accept) =>
                setRule({ dialog: _dialog, accept: _accept })
              }
            />
          </>
        ) : (
          <>
            <Styled.Spacer size={10} />
            <Module.Rules
              onRule={(_dialog, _accept) =>
                setRule({ dialog: _dialog, accept: _accept })
              }
            />
          </>
        )}
      </Styled.Container>
    </ConfigProvider>
  );
};

export default StockBuy;
