import styled from 'styled-components';

const Container = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #000;
  border-radius: 1em;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
`;

const Styled = {
  Container
};

export default Styled;
