import ApiHelper from 'services/config/http';
import { User } from 'services/config/url';

class UserAPI extends ApiHelper {
  fetchProfile = async () => {
    let result = {};
    await this.get(User.fetchProfile)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  setPersonalInfo = async body => {
    let result = {};
    await this.post(User.setPersonalInfo, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  setCompanyInfo = async body => {
    let result = {};
    await this.post(User.setCompanyInfo, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  changePassword = async body => {
    let result = {};
    await this.post(User.changePassword, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  setUserType = async body => {
    let result = {};
    await this.post(User.setUserType, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchSacrificeStatuses = async () => {
    let result = {};
    await this.get(User.fetchSacrificeStatus)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchEducationLevels = async () => {
    let result = {};
    await this.get(User.fetchEducationLevels)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
  fetchMaritalStatus = async () => {
    let result = {};
    await this.get(User.fetchMaritalStatus)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
}

export default UserAPI;
