import { TUser } from 'store/config/types';

const SetIsLogin = state => ({
  type: TUser.IS_LOGIN,
  payload: state
});

const SetToken = state => ({
  type: TUser.TOKEN,
  payload: state
});

const SetUserData = state => ({
  type: TUser.USER_DATA,
  payload: state
});

const PatchUserData = state => ({
  type: TUser.PATCH_USER_DATA,
  payload: state
});

const SetPersonalInfo = payload => ({
  type: TUser.SET_PERSONAL_INFO,
  payload: payload
});

const UserActions = {
  SetIsLogin,
  SetToken,
  SetUserData,
  SetPersonalInfo,
  PatchUserData
};

export default UserActions;
