import styled, { css } from 'styled-components';
import MediaQuery from 'utils/MediaQuery';

// TODO
const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 720px;
  padding-bottom: 85px;

  ${MediaQuery.styling(
    'sm',
    css`
      max-width: 560px;
    `
  )};

  ${MediaQuery.styling(
    'xs',
    css`
      max-width: calc(100% - 40px);
    `
  )};
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 720px;
  box-sizing: border-box;

  & > .pw-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35px;

    ${MediaQuery.styling(
      'md',
      css`
        gap: 20px;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        gap: 15px;
      `
    )};

    .pw-register,
    .pw-login {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 67px;
      width: 100%;
      border: 1px solid #1d4371;
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 700;
      color: #1d4371;
      cursor: pointer;

      ${MediaQuery.styling(
        'md',
        css`
          height: 60px;
          font-size: 16px;
        `
      )};

      ${MediaQuery.styling(
        'sm',
        css`
          height: 56px;
          font-size: 15px;
          font-weight: 500;
        `
      )};

      ${MediaQuery.styling(
        'xs',
        css`
          height: 48px;
          font-size: 14px;
        `
      )};
    }

    .pw-login {
      color: #fff;
      border: none;
      background: linear-gradient(
        158deg,
        rgba(29, 67, 113, 1) 0%,
        rgba(53, 162, 198, 1) 52%
      );

      &:disabled {
        background: linear-gradient(
          158deg,
          rgb(218, 233, 251) 0%,
          rgb(176, 213, 225) 52%
        );
        cursor: not-allowed;
      }
    }

    .pw-text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 5px;
      font-size: 15px;
      font-weight: 400;
      color: #9c9cbd;
      position: relative;
      top: -15px;

      ${MediaQuery.styling(
        'md',
        css`
          top: 0;
          font-size: 14px;
        `
      )};

      a {
        color: #9c9cbd;
        cursor: pointer;
      }
    }
  }
  & > .pw-double {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
    height: 60px;

    ${MediaQuery.styling(
      'md',
      css`
        height: 56px;
        gap: 20px;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        flex-direction: column;
        height: auto;
        gap: 15px;
      `
    )};

    &.pw-pin {
      gap: 20px;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 474px;
        height: 100%;
        background: linear-gradient(
          158deg,
          rgba(29, 67, 113, 1) 0%,
          rgba(53, 162, 198, 1) 52%
        );
        border: 0px solid transparent;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        transition: all ease 0.3s;

        ${MediaQuery.styling(
          'md',
          css`
            font-size: 15px;
          `
        )};

        ${MediaQuery.styling(
          'sm',
          css`
            width: 100%;
            height: 48px;
            font-size: 14px;
          `
        )};

        &:disabled {
          cursor: not-allowed;
          color: #9c9cbd;
          background: #ffffff;
          border: 1px solid #d0c9df;
        }
      }
    }
  }
  & > .pw-rules {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    ${MediaQuery.styling(
      'sm',
      css`
        flex-direction: column-reverse;
      `
    )};

    .rules {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;
      flex: 1;

      ${MediaQuery.styling(
        'sm',
        css`
          width: 100%;
        `
      )};

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #5d55a1;
        cursor: pointer;

        ${MediaQuery.styling(
          'md',
          css`
            gap: 15px;
            font-size: 15px;
          `
        )};

        ${MediaQuery.styling(
          'sm',
          css`
            font-size: 14px;
          `
        )};

        .radio {
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          border: 1px solid #d0c9df;
          box-sizing: border-box;
          border-radius: 5px;
          width: 24px;
          height: 24px;
          position: relative;
          transition: all ease 0.3s;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 1;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5323 0.470738C19.1559 1.0987 19.1559 2.11663 18.5323 2.74428L7.82324 13.5293C7.19967 14.1569 6.18919 14.1569 5.56562 13.5293L0.467673 8.39502C-0.155891 7.76737 -0.155891 6.74944 0.467673 6.12179C1.09093 5.49383 2.10172 5.49383 2.72498 6.12179L6.69427 10.1191L16.2747 0.470738C16.8983 -0.156913 17.9091 -0.156913 18.5323 0.470738Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
            background-attachment: scroll;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: transparent;
            background-size: 16px;
            opacity: 0;
            visibility: hidden;
            transition: all ease 0.3s;
          }

          &.active {
            background: #7754ff;
            border: 1px solid #7754ff;
            &:after {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }

    .input {
      min-width: 230px;

      ${MediaQuery.styling(
        'sm',
        css`
          width: 100%;
        `
      )};
    }
  }
`;

export const Input = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;

  &.select {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20px;
      z-index: 1;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.34157 11.3565L16.6511 2.4701C17.1163 1.90482 17.1163 0.988604 16.6511 0.423604C16.1863 -0.141442 15.4324 -0.141442 14.9677 0.423604L8.49997 8.28645L2.03231 0.423604C1.56729 -0.141442 0.813503 -0.141442 0.348741 0.423604C-0.116247 0.988649 -0.116247 1.9051 0.348741 2.47037L7.65837 11.3568C7.89089 11.6392 8.1953 11.7803 8.49997 11.7803C8.80453 11.7803 9.10917 11.6389 9.34157 11.3565Z' fill='%239C9CBD'/%3E%3C/svg%3E%0A");
      background-attachment: scroll;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: transparent;
      background-size: 10px;
      width: 15px;
      transition: all ease 0.3s;

      ${MediaQuery.styling(
        'md',
        css`
          left: 15px;
        `
      )};
    }
  }

  &.pin {
    width: 474px;
    min-width: 474px;
    justify-content: flex-end;

    ${MediaQuery.styling(
      'sm',
      css`
        width: 100%;
        min-width: auto;
      `
    )};

    .timer {
      position: absolute;
      direction: ltr;
      left: 20px;
      color: #d0c9df;
      font-size: 14px;
    }
  }

  &.password {
    flex-direction: column;
    align-items: flex-start;

    & > input {
      direction: ltr;
      text-align: right;
    }

    .pw-strength {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      height: 10px;
      margin-top: 15px;

      ${MediaQuery.styling(
        'sm',
        css`
          gap: 10px;
          height: 8px;
        `
      )};

      span {
        display: block;
        height: 100%;
        width: 150px;
        background: #d0c9df;
        border-radius: 5px;
        transition: all ease 0.3s;

        ${MediaQuery.styling(
          'sm',
          css`
            width: 100px;
          `
        )};

        ${MediaQuery.styling(
          'xs',
          css`
            width: 60px;
          `
        )};

        &.on {
          background: #7754ff;
        }

        &.ok {
          background: #64bc26;
        }
      }

      .text {
        font-size: 12px;
        font-weight: 400;
        color: #d0c9df;

        &.on {
          color: #7754ff;
        }

        &.ok {
          color: #64bc26;
        }
      }
    }

    .pw-password-text {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      color: #d0c9df;
      margin-top: 20px;

      ${MediaQuery.styling(
        'sm',
        css`
          flex-wrap: wrap;
        `
      )};

      span {
        margin: 0 5px;

        &.on {
          color: #64bc26;
        }
      }
    }

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      height: 60px;
      width: 32px;
      padding: 0 20px;
      box-sizing: content-box;
      fill: #d0c9df;
      transition: all ease 0.3s;
      cursor: pointer;

      ${MediaQuery.styling(
        'md',
        css`
          height: 56px;
          width: 28px;
          padding: 0 15px;
        `
      )};

      ${MediaQuery.styling(
        'sm',
        css`
          height: 48px;
          width: 28px;
        `
      )};

      &.on {
        fill: #7754ff;
      }
    }
  }

  &:not(.select, .password) {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 25px;
      z-index: 1;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5323 0.470738C19.1559 1.0987 19.1559 2.11663 18.5323 2.74428L7.82324 13.5293C7.19967 14.1569 6.18919 14.1569 5.56562 13.5293L0.467673 8.39502C-0.155891 7.76737 -0.155891 6.74944 0.467673 6.12179C1.09093 5.49383 2.10172 5.49383 2.72498 6.12179L6.69427 10.1191L16.2747 0.470738C16.8983 -0.156913 17.9091 -0.156913 18.5323 0.470738Z' fill='%2364BC26'/%3E%3C/svg%3E%0A");
      background-attachment: scroll;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: transparent;
      background-size: 18px;
      width: 20px;
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.3s;

      ${MediaQuery.styling(
        'md',
        css`
          left: 15px;
          background-size: 16px;
        `
      )};
    }

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.52497 1.81634L7.18324 0.474609L4.49991 3.15794L1.81659 0.474609L0.474854 1.81634L3.15818 4.49967L0.474854 7.183L1.81659 8.52473L4.49991 5.84141L7.18324 8.52473L8.52497 7.183L5.84165 4.49967L8.52497 1.81634Z' fill='%23C71720'/%3E%3C/svg%3E%0A");
    }

    &.valid {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
    &.invalid {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  & > select,
  & > input {
    font-size: 16px;
    font-weight: 400;
    background: #ffffff;
    border: 1px solid #d0c9df;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${MediaQuery.styling(
      'md',
      css`
        height: 56px;
        padding: 0 15px;
        font-size: 15px;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        height: 48px;
        font-size: 14px;
      `
    )};

    &.pw-number {
      letter-spacing: 2px;
    }

    &::placeholder {
      color: #9c9cbd;
      letter-spacing: 0;
    }
  }

  & > select {
    appearance: none;
    cursor: pointer;

    &.placeholder {
      color: #9c9cbd;
    }

    option {
      color: #000000;
      &.default {
        color: #9c9cbd;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

export const Spacer = styled('div')`
  display: block;
  width: 100%;
  height: ${({ size }) => (size ? `${size}px` : '30px')};

  ${MediaQuery.styling(
    'md',
    css`
      height: ${({ size }) => (size ? `${size / 2}px` : '15px')};
    `
  )};
`;

export const VerifyGroup = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  gap: 20px;

  ${MediaQuery.styling(
    'md',
    css`
      gap: 15px;
      height: 56px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      flex-direction: column;
      height: auto;
    `
  )};

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 474px;
    height: 100%;
    background: linear-gradient(
      158deg,
      rgba(29, 67, 113, 1) 0%,
      rgba(53, 162, 198, 1) 52%
    );
    border: 0px solid transparent;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    transition: all ease 0.3s;

    ${MediaQuery.styling(
      'md',
      css`
        font-size: 15px;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        width: 100%;
        height: 48px;
        font-size: 14px;
      `
    )};

    &:disabled {
      cursor: not-allowed;
      color: #9c9cbd;
      background: #ffffff;
      border: 1px solid #d0c9df;
    }
  }
`;

export const Divider = styled('div')`
  display: block;
  background: #9c9cbd80;
  width: 100%;
  height: 1px;
`;

const Styled = {
  Container
};

export default Styled;
