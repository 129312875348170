import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { DEFAULTS } from './constants';
import Modules from './modules';
import Styled from './styles';
import { useSelector } from 'react-redux';
import { Files } from 'services/config/url';

const baseUrl = process.env.REACT_APP_API_URL;
const apiUrl = 'api/v1';

const Uploader = ({ label, placeholder, onSelect }) => {
  const [image, setImage] = useState(placeholder);
  const [loaded, setLoaded] = useState(true);
  const [isPdf, setIsPdf] = useState(false);

  const token = useSelector(state => state.User.token);

  const getUploadParams = arg => {
    if (
      arg.file.type === 'image/png' ||
      arg.file.type === 'image/jpg' ||
      arg.file.type === 'image/jpeg'
    ) {
      setImage(arg.meta.previewUrl);
      setIsPdf(false);
    } else {
      setImage(
        'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzE0MWYzODt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmRvY3VtZW50LWNoZWNrLWdseXBoPC90aXRsZT48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik00MjIuNCwzNTguNGE3Ni44LDc2LjgsMCwxLDAsNzYuOCw3Ni44QTc2Ljg5LDc2Ljg5LDAsMCwwLDQyMi40LDM1OC40Wk00NTksNDE2LjE5bC0zMC43Miw1MS4yYTEyLjc5LDEyLjc5LDAsMCwxLTguODQsNiwxMi4zNCwxMi4zNCwwLDAsMS0yLjE0LjE4LDEyLjgsMTIuOCwwLDAsMS04LjE5LTNsLTIwLjQ4LTE3LjA3QTEyLjgsMTIuOCwwLDAsMSw0MDUsNDMzLjlsOSw3LjVMNDM3LDQwM0ExMi44LDEyLjgsMCwwLDEsNDU5LDQxNi4xOVptLTEzOSwxOUExMDIuNCwxMDIuNCwwLDAsMSw0MjIuNCwzMzIuOFYxNTMuNkwyNjguOTQsMEg3Ni44YTY0LDY0LDAsMCwwLTY0LDY0VjQ0OGE2NCw2NCwwLDAsMCw2NCw2NGgyNzhBMTAyLjA1LDEwMi4wNSwwLDAsMSwzMjAsNDM1LjJaTTI1NiwyNS42LDM5Ni44LDE2Ni40SDMyMGE2NCw2NCwwLDAsMS02NC02NFpNMTI4LDI0My4ySDIzMC40YTEyLjgsMTIuOCwwLDEsMSwwLDI1LjZIMTI4YTEyLjgsMTIuOCwwLDEsMSwwLTI1LjZabTAsNTEuMkgzMDcuMmExMi44LDEyLjgsMCwwLDEsMCwyNS42SDEyOGExMi44LDEyLjgsMCwwLDEsMC0yNS42Wm0xNzguODIsNzYuOEgxMjhhMTIuOCwxMi44LDAsMSwxLDAtMjUuNkgzMDYuODJhMTIuOCwxMi44LDAsMSwxLDAsMjUuNloiLz48L3N2Zz4='
      );
      setIsPdf(true);
    }
    setTimeout(() => {}, 100);
    const params = {
      headers: { Authorization: `Bearer ${token}` },
      url: `${baseUrl}/${apiUrl}/${Files.uploadFiles}`
    };
    return params;
  };

  const changeStatus = ({ meta, file, xhr }, status) => {
    if (status === 'done') {
      try {
        const response = JSON.parse(xhr.response);
        if (response && response.id) {
          onSelect(response.id);
        } else {
          onSelect(null);
          setImage(placeholder);
        }
      } catch (e) {
        onSelect(null);
        setImage(placeholder);
      }
    } else if (status === 'removed') {
      setImage(placeholder);
      onSelect(null);
    }
  };

  return (
    <Styled.Container>
      <Styled.Label>{label}</Styled.Label>
      <Dropzone
        getUploadParams={getUploadParams}
        accept={DEFAULTS.accept}
        minSizeBytes={DEFAULTS.minSizeBytes}
        maxSizeBytes={DEFAULTS.maxSizeBytes}
        maxFiles={DEFAULTS.maxFiles}
        multiple={DEFAULTS.multiple}
        InputComponent={Modules.Input}
        PreviewComponent={Modules.Preview}
        SubmitButtonComponent={null}
        onChangeStatus={changeStatus}
      />
      <Styled.Image loaded={loaded}>
        <img
          src={image}
          alt={''}
          style={{ objectFit: isPdf ? 'unset' : 'cover' }}
        />
      </Styled.Image>
    </Styled.Container>
  );
};

export default Uploader;
