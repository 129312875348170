import styled, { css } from 'styled-components';
import MediaQuery from 'utils/MediaQuery';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1280px;
  padding: 0 20px 90px 20px;
`;

const Steps = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;

  ${MediaQuery.styling(
    'sm',
    css`
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 40px;
    `
  )}
  &:after {
    content: '';
    position: absolute;
    top: 75px;
    right: 75px;
    left: 75px;
    z-index: 0;
    height: 1px;
    border-bottom: 2px dashed rgba(156, 156, 189, 0.7);

    ${MediaQuery.styling(
      'sm',
      css`
        content: none;
      `
    )}
  }
`;

const Step = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 210px;
  position: relative;
  z-index: 1;

  & > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(208, 201, 223, 0.5);
    border-radius: 44px;
    min-width: 150px;
    width: 150px;
    height: 150px;
    margin-bottom: 40px;
    padding: 40px 35px;

    ${MediaQuery.styling(
      'md',
      css`
        border-radius: 30px;
        min-width: 120px;
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
        padding: 30px 25px;
      `
    )}
    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  & > .title {
    font-size: 16px;
    font-weight: 400;
    color: #322e55;
    margin-bottom: 8px;

    ${MediaQuery.styling(
      'md',
      css`
        font-size: 15px;
      `
    )}
  }

  & > .subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #9c9cbd;
  }
`;

const HelpLink = styled('a')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 460px;
  height: 70px;
  border: 1px dashed #35a2c6;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  color: #1d4371;
  transition: all ease 0.3s;

  ${MediaQuery.styling(
    'lg',
    css`
      height: 60px;
    `
  )};

  ${MediaQuery.styling(
    'md',
    css`
      height: 56px;
      font-size: 15px;
    `
  )}
  ${MediaQuery.styling(
    'sm',
    css`
      height: 48px;
      font-size: 14px;
      width: 100%;
    `
  )}
  &:hover {
    background: #ffffff66;
  }
`;

const Spacer = styled('div')`
  display: block;
  width: 100%;
  height: ${({ size }) => (size ? `${size}px` : '30px')};

  ${MediaQuery.styling(
    'md',
    css`
      height: ${({ size }) => (size ? `${size / 2}px` : '15px')};
    `
  )};
`;

const Divider = styled('div')`
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(208, 201, 223, 0.73);
`;

const PackageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  width: 100%;

  ${MediaQuery.styling(
    'md',
    css`
      gap: 15px;
    `
  )}

  ${MediaQuery.styling(
    'sm',
    css`
      flex-direction: column;
    `
  )};
`;

const Package = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px 45px 25px 45px;
  background: #ffffff;
  border-radius: 10px;

  ${MediaQuery.styling(
    'lg',
    css`
      padding: 20px 40px 25px 40px;
    `
  )};

  ${MediaQuery.styling(
    'md',
    css`
      padding: 20px 25px 25px 25px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      padding: 20px 40px 25px 40px;
      width: 100%;
      flex: auto;
    `
  )};

  ${MediaQuery.styling(
    'xs',
    css`
      padding: 20px 25px 25px 25px;
    `
  )};

  & > .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5f5;
    margin-bottom: 30px;

    img {
      width: 84px;
      height: 91px;
    }

    ${MediaQuery.styling(
      'lg',
      css`
        gap: 25px;
      `
    )};

    ${MediaQuery.styling(
      'md',
      css`
        gap: 15px;
        margin-bottom: 20px;

        img {
          width: 60px;
          height: 66px;
        }
      `
    )};

    & > .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      & > .title {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: #1d4371;
        white-space: nowrap;

        ${MediaQuery.styling(
          'lg',
          css`
            font-size: 17px;
          `
        )};

        ${MediaQuery.styling(
          'md',
          css`
            font-size: 16px;
          `
        )};
      }

      & > .subtitle {
        font-size: 16px;
        font-weight: 300;
        color: #9c9cbd;

        ${MediaQuery.styling(
          'lg',
          css`
            font-size: 15px;
          `
        )};

        ${MediaQuery.styling(
          'md',
          css`
            font-size: 14px;
          `
        )};
      }
    }
  }

  & > .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    width: 100%;
    margin-bottom: 25px;
    padding: 0 10px;

    ${MediaQuery.styling(
      'lg',
      css`
        gap: 15px;
      `
    )};

    ${MediaQuery.styling(
      'md',
      css`
        gap: 10px;
        padding: 0 5px;
      `
    )};

    & > .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      color: #000000;

      ${MediaQuery.styling(
        'lg',
        css`
          font-size: 15px;
        `
      )};

      ${MediaQuery.styling(
        'md',
        css`
          font-size: 14px;
        `
      )};

      .key {
        &:after {
          content: ':';
        }
      }
    }
  }

  & > .action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: calc(100% - 20px);
    margin: 0 10px;
    border: 1px solid #1d4371;
    border-radius: 5px;
    transition: all ease 0.3s;
    font-size: 16px;
    color: #1d4371;

    ${MediaQuery.styling(
      'md',
      css`
        height: 56px;
        font-size: 15px;
      `
    )}
    ${MediaQuery.styling(
      'sm',
      css`
        height: 48px;
        font-size: 14px;
        width: 100%;
      `
    )}
    &:active,
    &:hover {
      background: #e5e5f566;
    }
  }
`;

const Tabs = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 85px;
  background: #ffffff;
  border-radius: 10px;
  gap: 10px;
  padding: 0 40px;

  ${MediaQuery.styling(
    'md',
    css`
      height: 70px;
      padding: 0 20px;
    `
  )}
  ${MediaQuery.styling(
    'sm',
    css`
      height: 56px;
      padding: 0 10px;
    `
  )}
  ${MediaQuery.styling(
    'sm',
    css`
      height: auto;
      flex-direction: column;
      padding: 15px;
    `
  )}
  & > .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    background: #ffffff;
    color: #9c9cbd;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    transition: all ease 0.3s;
    padding: 0 50px;
    cursor: pointer;

    ${MediaQuery.styling(
      'md',
      css`
        height: 40px;
        font-size: 16px;
        padding: 0 30px;
      `
    )}
    ${MediaQuery.styling(
      'sm',
      css`
        height: 36px;
        font-size: 14px;
        padding: 0 20px;
      `
    )}
    ${MediaQuery.styling(
      'xs',
      css`
        width: 100%;
      `
    )}
    &:hover {
      background: #e5e5f566;
    }

    &.active {
      background: #b7d9f9;
      color: #1d4371;
    }
  }
`;

const Invoice = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 60px 45px;
  gap: 90px;

  ${MediaQuery.styling(
    'md',
    css`
      padding: 45px 35px;
      gap: 40px;
    `
  )}

  ${MediaQuery.styling(
    'sm',
    css`
      flex-direction: column;
      padding: 50px 30px;
      gap: 60px;
    `
  )}

  ${MediaQuery.styling(
    'xs',
    css`
      padding: 50px 20px;
    `
  )}
`;

const Chart = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  ${MediaQuery.styling(
    'sm',
    css`
      flex: auto;
      width: 100%;
    `
  )}
  & > .colors {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    ${MediaQuery.styling(
      'md',
      css`
        gap: 10px;
      `
    )}
    ${MediaQuery.styling(
      'xs',
      css`
        flex-wrap: wrap;
      `
    )}
    & > .item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .color {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        background: #fff;
        border-radius: 2px;

        padding: 0 5px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
      }

      .text {
        font-size: 14px;
        font-weight: 700;

        ${MediaQuery.styling(
          'md',
          css`
            font-size: 13px;
            font-weight: 500;
          `
        )}
      }

      &.total {
        .color {
          background: #1d4371;
          color: #ffffff;
        }

        .text {
          color: #1d4371;
        }
      }

      &.reserved {
        .color {
          background: #35a2c6;
          color: #ffffff;
        }

        .text {
          color: #35a2c6;
        }
      }

      &.owned {
        .color {
          background: #9c9cbd;
          color: #ffffff;
        }

        .text {
          color: #9c9cbd;
        }
      }

      &.enabled {
        .color {
          background: #0071f2;
          color: #ffffff;
        }

        .text {
          color: #0071f2;
        }
      }
    }
  }
`;

const InvoiceContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 375px;
  min-width: 375px;

  ${MediaQuery.styling(
    'md',
    css`
      gap: 25px;
    `
  )}
  ${MediaQuery.styling(
    'sm',
    css`
      min-width: 100%;
      width: 100%;
    `
  )}
  & > .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    gap: 30px;
    width: 100%;
    border: 1px solid #d0c9df;
    border-radius: 5px;

    ${MediaQuery.styling(
      'md',
      css`
        padding: 20px;
        gap: 20px;
      `
    )}
    & > .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      width: 100%;

      ${MediaQuery.styling(
        'md',
        css`
          gap: 10px;
        `
      )}
      & > .key {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        white-space: nowrap;

        ${MediaQuery.styling(
          'md',
          css`
            font-size: 15px;
          `
        )}
        ${MediaQuery.styling(
          'xs',
          css`
            font-size: 14px;
          `
        )}
        &:after {
          content: ':';
        }

        span {
          font-size: 12px;
          color: #9c9cbd;
          float: left;
          line-height: 2;
          margin-right: 4px;

          ${MediaQuery.styling(
            'md',
            css`
              font-size: 11px;
              line-height: 2.25;
            `
          )}
        }
      }

      & > .value {
        height: 50px;
        background: #e5e5f5;
        border: 1px solid #008bff;
        border-radius: 2px;
        max-width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        ${MediaQuery.styling(
          'md',
          css`
            height: 42px;
          `
        )}
        ${MediaQuery.styling(
          'xs',
          css`
            max-width: 100px;
          `
        )}
        .input {
          border: none;
          background: none;
          max-width: 100%;
          min-width: 100%;
          height: 100%;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #0071f2;
          padding: 0;
          transition: all ease 0.3s;

          ${MediaQuery.styling(
            'md',
            css`
              font-size: 16px;
            `
          )}
        }

        .icons {
          position: absolute;
          width: 20px;
          top: 0;
          bottom: 0;
          left: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          ${MediaQuery.styling(
            'md',
            css`
              width: 18px;
            `
          )}
          .icon {
            width: 100%;
            height: 50%;
            padding: 0 5px;
            cursor: pointer;
            fill: #008bff;
            transition: all ease 0.3s;

            &.disabled {
              cursor: default;
              fill: #d0c9df;
            }
          }
        }

        &.disabled {
          border: 1px solid #9c9cbd;

          .input {
            color: #9c9cbd;
          }
        }
      }
    }
  }

  & > .action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    background: linear-gradient(
      158deg,
      rgba(29, 67, 113, 1) 0%,
      rgba(53, 162, 198, 1) 52%
    );
    border-radius: 2px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;

    ${MediaQuery.styling(
      'md',
      css`
        font-size: 16px;
        height: 42px;
      `
    )}

    ${MediaQuery.styling(
      'xs',
      css`
        font-size: 14px;
      `
    )}
  }

  & > .disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }

  & > .rule {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #9c9cbd;

    .radio {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid #d0c9df;
      box-sizing: border-box;
      border-radius: 5px;
      width: 24px;
      height: 24px;
      position: relative;
      cursor: pointer;
      transition: all ease 0.3s;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5323 0.470738C19.1559 1.0987 19.1559 2.11663 18.5323 2.74428L7.82324 13.5293C7.19967 14.1569 6.18919 14.1569 5.56562 13.5293L0.467673 8.39502C-0.155891 7.76737 -0.155891 6.74944 0.467673 6.12179C1.09093 5.49383 2.10172 5.49383 2.72498 6.12179L6.69427 10.1191L16.2747 0.470738C16.8983 -0.156913 17.9091 -0.156913 18.5323 0.470738Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: transparent;
        background-size: 16px;
        opacity: 0;
        visibility: hidden;
        transition: all ease 0.3s;
      }

      &.active {
        background: #7754ff;
        border: 1px solid #7754ff;

        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .link {
      margin-right: 5px;
      cursor: pointer;
      text-decoration: underline;
      color: #0071f2;
    }
  }
`;

const RulesContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 90px;
  gap: 75px;

  & > .title {
    font-size: 25px;
    font-weight: 500;
    color: #1d4371;
    margin: 0;
  }

  & > .rules {
    font-size: 18px;
    line-height: 36px;
    font-weight: 300;
    color: #1d4371;
    white-space: break-spaces;
    width: 100%;
  }

  & > .action {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    width: 280px;
    height: 60px;
    background: #7754ff;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      background: #7754ffdd;
    }
  }

  ${({ fixed }) =>
    fixed &&
    css`
      justify-content: flex-start;
      height: 100%;
    `};
`;

const FingerPrint = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  min-width: 150px;
  height: 155px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(208, 201, 223, 0.5);
  border-radius: 44px;
  padding: 45px;
  position: relative;
`;
const Fields = styled('div')`
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  align-items: center;
  justify-content: center;
  row-gap: 35px;
  column-gap: 40px;
  position: relative;

  ${MediaQuery.styling(
    'md',
    css`
      row-gap: 25px;
      column-gap: 30px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      row-gap: 15px;
      column-gap: 20px;
    `
  )};

  & > .actions {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 10px;
    box-shadow: 5px 0 10px 5px rgba(255, 255, 255, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.3s;

    ${MediaQuery.styling(
      'sm',
      css`
        position: relative;
        padding: 0;
        grid-column: span 6;
        opacity: 1;
        visibility: visible;
        margin: 0 30px;
      `
    )};

    & > span {
      font-size: 12px;
      font-weight: 500;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: all ease 0.3s;

      ${MediaQuery.styling(
        'sm',
        css`
          flex: 1;
          align-items: center;
          justify-content: center;
          display: flex;
        `
      )};

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }

      &.save {
        background: #1a873c;
        color: #ffffff;
      }

      &.delete {
        color: #c71720;
      }

      &:not(.disabled) {
        &:hover {
          &.delete {
            background: #c71720;
            color: #ffffff;
          }
        }
      }
    }

    &.disabled {
      display: none;
    }
  }

  &:hover {
    & > .actions {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Field = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: ${({ isActive }) =>
    isActive ? '1px solid #7754FF' : '1px solid #d0c9df'};
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  grid-column: span 2;

  ${MediaQuery.styling(
    'md',
    css`
      height: 56px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      height: 48px;
      grid-column: span 6;
    `
  )};

  .params-settings-datepicker > .ant-picker-input > input {
    font-size: 16px !important;
    color: rgb(93, 85, 161) !important;
    text-align: center !important;
  }

  & > .label {
    font-size: 16px;
    font-weight: 400;
    background: #fff;
    color: #c4c4c4;
    margin: 0;
    padding: 0 18px 0 0;
    white-space: nowrap;

    ${MediaQuery.styling(
      'md',
      css`
        font-size: 15px;
        padding: 0 15px 0 0;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        font-size: 14px;
      `
    )};

    &:after {
      content: ':';
    }
  }

  & > .input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    width: auto;
    height: 100%;
    border: none;
    background: none;
    padding: 0 15px;
    text-align: center;
    appearance: none;
    font-size: 16px;
    font-weight: 400;
    min-width: 0;

    ${MediaQuery.styling(
      'md',
      css`
        font-size: 15px;
        padding: 0 10px;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        font-size: 14px;
      `
    )};

    &.wide {
      letter-spacing: 2px;

      ${MediaQuery.styling(
        'sm',
        css`
          letter-spacing: 1px;
        `
      )};
    }

    &.tiny {
      padding: 0;
      border: 1px solid #e5e5f5;
      height: 75%;
      border-radius: 5px;
    }

    &.ltr {
      direction: ltr;
    }

    &.center {
      text-align: center;
    }

    &.active {
      color: #7754ff;
    }

    &:disabled {
      color: #88888b;
      cursor: not-allowed;

      &.active {
        color: #1d4371;
      }
    }

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: #c4c4c4;

      ${MediaQuery.styling(
        'md',
        css`
          font-size: 15px;
        `
      )};

      ${MediaQuery.styling(
        'sm',
        css`
          font-size: 14px;
        `
      )};
    }
  }

  & > .icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 60px;
    width: 28px;
    padding: 0 12px;
    box-sizing: content-box;
    fill: #d0c9df;
    transition: all ease 0.3s;
    cursor: pointer;

    ${MediaQuery.styling(
      'md',
      css`
        height: 100%;
        width: 25px;
      `
    )};

    &.on {
      fill: #7754ff;
    }
  }

  & > .action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    min-width: 25px;
    height: 100%;
    padding: 0 12px;
    box-sizing: content-box;
    cursor: pointer;

    ${MediaQuery.styling(
      'md',
      css`
        width: 20px;
        min-width: 20px;
      `
    )};

    & > svg {
      fill: #d0c9df;
      transition: all ease 0.3s;
    }

    &:hover {
      & > svg {
        fill: #88888b;
      }
    }

    &.active {
      & > svg {
        fill: #7754ff;
      }
    }
  }

  ${({ field }) =>
    field === 'password-strength'
      ? css`
          border: none;
          border-radius: 0;

          & > .strength {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 10px;
            height: 10px;

            ${MediaQuery.styling(
              'md',
              css`
                height: 8px;
                gap: 8px;
              `
            )};

            span {
              display: block;
              height: 100%;
              width: auto;
              flex: 1;
              background: #d0c9df;
              border-radius: 5px;
              transition: all ease 0.3s;

              &.on {
                background: #7754ff;
              }

              &.ok {
                background: #64bc26;
              }
            }

            .text {
              width: 60px;
              font-size: 12px;
              font-weight: 400;
              color: #d0c9df;
              text-align: left;

              &.on {
                color: #7754ff;
              }

              &.ok {
                color: #64bc26;
              }
            }
          }
        `
      : field === 'select'
      ? css`
          & > .input {
            cursor: pointer;
          }

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
            z-index: 1;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.34157 11.3565L16.6511 2.4701C17.1163 1.90482 17.1163 0.988604 16.6511 0.423604C16.1863 -0.141442 15.4324 -0.141442 14.9677 0.423604L8.49997 8.28645L2.03231 0.423604C1.56729 -0.141442 0.813503 -0.141442 0.348741 0.423604C-0.116247 0.988649 -0.116247 1.9051 0.348741 2.47037L7.65837 11.3568C7.89089 11.6392 8.1953 11.7803 8.49997 11.7803C8.80453 11.7803 9.10917 11.6389 9.34157 11.3565Z' fill='%239C9CBD'/%3E%3C/svg%3E%0A");
            background-attachment: scroll;
            background-repeat: no-repeat;
            background-position: 50% 100%;
            background-color: transparent;
            background-size: 10px;
            width: 10px;
            height: 10px;
            cursor: pointer;
            transition: all ease 0.3s;
          }
        `
      : field === 'sheba'
      ? css`
          /* padding: 0 15px 0 45px; */

          & > .input {
            padding: 0 15px 0 45px;
            ${MediaQuery.styling(
              'md',
              css`
                padding: 0 10px 0 40px;
              `
            )};
          }

          &:after {
            content: 'IR';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            position: absolute;
            top: 0;
            left: 10px;
            font-size: 24px;
            font-weight: 500;
            line-height: 62px;
            color: #1d4371dd;

            ${MediaQuery.styling(
              'md',
              css`
                line-height: 58px;
                font-size: 20px;
                width: 28px;
              `
            )};

            ${MediaQuery.styling(
              'sm',
              css`
                line-height: 50px;
              `
            )};
          }
        `
      : field === 'calendar' &&
        css`
          cursor: text;

          &:after {
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-color: transparent;
            background-attachment: scroll;
            background-size: 28px;
            ${({ icon }) =>
              icon &&
              css`
                background-image: url(${icon});
              `};
          }

          & > .calendar {
            position: relative;
            z-index: 999;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-5px);
            transition: all ease 0.3s;
            cursor: initial;

            &.active {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
            }
          }
        `};

  ${({ size }) =>
    size === 'full'
      ? css`
          grid-column: span 6;
        `
      : size === 'half'
      ? css`
          grid-column: span 3;
        `
      : size === 'onThree'
      ? css`
          grid-column: span 2;
        `
      : size === 'twoThree'
      ? css`
          grid-column: span 4;
        `
      : size === 'quarter' &&
        css`
          grid-column: span 5;
        `}
`;
const ResultActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  width: 100%;
  max-width: 640px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    &.primary {
      background: #ffffff;
      color: #7754ff;
      border: 1px solid #7754ff;
    }

    &.secondary {
      background: linear-gradient(
        158deg,
        rgba(29, 67, 113, 1) 0%,
        rgba(53, 162, 198, 1) 52%
      );
      color: #ffffff;
    }
  }
`;
const RuleScroll = styled('div')`
  //display: flex;
  //align-items: flex-end;
  margin-top: 0px;
  justify-content: center;
  text-align: right;
  overflow-y: scroll;
  color: #88888b;
  direction: rtl;
  max-height: 200px;
  padding: 20px;
  border-radius: 2px;
  border: 2px #000000;
  background-color: #f8f8f8;
`;
const RuleNotScroll = styled('div')`
  //display: flex;
  //align-items: flex-end;
  margin-top: 0px;
  justify-content: center;
  text-align: right;
  //overflow-y: scroll;
  color: #88888b;
  direction: rtl;
  max-height: 200px;
  padding: 20px;
  border-radius: 2px;
  border: 2px #000000;
  background-color: #f8f8f8;
`;
const StatsContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  padding: 30px 20px 80px 20px;
  border-radius: 10px;

  & > .text {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;

    & > .b {
      color: #1d4371;
      opacity: 1;
    }

    & > .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      color: #1d4371;
      margin: 0;
      padding: 0 0 5px 0;
      width: 100%;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 55px;
        height: 3px;
        background: #7754ff;
      }
    }

    & > .data {
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;

      .item {
        display: flex;
        align-items: center;
        gap: 15px;
        font-size: 16px;
        height: 28px;
        width: 100%;

        .label {
          font-weight: 500;
          color: #000000;
        }

        .space {
          flex: 1;
          border-bottom: 1px dashed #d0c9df;
        }

        .value {
          font-weight: 400;
          color: #1d4371;
        }
      }
    }
  }

  ${MediaQuery.styling(
    'sm',
    css`
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 50px;
    `
  )};
`;
const Styled = {
  Container,
  Steps,
  Step,
  HelpLink,
  Spacer,
  Divider,
  PackageContainer,
  Package,
  Tabs,
  Invoice,
  Chart,
  Fields,
  Field,
  InvoiceContent,
  RulesContent,
  FingerPrint,
  ResultActions,
  RuleScroll,
  RuleNotScroll,
  StatsContainer
};

export default Styled;
