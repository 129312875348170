import React, { useEffect, useState } from 'react';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import Styled from './styles';

const DatePicker = ({ date, onSelect }) => {
  const [selectedDay, setSelectedDay] = useState(date ? date : null);

  useEffect(() => {
    if (!!onSelect) onSelect(selectedDay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]);

  return (
    <Styled.Container>
      <Calendar
        value={selectedDay}
        onChange={setSelectedDay}
        shouldHighlightWeekends
        locale={'fa'}
      />
    </Styled.Container>
  );
};

export default DatePicker;
