import Logo from 'assets/images/logo_new.svg';
import Notification from 'assets/images/notification.png';
import Profile from 'assets/images/profile.svg';
import useClick from 'hooks/useClick';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import UserActions from 'store/stores/User/actions';
import Format from 'utils/Formatter';
import { AUTHENTICATION, PERMISSIONS } from './constants';
import Styled from './styles';
import useToast from 'hooks/useToast';
import AuthAPI from 'services/apis/AuthAPI';
import { DEFAULTS } from 'components/Home/constants';

const Navbar = () => {
  const dispatch = useDispatch();
  const dropDownRef = useRef(null);
  const history = useHistory();
  const { t } = useTranslation('layouts');
  const [isDropDown, setIsDropDown] = useClick(dropDownRef);
  const [toggle, setToggle] = useState(false);
  const user = useSelector(state => state.User.user);

  useEffect(() => {
    if (toggle) document.body.style.overflow = 'hidden';
    else document.body.removeAttribute('style');
  }, [toggle]);

  const onLogout = () => {
    dispatch(UserActions.SetIsLogin(false));
    history.push('/login/');
  };

  const toast = useToast();

  const ocalStockDataList = new AuthAPI();
  const [stockData, setStockData] = useState(DEFAULTS.stock);

  const fetchData = async () => {
    try {
      const response = await ocalStockDataList.StockDataListIndex();
      setStockData(response);
    } catch (error) {
      toast(t('notification.bank.error'), 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Styled.Nav>
      <Styled.Container>
        <Styled.Toggle
          className={`${toggle ? 'active' : ''}`}
          onClick={() => setToggle(!toggle)}
        >
          <span className={'bar'} />
          <span className={'bar'} />
          <span className={'bar'} />
        </Styled.Toggle>

        <Styled.Logo to={'/'}>
          <img src={Logo} alt={''} />
        </Styled.Logo>

        <Styled.Menu className={`${toggle ? 'toggle' : ''}`}>
          {toggle && (
            <span className={'close'} onClick={() => setToggle(false)}>
              {t('nabbar.toggle.close')}
            </span>
          )}
          <Link to={'/'} className={'item'}>
            {t('navbar.menu.home')}
          </Link>
          <Link
            to={{ pathname: 'https://parswater.com/faq/' }}
            target="_blank"
            className={'item'}
          >
            {t('navbar.menu.qusetion')}
          </Link>
          {/* <Link
            to={{
              pathname:
                'https://parswater.com/%D9%85%D9%82%D8%A7%D9%84%D8%A7%D8%AA/'
            }}
            target="_blank"
            className={'item'}
          >
            {t('navbar.menu.blog')}
          </Link> */}
          {/* <Link to={'/'} className={'item'}>
            {t('navbar.menu.projects')}
          </Link>
          <Link to={'/'} className={'item'}>
            {t('navbar.menu.tender')}
          </Link>
          <Link to={'/'} className={'item'}>
            {t('navbar.menu.stock')}
          </Link> */}
          <Link
            to={{ pathname: 'https://parswater.com/contact/' }}
            target="_blank"
            className={'item'}
          >
            {t('navbar.menu.contact')}
          </Link>
        </Styled.Menu>

        <Styled.Actions>
          {user ? (
            <>
              <Styled.Profile
                ref={dropDownRef}
                isDropDown={isDropDown}
                onClick={() => setIsDropDown(!isDropDown)}
              >
                <img src={Profile} alt={''} />
                <span
                  className={'text'}
                >{`${user?.firstName} ${user?.lastName}`}</span>
              </Styled.Profile>

              <div className={'notification'}>
                <span className={'counter'}>0</span>
                <img src={Notification} alt={''} />
              </div>

              <Styled.DropDown isDropDown={isDropDown}>
                <div className={'details'}>
                  <div className={'avatar'} />
                  <div className={'text'}>
                    <div className={'user'}>
                      {`${user?.firstName} ${user?.lastName} | `}
                      <span className={'mobile'}>
                        {Format.formatPhoneNumber('0' + user?.mobile)}
                      </span>
                    </div>
                    <div
                      className={`log ${
                        user?.authStep === AUTHENTICATION[0].step ||
                        (user?.rejectedReason !== null &&
                          user?.rejectedReason !== undefined)
                          ? 'error'
                          : user?.authStep === AUTHENTICATION[8].step
                          ? user?.permissions.includes(PERMISSIONS[1].step)
                            ? 'success'
                            : 'doing'
                          : 'doing'
                      }`}
                    >
                      {user?.authStep === AUTHENTICATION[0].step
                        ? user?.rejectedReason !== null &&
                          user?.rejectedReason !== undefined
                          ? `${t('navbar.log.step.rejected')} ${
                              user?.rejectedReason
                            }`
                          : t('navbar.log.step.0')
                        : user?.authStep === AUTHENTICATION[8].step
                        ? user?.permissions.includes(PERMISSIONS[1].step)
                          ? t('navbar.log.step.end')
                          : t('navbar.log.step.doing')
                        : t('navbar.log.step.begin', {
                            step: t(
                              AUTHENTICATION.find(
                                _step => _step.step === user?.authStep
                              )?.title
                            )
                          })}
                    </div>
                    <div className={'log'}>
                      {user?.authStep === AUTHENTICATION[8].step
                        ? t('navbar.log.stock.empty')
                        : t('navbar.log.stock.not_empty')}
                    </div>
                  </div>
                </div>
                <div className={'buttons'}>
                  <Link to={'/dashboard/'} className={'dashboard'}>
                    {t('navbar.dropdown.dashboard')}
                  </Link>
                  <div className={'logout'} onClick={onLogout}>
                    {t('navbar.dropdown.logout')}
                  </div>
                </div>
              </Styled.DropDown>
            </>
          ) : (
            <>
              <Styled.Button to={'/login/'} type={'secondary'}>
                {t('navbar.button.login')}
              </Styled.Button>

              {/* {stockData && stockData.lastDays >= -1 && (
                <Styled.Button to={'/register/'} type={'secondary'}>
                  {t('navbar.button.stock')}
                </Styled.Button>
              )} */}

              {stockData && stockData.lastDays >= -1 && (
                <Styled.Button to={'/register/'} type={'primary1'}>
                  {t('navbar.button.stock')}
                </Styled.Button>
              )}
            </>
          )}
        </Styled.Actions>
      </Styled.Container>
    </Styled.Nav>
  );
};

export default Navbar;
