import axios from 'axios';
import { useSelector } from 'react-redux';

const Token = () => {
  const token = useSelector(state => state.User.token);
  return token;
};

class ApiHelper {
  constructor(timeout) {
    const baseUrl = process.env.REACT_APP_API_URL;
    const apiUrl = 'api/v1';
    const token = Token();
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    this.http = axios.create({
      baseURL: `${baseUrl}/${apiUrl}`,
      timeout: timeout !== undefined ? timeout : 12000,
      headers
    });

    this.http.interceptors.response.use(
      response => response,
      error =>
        // TODO
        // eslint-disable-next-line no-console
        // console.log('----- Error: ' + error);
        Promise.reject(error)
    );
  }

  get = (url, data) =>
    this.http.get(url, {
      params: data
    });

  post = (url, data) => this.http.post(url, data);
  delete = (url, data) => this.http.delete(url, data);
  put = (url, data) => this.http.put(url, data);

  error = () => ({
    status: 'fail',
    code: '10500',
    message: 'در حال حاضر ارتباط با سرور برقرار نیست',
    errors: undefined,
    meta: undefined,
    actions: undefined
  });
}

export default ApiHelper;
