import Styled from 'components/DashboardStock/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modules from 'components/DashboardStock/modules';
import { Button, ConfigProvider, Modal } from 'antd';
import CustomTable from 'uikit/table';
import { Spacer } from 'components/Register/styles';
import StocksAPI from 'services/apis/StocksAPI';
import FilesAPI from 'services/apis/FilesAPI';
import useToast from 'hooks/useToast';
import faIR from 'antd/lib/locale/fa_IR';

const Tab4Module = ({ stats }) => {
  const { t } = useTranslation('dashboardStock');
  const [tableData, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableQuery, setTableQuery] = useState({});
  const [tableTotalElements, setTableTotalElements] = useState(0);
  const [downloadingFileId, setDownloadingFileId] = useState('');

  const [rejectReasonModalVisible, setRejectReasonModalVisible] =
    useState(false);
  const [rejectReason, setRejectReason] = useState('');

  const localStocksAPI = new StocksAPI();
  const localFilesAPI = new FilesAPI(60000);
  const toast = useToast();

  const [requestPaperLoading, setRequestPaperLoading] = useState(false);
  const [requestPaperModalVisible, setRequestPaperModalVisible] =
    useState(false);
  const [targetTransactionId, setTargetTransactionId] = useState('');

  const tableColumns = [
    {
      title: 'تاریخ خرید',
      key: 'buyDate',
      dataIndex: 'buyDate',
      sorter: true,
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs']
    },
    {
      title: 'نوع سهامداری',
      key: 'userType',
      dataIndex: 'userType',
      responsive: ['xxl', 'xl', 'lg']
    },
    {
      title: 'تعداد سهم',
      key: 'amount',
      dataIndex: 'amount',
      sorter: true,
      responsive: ['xxl', 'xl', 'lg', 'md']
    },
    // {
    //   title: 'تاریخ ثبت درخواست',
    //   key: 'requestDate',
    //   dataIndex: 'requestDate',
    //   sorter: true,
    //   responsive: ['xxl', 'xl', 'lg', 'md']
    // },
    {
      title: 'دانلود برگ سهام',
      key: 'fileId',
      dataIndex: 'fileId',
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
      render: fileId => (
        <a onClick={() => downloadFile(fileId)}>
          {downloadingFileId === fileId ? 'در حال دانلود...' : 'دانلود'}
        </a>
      )
    }
    // {
    //   title: 'اقدامات',
    //   key: 'status',
    //   dataIndex: 'status',
    //   responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
    //   render: item => (
    //     <>
    //       {item && item.status === 'Pending' ? (
    //         <Button disabled>در حال بررسی</Button>
    //       ) : (
    //         <></>
    //       )}
    //       {item && item.status === 'Accepted' ? (
    //         <Button
    //           // disabled={item.amount <= 1}
    //           onClick={() => {
    //             setTargetTransactionId(item.transactionId);
    //             setRequestPaperModalVisible(true);
    //           }}
    //         >
    //           درخواست مجدد
    //         </Button>
    //       ) : (
    //         <></>
    //       )}
    //       {item && item.status === 'Rejected' ? (
    //         <Button
    //           onClick={() => {
    //             setRejectReason(item.reason);
    //             setRejectReasonModalVisible(true);
    //           }}
    //         >
    //           رد شده
    //         </Button>
    //       ) : (
    //         <></>
    //       )}
    //       {item && item.status == null ? (
    //         <Button
    //           // disabled={item.amount < 100}
    //           onClick={() => {
    //             setTargetTransactionId(item.transactionId);
    //             setRequestPaperModalVisible(true);
    //           }}
    //         >
    //           ثبت درخواست
    //         </Button>
    //       ) : (
    //         <></>
    //       )}
    //     </>
    //   )
    // }
  ];

  useEffect(() => {
    getPapers();
  }, []);

  const downloadFile = fileId => {
    setDownloadingFileId(fileId);
    localFilesAPI.downloadFile(fileId, 'paper.pdf').then(() => {
      setDownloadingFileId('');
      reloadPapers();
    });
  };
  const requestPaper = () => {
    setRequestPaperLoading(true);
    localStocksAPI
      .paperRequest({ transactionId: targetTransactionId })
      .then(response => {
        toast('درخواست ارسال شد', 'success');
        setRequestPaperLoading(false);
        setRequestPaperModalVisible(false);
        reloadPapers();
      })
      .catch(resp => {
        toast(resp.msg, 'error');
        setRequestPaperLoading(false);
        setRequestPaperModalVisible(false);
      });
  };

  const getPapers = (
    { page, size, sort, asc } = {
      page: 0,
      size: 5,
      sort: 'buyDate',
      asc: false
    }
  ) => {
    const query = {
      page: page ? page : 0,
      size: size ? size : 5,
      sort: sort ? sort : 'buyDate',
      asc: asc ? asc : false
    };

    setTableQuery(query);
    setTableLoading(true);
    localStocksAPI
      .fetchPapersList(query)
      .then(response => {
        if (response && response.content) {
          if (Object(response).hasOwnProperty('totalElements'))
            setTableTotalElements(response.totalElements);
          setDataTable(
            response.content?.map(item => ({
              buyDate: item.buyDate?.toString().replace(/-/g, '/'),
              userType: t(`userType.${item.userType}`),
              amount: item.amount,
              status: item,
              requestDate: item.requestDate
                ? item.requestDate.toString().replace(/-/g, '/')
                : '-',
              action: item,
              fileId: item.fileId
            }))
          );
          // if (response) {
          //   console.log(response);
          //   reloadPapers();
          // }
        } else {
          toast(response.msg, 'error');
        }
        setTableLoading(false);
      })
      .catch(resp => {
        toast(resp.msg, 'error');
        setTableLoading(false);
      });
  };

  const reloadPapers = () => {
    getPapers(tableQuery);
  };

  return (
    <>
      <ConfigProvider locale={faIR} direction="rtl">
        <Styled.StatsContainer>
          <div className={'text'}>
            <h3 className={'title'}>{t('box.suppliedStocks.title')}</h3>
            <div style={{ width: '100%' }}>
              <Styled.Spacer size={20} />
              <CustomTable
                paginationBottom={'bottomCenter'}
                data={tableData}
                columns={tableColumns}
                loading={tableLoading}
                onChange={getPapers}
                total={tableTotalElements}
                defaultSort={'buyDate'}
                onFinish={reloadPapers}
              />
            </div>
          </div>
        </Styled.StatsContainer>
      </ConfigProvider>

      <Spacer size={50} />

      <Modules.Stats stats={stats} />

      <Modal
        style={{ direction: 'ltr' }}
        visible={requestPaperModalVisible}
        onCancel={() => setRequestPaperModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setRequestPaperModalVisible(false)}>
            لغو
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={requestPaperLoading}
            onClick={requestPaper}
          >
            تایید
          </Button>
        ]}
      >
        <br />
        <p style={{ direction: 'rtl' }}>
          آیا از درخواست برگ سهام اطمینان دارید؟
        </p>
      </Modal>
      <Modal
        style={{ direction: 'ltr' }}
        visible={rejectReasonModalVisible}
        onCancel={() => setRejectReasonModalVisible(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={requestPaperLoading}
            onClick={() => setRejectReasonModalVisible(false)}
          >
            تایید
          </Button>
        ]}
      >
        <br />
        <div style={{ direction: 'rtl' }}>
          <h3>علت رد:</h3>
          <br />
          <p>{rejectReason}</p>
        </div>
      </Modal>
    </>
  );
};

export default Tab4Module;
