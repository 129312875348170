import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import MediaQuery from 'utils/MediaQuery';

const Nav = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 115px;
  background-color: #fff;
  box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 999;

  ${MediaQuery.styling(
    'md',
    css`
      height: 80px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      height: 60px;
    `
  )};
`;

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1570px;
  padding: 0 20px;
  position: relative;

  ${MediaQuery.styling(
    'sm',
    css`
      justify-content: center;
    `
  )};
`;

const Logo = styled(Link)`
  margin-left: 50px;

  ${MediaQuery.styling(
    'md',
    css`
      margin-left: 10px;
      width: 90px;

      img {
        object-fit: cover;
        height: 40px;
        width: 105px;
        object-position: top;
      }
    `
  )};
`;

const Toggle = styled('div')`
  display: none;
  ${MediaQuery.styling(
    'sm',
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      border-radius: 10px;
      padding: 10px;
      background: #9c9cbd10;
      transition: all ease 0.3s;
      cursor: pointer;

      .bar {
        width: 24px;
        height: 3px;
        background: #888;
        transition: all ease 0.3s;
      }

      &.active,
      &:hover {
        background: #9c9cbd30;

        .bar {
          background: #666;
        }
      }
    `
  )};
`;

const Menu = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  flex: 1;
  margin-top: 10px;

  ${MediaQuery.styling(
    'lg',
    css`
      gap: 40px;
    `
  )};

  ${MediaQuery.styling(
    'md',
    css`
      gap: 30px;
      margin-top: 0;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: #fff;
      padding: 80px 0 0 0;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.3s;
      transform: translateY(-10px);

      &.toggle {
        opacity: 1;
        visibility: visible;
        z-index: 999;
        transform: translateY(0);
      }

      & > .close {
        position: fixed;
        top: 25px;
        right: 25px;
        z-index: 9999;
        color: #c71720;
        padding: 5px 15px;
        border-radius: 10px;
        transition: all ease 0.3s;
        cursor: pointer;

        &:hover {
          background: #c7172020;
        }
      }
    `
  )};

  .item {
    font-weight: 700;
    font-size: 17px;
    color: #35a2c6;
    transition: all ease 0.3s;

    ${MediaQuery.styling(
      'lg',
      css`
        font-size: 16px;
      `
    )};

    &:hover {
      color: #1d4371;
    }
  }
`;

const Actions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;

  ${MediaQuery.styling(
    'md',
    css`
      gap: 5px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      position: absolute;
      left: 20px;
      gap: 10px;
      border-radius: 10px;
      padding: 10px 15px;
      // background: #9c9cbd10;
      transition: all ease 0.3s;

      &.active,
      &:hover {
        // background: #9c9cbd30;
      }
    `
  )};

  & > .notification {
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px;
    transition: all ease 0.3s;
    background: #ffffff;

    ${MediaQuery.styling(
      'md',
      css`
        img {
          height: 28px;
          width: auto;
        }
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        display: none;
      `
    )};

    &.active,
    &:hover {
      background: #9c9cbd10;
    }

    ${({ isDropDown }) =>
      isDropDown &&
      css`
        background: #9c9cbd10;
      `};

    .counter {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 10px;
      font-weight: 500;

      ${MediaQuery.styling(
        'md',
        css`
          top: 9px;
          right: 9px;
        `
      )};
    }
  }
`;

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  width: 216px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  text-align: center;

  ${MediaQuery.styling(
    'lg',
    css`
      width: 200px;
      color: #1d4371;
    `
  )};

  ${MediaQuery.styling(
    'md',
    css`
      font-size: 15px;
      font-weight: 500;
      height: 42px;
      width: 150px;
    `
  )};

  ${({ type }) =>
    type === 'primary'
      ? css`
          background: linear-gradient(90deg, #2193b0, #1b2735 71%);
          color: #1d4371;

          ${MediaQuery.styling(
            'sm',
            css`
              display: none;
            `
          )};
        `
      : css`
          // background: #fafafc;
          border: 1px solid #35a2c6;
          color: #1d4371;
        `}

  ${MediaQuery.styling(
    'sm',
    css`
      background: none;
      border: none;
      height: auto;
      width: auto;
      font-size: 14px;
      font-weight: 500;
      color: #1d4371;
    `
  )};

  ${({ type }) =>
    type === 'primary1'
      ? css`
          background: linear-gradient(
            158deg,
            rgba(29, 67, 113, 1) 0%,
            rgba(53, 162, 198, 1) 52%
          );
          color: #ffffff;

          ${MediaQuery.styling('sm', css``)};
        `
      : css`
          background: #fafafc;
          border: 1px solid #35a2c6;
          color: #1d4371;
        `}

  ${MediaQuery.styling(
    'sm',
    css`
      background: none;
      border: none;
      height: auto;
      width: auto;
      font-size: 14px;
      font-weight: 500;
      color: #1d4371;
    `
  )};
`;

const Profile = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  padding: 10px 15px 10px 10px;
  transition: all ease 0.3s;
  background: #ffffff;
  border-radius: 10px;

  &.active,
  &:hover {
    background: #9c9cbd10;
  }

  ${MediaQuery.styling(
    'md',
    css`
      img {
        height: 28px;
        width: auto;
      }
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      padding: 0;
      transition: none;
      background: none;
    `
  )};

  ${({ isDropDown }) =>
    isDropDown &&
    css`
      background: #9c9cbd10;
    `};

  .text {
    font-size: 17px;
    font-weight: 400;
    color: #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    ${MediaQuery.styling(
      'md',
      css`
        font-size: 15px;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        display: none;
      `
    )};

    &:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.91445 7.71223L11.6417 1.67754C11.9425 1.29367 11.9425 0.671474 11.6417 0.287788C11.3411 -0.0959291 10.8535 -0.0959291 10.553 0.287788L6.37017 5.62737L2.18739 0.287788C1.88665 -0.0959291 1.39916 -0.0959291 1.09859 0.287788C0.797867 0.671505 0.797867 1.29386 1.09859 1.67773L5.82589 7.71241C5.97626 7.90418 6.17313 8 6.37017 8C6.56713 8 6.76415 7.90399 6.91445 7.71223Z' fill='%23C4C4C4'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: transparent;
      height: 20px;
      width: 20px;
      display: block;

      ${MediaQuery.styling(
        'md',
        css`
          height: 14px;
          width: 14px;
        `
      )};

      ${MediaQuery.styling(
        'sm',
        css`
          content: none;
        `
      )};
    }
  }
`;

const DropDown = styled('div')`
  position: absolute;
  bottom: 0;
  right: auto;
  left: 0;
  min-width: 420px;
  background: #fafafc;
  transform: translateY(calc(100% - 5px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 55px 30px 25px 30px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: all ease 0.3s;

  ${MediaQuery.styling(
    'sm',
    css`
      padding: 30px 20px 20px 20px;
      min-width: 300px;
    `
  )};

  ${({ isDropDown }) =>
    isDropDown &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(100%);
    `};

  .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    width: 100%;
    padding: 0 0 25px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #e5e5f5;

    ${MediaQuery.styling(
      'sm',
      css`
        padding: 0 0 15px 0;
        margin-bottom: 15px;
      `
    )};

    .avatar {
      margin-right: 20px;
      min-width: 70px;
      min-height: 70px;
      border-radius: 100%;
      background: linear-gradient(
        0deg,
        rgba(127, 85, 250, 0.5) 0%,
        rgba(244, 97, 183, 0.5) 100%
      );
      ${MediaQuery.styling(
        'sm',
        css`
          display: none;
        `
      )};
    }

    .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      ${MediaQuery.styling(
        'sm',
        css`
          align-items: center;
        `
      )};

      .user {
        font-size: 14px;
        font-weight: 700;
        color: #322e55;
        margin-bottom: 15px;
        .mobile {
          display: inline-flex;
          direction: ltr;
          font-weight: 300;
          margin-right: 6px;
        }
      }

      .log {
        font-size: 12px;
        font-weight: 500;
        color: #1d4371;

        &.error {
          color: #f461b7;
        }
        &.success {
          color: #64bc26;
        }
        &.doing {
          color: #0071f2;
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      height: 57px;
      width: 100%;
      cursor: pointer;
      transition: all ease 0.3s;

      ${MediaQuery.styling(
        'sm',
        css`
          font-size: 14px;
          font-weight: 500;
          height: 42px;
        `
      )};
    }
    .dashboard {
      background: #ffffff;
      border: 1px solid #1d4371;
      color: #1d4371;

      &:hover {
        background: linear-gradient(
          158deg,
          rgba(29, 67, 113, 1) 0%,
          rgba(53, 162, 198, 1) 52%
        );
        border: 1px solid #1d4371;
        color: #ffffff;
      }
    }
    .logout {
      background: #e6e5f5;
      border: 1px solid #e6e5f5;
      color: #9c9cbd;

      &:hover {
        background: #c71720ee;
        border: 1px solid #c71720;
        color: #ffffff;
      }
    }
  }
`;

const Styled = {
  Nav,
  Container,
  Logo,
  Toggle,
  Menu,
  Actions,
  Button,
  Profile,
  DropDown
};

export default Styled;
