import styled, { css } from 'styled-components';
import MediaQuery from 'utils/MediaQuery';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 100px;
  position: relative;
  z-index: 1;

  ${MediaQuery.styling(
    'lg',
    css`
      padding: 0 60px;
    `
  )}

  ${MediaQuery.styling(
    'md',
    css`
      padding: 0 40px;
    `
  )}

  ${MediaQuery.styling(
    'sm',
    css`
      padding: 0 20px;
    `
  )}

  ${MediaQuery.styling(
    'xs',
    css`
      padding: 0 10px;
    `
  )}
`;

const StepBar = styled('div')`
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
  height: 8px;
  background: #e5e5f5;
  border-radius: 5px;
  overflow: hidden;

  ${MediaQuery.styling(
    'md',
    css`
      height: 6px;
    `
  )}

  ${MediaQuery.styling(
    'xs',
    css`
      height: 4px;
    `
  )}

  &:after {
    content: '';
    position: absolute;
    right: 0;
    height: 8px;
    background: #35a2c6;
    transition: all ease 0.3s;
    width: ${({ progress }) => `calc((100% - 200px) * ${progress} + 100px)`};
    max-width: 100%;

    ${MediaQuery.styling(
      'lg',
      css`
        width: ${({ progress }) => `calc((100% - 120px) * ${progress} + 60px)`};
      `
    )}

    ${MediaQuery.styling(
      'md',
      css`
        height: 6px;
        width: ${({ progress }) => `calc((100% - 80px) * ${progress} + 40px)`};
      `
    )}

  ${MediaQuery.styling(
      'sm',
      css`
        width: ${({ progress }) => `calc((100% - 40px) * ${progress} + 20px)`};
      `
    )}

  ${MediaQuery.styling(
      'xs',
      css`
        height: 4px;
        width: ${({ progress }) => `calc((100% - 20px) * ${progress} + 10px)`};
      `
    )}
  }
`;

const Step = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 10px;
  position: relative;
  z-index: 1;
  transition: all ease 0.3s;

  ${MediaQuery.styling(
    'lg',
    css`
      width: 35px;
      height: 35px;
      border-radius: 35px;
    `
  )}

  ${MediaQuery.styling(
    'md',
    css`
      width: 30px;
      height: 30px;
      border-radius: 30px;
    `
  )}

  ${MediaQuery.styling(
    'xs',
    css`
      width: 20px;
      height: 20px;
      border-radius: 20px;
    `
  )}

  & > svg {
    transition: all ease 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: #35a2c6;
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.3s;

    ${MediaQuery.styling(
      'md',
      css`
        width: 12px;
        height: 12px;
        border-radius: 12px;
      `
    )}

    ${MediaQuery.styling(
      'xs',
      css`
        width: 8px;
        height: 8px;
        border-radius: 8px;
      `
    )}
  }

  ${({ type }) =>
    type === 'before'
      ? css`
          background: #35a2c6;

          & > svg {
            fill: #ffffff;
          }
        `
      : type === 'current'
      ? css`
          background: #e5e5f5;
          &:after {
            opacity: 1;
            visibility: visible;
          }
        `
      : css`
          background: #e5e5f5;
        `};
`;

const Styled = {
  Container,
  StepBar,
  Step
};

export default Styled;
