import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import authentication from './translations/authentication.json';
import dashboard from './translations/dashboard.json';
import dashboardStock from './translations/dashboardStock.json';
import home from './translations/home.json';
import layouts from './translations/layouts.json';
import login from './translations/login.json';
import register from './translations/register.json';
import stock from './translations/stock.json';
import uikits from './translations/uikits.json';

const resources = {
  fa: {
    authentication,
    dashboard,
    dashboardStock,
    home,
    layouts,
    login,
    uikits,
    register,
    stock
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fa',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
