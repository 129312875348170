import { DEFAULTS, STEPS } from 'components/Authentication/constants';
import Module from 'components/Authentication/modules';
import Styled from 'components/Authentication/styles';
import useToast from 'hooks/useToast';
import Loading from 'layouts/Loading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserAPI from 'services/apis/UserAPI';

const Step1Module = ({ userData, onStep }) => {
  const { t } = useTranslation('authentication');
  const [person, setPerson] = useState(DEFAULTS.person);
  const [company, setCompany] = useState(DEFAULTS.company);
  const [isPerson, setIsPerson] = useState(userData.accountType === 'Natural');
  const [isSubmit, setIsSubmit] = useState(false);
  const [validations, setValidations] = useState({
    person: {
      birthDate: true,
      gender: true,
      ostan: true,
      shahrestan: true,
      ssnOstan: true,
      ssnShahrestan: true,
      educationLevel: true,
      sacrificeStatus: true,
      dutyStatus: true,
      maritalStatus: true,
      idCardNumber: true,
      phone: true,
      postalCode: true,
      detail: true
    },
    company: {
      companyName: true,
      companyRegisterNumber: true,
      companyOstan: true,
      companyShahr: true,
      companyPostalCode: true,
      companyType: true
    }
  });
  const localUserAPI = new UserAPI();
  const toast = useToast();

  useEffect(() => {
    let _person = { ...person };
    let _company = { ...company };
    if (
      userData?.birthdate !== null &&
      userData?.birthdate?.split('-').length === 3
    ) {
      _person['birthDate'] = {
        year: userData?.birthdate?.split('-')[0],
        month: userData?.birthdate?.split('-')[1],
        day: userData?.birthdate?.split('-')[2]
      };
    }
    if (userData?.gender !== null) _person['gender'] = userData?.gender;

    if (userData?.idCardNumber !== null)
      _person['idCardNumber'] = userData?.idCardNumber;

    if (userData?.maritalStatus !== null && userData.maritalStatus.length > 0)
      _person['maritalStatus'] = userData?.maritalStatus;

    if (userData?.educationLevel !== null && userData.educationLevel.length > 0)
      _person['educationLevel'] = userData?.educationLevel;

    if (
      userData?.sacrificeStatus !== null &&
      userData.sacrificeStatus.length > 0
    )
      _person['sacrificeStatus'] = userData?.sacrificeStatus;
    if (userData?.dutyStatus !== null && userData.dutyStatus.length > 0)
      _person['dutyStatus'] = userData?.dutyStatus;
    if (userData?.phone !== null) _person['phone'] = userData?.phone;

    if (userData?.address !== null && userData?.address?.ostan?.id)
      _person['ostan'] = userData?.address?.ostan?.id || 0;

    if (userData?.address !== null && userData?.address?.shahrestan?.id)
      _person['shahrestan'] = userData?.address?.shahrestan?.id || 0;
    if (userData?.address !== null && userData?.address?.bakhsh?.id)
      _person['bakhsh'] = userData?.address?.bakhsh?.id || 0;

    if (userData?.address !== null && userData?.address?.dehestan?.id)
      _person['dehestan'] = userData?.address?.dehestan?.id || 0;

    if (userData?.ssnAddress !== null && userData?.ssnAddress?.ostan?.id)
      _person['ssnOstan'] = userData?.ssnAddress?.ostan?.id || 0;

    if (userData?.ssnAddress !== null && userData?.ssnAddress?.shahrestan?.id)
      _person['ssnShahrestan'] = userData?.ssnAddress?.shahrestan?.id || 0;

    if (userData?.address !== null && userData?.address?.postalCode)
      _person['postalCode'] = userData?.address?.postalCode;

    if (userData?.address !== null && userData?.address?.detail)
      _person['detail'] = userData?.address?.detail;

    if (userData?.companyName !== null)
      _company['companyName'] = userData?.companyName;

    if (userData?.companyType !== null)
      _company['companyType'] = userData?.companyType;

    if (userData?.companyEcoNumber !== null)
      _company['companyEcoNumber'] = userData?.companyEcoNumber;

    if (userData?.companySSNumber !== null)
      _company['companySSNumber'] = userData?.companySSNumber;

    if (
      userData?.companyAddress !== null &&
      userData?.companyAddress?.ostan?.id
    )
      _company['companyOstan'] = userData?.companyAddress?.ostan?.id;

    if (
      userData?.companyAddress !== null &&
      userData?.companyAddress?.shahrestan?.id
    )
      _company['companyShahr'] = userData?.companyAddress?.shahrestan?.id;

    if (
      userData?.companyAddress !== null &&
      userData?.companyAddress?.companyAddressDetail
    )
      _company['companyAddressDetail'] =
        userData?.companyAddress?.companyAddressDetail;

    if (
      userData?.companyAddress !== null &&
      userData?.companyAddress?.companyPostalCode
    )
      _company['companyPostalCode'] =
        userData?.companyAddress?.companyPostalCode;

    setPerson(_person);
    setCompany(_company);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const onHandleStep = () => {
    if (!!onStep && onValidation()) {
      setIsSubmit(true);
      setTimeout(() => {
        onSetPersonalInfo();
      }, 1000);
    }
  };
  const onValidation = () => {
    let vs = {
      person: {
        birthDate:
          person.birthDate !== undefined &&
          person.birthDate !== null &&
          person.undefined !== null,
        gender:
          (person.gender !== null && person.gender === 'male') ||
          person.gender === 'female',
        ostan: person.ostan !== undefined && person.ostan !== 0,
        shahrestan: person.shahrestan !== undefined && person.shahrestan !== 0,
        ssnOstan: person.ssnOstan !== undefined && person.ssnOstan !== 0,
        ssnShahrestan:
          person.ssnShahrestan !== undefined && person.ssnShahrestan !== 0,
        educationLevel:
          person.educationLevel !== undefined && person.educationLevel !== '',
        sacrificeStatus:
          person.sacrificeStatus !== undefined && person.sacrificeStatus !== '',
        maritalStatus:
          person.maritalStatus !== undefined && person.maritalStatus !== '',
        dutyStatus:
          person.gender === 'female'
            ? true
            : person.dutyStatus !== undefined && person.dutyStatus !== '',
        idCardNumber:
          person.idCardNumber !== undefined && person.idCardNumber.length !== 0,
        phone:
          person.phone !== null &&
          /0[1-8][1-9]{2}-?[0-9]{7}/gm.test(person.phone),
        postalCode:
          person.postalCode !== undefined && person.postalCode.length === 10,
        detail: person.detail !== undefined && person.detail.length > 0
      },
      company: {
        companyName:
          company.companyName !== undefined && company.companyName?.length > 0,
        companyRegisterNumber:
          company.companyRegisterNumber !== undefined &&
          company.companyRegisterNumber?.length > 0,
        companyOstan:
          company.companyOstan !== undefined &&
          company.companyOstan.length !== 0,
        companyShahr:
          company.companyShahr !== undefined &&
          company.companyShahr.length !== 0,
        companyPostalCode:
          company.companyPostalCode !== undefined &&
          company.companyPostalCode.length === 10,
        companyType:
          (company.companyType !== undefined &&
            company.companyType === 'PrivateHeld') ||
          company.companyType === 'Cooperative'
      }
    };
    setValidations(vs);
    let keys = Object.keys(vs.person);
    for (let i = 0; i < keys.length; i++) {
      if (!vs.person[keys[i]]) return false;
    }
    if (isPerson) return true;

    let keys2 = Object.keys(vs.company);
    for (let i = 0; i < keys2.length; i++) {
      if (!vs.company[keys2[i]]) return false;
    }
    return true;
  };

  const onSetPersonalInfo = () => {
    const _body = {
      ...person,
      birthDate: `${person.birthDate.year}-${person.birthDate.month}-${person.birthDate.day}`,
      ostan: parseInt(person.ostan),
      shahrestan: parseInt(person.shahrestan),
      bakhsh: parseInt(person.bakhsh),
      dehestan: parseInt(person.dehestan),
      // ssnAddressOstan: parseInt(person.ssnOstan),
      ssnAddressShahrestan: parseInt(person.ssnShahrestan),
      auth: true
    };
    localUserAPI.setPersonalInfo(_body).then(response => {
      if (response && response.id) {
        isPerson
          ? onStep(t(STEPS.find(_step => _step.id === 2)?.name))
          : onSetCompanyInfo();
      } else {
        toast(response.msg || t('notification.step1.error'), 'error');
        setIsSubmit(false);
      }
    });
  };

  const onSetCompanyInfo = () => {
    const _body = {
      ...company,
      companyOstan: parseInt(company.companyOstan),
      companyShahr: parseInt(company.companyShahr),
      auth: true
    };

    localUserAPI.setCompanyInfo(_body).then(response => {
      if (response) {
        onStep(t(STEPS.find(_step => _step.id === 2)?.name));
      } else {
        toast(response.msg || t('notification.step1.error'), 'error');
        setIsSubmit(false);
      }
    });
  };

  return (
    <>
      <Styled.Box>
        <Styled.PersonToggle>
          <div
            className={`${isPerson ? 'active' : ''}`}
            onClick={() => {
              if (isPerson) setIsPerson(true);
            }}
          >
            {t('toggle.personal')}
          </div>
          <div
            className={`${!isPerson ? 'active' : ''}`}
            onClick={() => {
              if (!isPerson) setIsPerson(false);
            }}
          >
            {t('toggle.legal')}
          </div>
        </Styled.PersonToggle>
        <Module.Personal
          user={userData}
          isPerson={isPerson}
          data={person}
          validations={validations}
          onData={_data => setPerson(_data)}
        />
        {!isPerson && (
          <Module.Company
            user={userData}
            data={company}
            onData={(_data, debug) => {
              setCompany(_data);
            }}
          />
        )}
      </Styled.Box>
      <Styled.StepButtons>
        <div />
        <Styled.StepButton onClick={onHandleStep}>
          {isSubmit ? <Loading /> : t('action.next_step')}
        </Styled.StepButton>
      </Styled.StepButtons>
    </>
  );
};

export default Step1Module;
