import Bar01 from 'assets/images/bar_01.svg';
import Bar02 from 'assets/images/bar_02.svg';
import Bar03 from 'assets/images/bar_03.svg';
import info01 from 'assets/images/info_01.png';
import info02 from 'assets/images/info_02.png';
import info03 from 'assets/images/info_03.png';
import Styled from 'components/Stock/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'antd';
import StocksAPI from 'services/apis/StocksAPI';
import { DEFAULTS } from '../constants';
import useToast from 'hooks/useToast';
import { useSelector } from 'react-redux';

const PackagesModule = () => {
  const { t } = useTranslation('stock');
  const [infoModal, setInfoModal] = useState({ visible: false, textIndex: 0 });
  const titles = [
    t('title.package_01'),
    t('title.package_02'),
    t('title.package_03')
  ];
  const infos = [
    '<strong>به سهامداری گفته می شود  که ضمن رعایت کلیه شرایط عمومی و اختصاصی مندرج در اساسنامه شرکت بطورکلی ملزم به رعایت شرایط زیر می باشد:</strong><ul><li>مالکیت حداقل ده هزارسهم از سهام شرکت</li><li>برخورداری از حق رای در مجامع شرکت</li><li>ورود و خروج از سهامداری با تائید هیات مدیره</li><li>سقف سهامداری، حداکثر 30% از سهام شرکت</li></ul>',
    '<strong>سهامدار غیرعضو سرمایه گذار به عنوان سهام دار غیر عضو برای یک دوره مشخص با خرید تعدادی از سهام شرکت با ارزش آتی سرمایه گذاری می‌کند. پیش بینی ارزش افزوده سرمایه گذاری سالیانه 24%مبلغ سرمایه گذاری می‌باشد. که به صورت علی الحساب ماهانه پرداخت می‌گردد.پس از پایان دوره سرمایه گذاری، سهامدار امکان خروج از سهامداری به پشتوانه سهامداران عضو وجود دارد و امکان تمدید سرمایه گذاری و یا جابجایی نوع سهامداری نسبت به وضعیت پروژه بررسی و اقدام می‌گردد.</strong><ul><li>امکان سرمایه‌گذاری از یک سهم (با ارزش آتی یک میلیون ریال) تا 9999 سهم </li><li>بهره مندی از ارزش افزوده سالانه 24% مبلغ سرمایه‌گذاری به صورت تضمینی </li><li>دوره سرمایه‌گذاری 30 ماه</li>',
    '<strong>به سهامداری گفته می شود  که ضمن رعایت کلیه شرایط عمومی و اختصاصی مندرج در اساسنامه شرکت بطورکلی ملزم به رعایت شرایط می‌باشد.</strong><ul><li>مالکیت حداقل یک سهم و حداکثر 9999 سهم از سهام شرکت </li><li>برخورداری از 10% حق رای مجموع سهامداران غیر عضو </li><li>بهره مندی از سود تقسیمی و منافع شرکت </li><li>تضمین بازپرداخت مبلغ سهام و خروج از سهامداری پشتوانه سهامدران عضو</li></ul>'
  ];
  //////////////////////////////////////////////////////////////
  const userType = useSelector(state => state.User.user?.userType);

  const [user, setUser] = useState(DEFAULTS.user);
  const localStocksAPI = new StocksAPI();
  const toast = useToast();
  const onGetUserStocks = () => {
    localStocksAPI.fetchUser().then(response => {
      if (response) {
        setUser(response);
      } else {
        toast(response.msg, 'error');
      }
    });
  };

  useEffect(() => {
    onGetUserStocks();
  }, []);

  return (
    <Styled.PackageContainer>
      {userType === 'None' && (
        <>
          <Styled.Package>
            <div className={'header'}>
              <img src={Bar01} alt={''} />
              <div className={'text'}>
                <h3 className={'title'}>{t('title.package_01')}</h3>
                <div className={'subtitle'}>
                  {t('subtitle.package_01')}
                  {'  '}
                  <img
                    src={info01}
                    alt={''}
                    onClick={() =>
                      setInfoModal({ visible: true, textIndex: 0 })
                    }
                    style={{ width: '15px', height: '15px' }}
                  />
                </div>
              </div>
            </div>
            <div className={'items'}>
              <div className={'item'}>
                <div className={'key'}>{t('item_01.key_01')}</div>
                <div className={'value'}>{t('item_01.value_01')}</div>
              </div>
              <div className={'item'}>
                <div className={'key'}>{t('item_01.key_02')}</div>
                {/*  */}
                <div className={'value'}>{user?.max}</div>
              </div>
            </div>
            <Link className={'action'} to={'/stock/buy/1/'}>
              {t('action.package_start')}
            </Link>
          </Styled.Package>
          <Styled.Package>
            <div className={'header'}>
              <img src={Bar03} alt={''} />
              <div className={'text'}>
                <h3 className={'title'}>{t('title.package_03')}</h3>
                <div className={'subtitle'}>
                  {t('subtitle.package_03')}
                  {'  '}
                  <img
                    src={info03}
                    alt={''}
                    onClick={() =>
                      setInfoModal({ visible: true, textIndex: 2 })
                    }
                    style={{ width: '15px', height: '15px' }}
                  />
                </div>
              </div>
            </div>
            <div className={'items'}>
              <div className={'item'}>
                <div className={'key'}>{t('item_03.key_01')}</div>
                <div className={'value'}>{t('item_03.value_01')}</div>
              </div>
              <div className={'item'}>
                <div className={'key'}>{t('item_03.key_02')}</div>
                <div className={'value'}>{user?.max}</div>
              </div>
            </div>
            <Link className={'action'} to={'/stock/buy/3/'}>
              {t('action.package_start')}
            </Link>
          </Styled.Package>
        </>
      )}

      {userType === 'NonMember' && (
        <Styled.Package>
          <div className={'header'}>
            <img src={Bar01} alt={''} />
            <div className={'text'}>
              <h3 className={'title'}>{t('title.package_01')}</h3>
              <div className={'subtitle'}>
                {t('subtitle.package_01')}
                {'  '}
                <img
                  src={info01}
                  alt={''}
                  onClick={() => setInfoModal({ visible: true, textIndex: 0 })}
                  style={{ width: '15px', height: '15px' }}
                />
              </div>
            </div>
          </div>
          <div className={'items'}>
            <div className={'item'}>
              <div className={'key'}>{t('item_01.key_01')}</div>
              <div className={'value'}>{t('item_01.value_01')}</div>
            </div>
            <div className={'item'}>
              <div className={'key'}>{t('item_01.key_02')}</div>
              {/*  */}
              <div className={'value'}>{user?.max}</div>
            </div>
          </div>
          <Link className={'action'} to={'/stock/buy/1/'}>
            {t('action.package_start')}
          </Link>
        </Styled.Package>
      )}

      {/* <Styled.Package>
        <div className={'header'}>
          <img src={Bar02} alt={''} />
          <div className={'text'}>
            <h3 className={'title'}>{t('title.package_02')}</h3>
            <div className={'subtitle'}>
              {t('subtitle.package_02')}
              {'  '}
              <img
                src={info02}
                alt={''}
                onClick={() => setInfoModal({ visible: true, textIndex: 1 })}
                style={{ width: '15px', height: '15px' }}
              />
            </div>
          </div>
        </div>
        <div className={'items'}>
          <div className={'item'}>
            <div className={'key'}>{t('item_02.key_01')}</div>
            <div className={'value'}>{t('item_02.value_01')}</div>
          </div>
          <div className={'item'}>
            <div className={'key'}>{t('item_02.key_02')}</div>
            <div className={'value'}>{t('item_02.value_02')}</div>
          </div>
        </div>
       <Link className={'action'} to={'/stock/buy/2/'}>
          {t('action.package_start')}
        </Link> 
       
      </Styled.Package> */}
      {userType === 'Member' && (
        <Styled.Package>
          <div className={'header'}>
            <img src={Bar03} alt={''} />
            <div className={'text'}>
              <h3 className={'title'}>{t('title.package_03')}</h3>
              <div className={'subtitle'}>
                {t('subtitle.package_03')}
                {'  '}
                <img
                  src={info03}
                  alt={''}
                  onClick={() => setInfoModal({ visible: true, textIndex: 2 })}
                  style={{ width: '15px', height: '15px' }}
                />
              </div>
            </div>
          </div>
          <div className={'items'}>
            <div className={'item'}>
              <div className={'key'}>{t('item_03.key_01')}</div>
              <div className={'value'}>{t('item_03.value_01')}</div>
            </div>
            <div className={'item'}>
              <div className={'key'}>{t('item_03.key_02')}</div>
              <div className={'value'}>{user?.max}</div>
            </div>
          </div>
          <Link className={'action'} to={'/stock/buy/3/'}>
            {t('action.package_start')}
          </Link>
        </Styled.Package>
      )}

      <Modal
        //Info Dialog
        style={{ maxHeight: '80px' }}
        visible={infoModal.visible}
        onCancel={() => {
          setInfoModal({ visible: false, textIndex: 0 });
        }}
        title={
          <h4 style={{ textAlign: 'center' }}>{titles[infoModal.textIndex]}</h4>
        }
        footer={[
          <Button
            key="back"
            type="back"
            onClick={() => {
              setInfoModal({ visible: false, textIndex: 0 });
            }}
          >
            بستن
          </Button>
        ]}
      >
        <Styled.RuleScroll>
          <div>
            <p
              style={{
                textAlign: 'justify',
                lineHeight: '20px',
                textSize: '12px'
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: infos[infoModal.textIndex]
                }}
              />
            </p>
          </div>
        </Styled.RuleScroll>
      </Modal>
    </Styled.PackageContainer>
  );
};

export default PackagesModule;
