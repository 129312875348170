import Styled from 'components/Home/styles';
import React from 'react';
// import { useTranslation } from 'react-i18next';

const NewsModule = () => {
  // const { t } = useTranslation('home');

  return <Styled.News>در دست ساخت...</Styled.News>;
};

export default NewsModule;
