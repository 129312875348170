import { css } from 'styled-components';

class MediaQuery {
  static styling = (size, style, down = true) => {
    const _breakPoints = {
      xs: 640,
      sm: 960,
      md: 1200,
      lg: 1600,
      xl: 1920
    };
    const _width = _breakPoints[size]
      ? down
        ? `${_breakPoints[size] - 1}px`
        : `${_breakPoints[size]}px`
      : down
      ? `${size - 1}px`
      : `${size}px`;

    return down
      ? css`
          @media screen and (max-width: ${_width}) {
            ${style}
          }
        `
      : css`
          @media screen and (min-width: ${_width}) {
            ${style}
          }
        `;
  };
}

export default MediaQuery;
