export const AUTHENTICATION = [
  { step: 'PERSONAL_INFO', title: 'navbar.log.step.1' },
  { step: 'COMPANY_INFO', title: 'navbar.log.step.2' },
  { step: 'BANK_INFO', title: 'navbar.log.step.3' },
  { step: 'UPLOAD_DOC_PERSONAL_PHOTO', title: 'navbar.log.step.4' },
  { step: 'UPLOAD_DOC_NATIONAL_CARD', title: 'navbar.log.step.5' },
  { step: 'UPLOAD_DOC_ID_CARD', title: 'navbar.log.step.6' },
  { step: 'UPLOAD_DOC_COMPANY', title: 'navbar.log.step.7' },
  { step: 'UPLOAD_DOC_BANK', title: 'navbar.log.step.8' },
  { step: 'PRE_BUY_STOCK', title: 'navbar.log.step.9' }
];

export const PERMISSIONS = [
  { step: 'MOBILE_VERIFIED', title: 'navbar.log.buy.step.1' },
  { step: 'DOCS_VERIFIED', title: 'navbar.log.buy.step.2' },
  { step: 'MEMBERSHIP_VERIFIED', title: 'navbar.log.buy.step.3' },
  { step: 'BOARD_MEMBER', title: 'navbar.log.buy.step.4' }
];
