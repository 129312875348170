import Header from 'layouts/Header';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from 'uikit/Tab';
import Styled from './styles';
import Module from './modules';

const Login = () => {
  const { t } = useTranslation('login');
  const [isPerson, setIsPerson] = useState(t('tab.person'));

  return (
    <Styled.Container>
      <Header
        title={{ color: t('title.color'), solid: t('title.solid') }}
        star={true}
      />
      <Tab
        init={isPerson}
        border={true}
        big={true}
        onSelect={_tab => setIsPerson(_tab)}
      >
        <div label={t('tab.person')} />
        <div label={t('tab.legal')} />
      </Tab>
      <Module.Login isPerson={isPerson === t('tab.person')} />
    </Styled.Container>
  );
};

export default Login;
