import { DEFAULTS, STEPS } from 'components/Authentication/constants';
import Styled from 'components/Authentication/styles';
import useToast from 'hooks/useToast';
import Loading from 'layouts/Loading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BankAPI from 'services/apis/BankAPI';
import Formatter from 'utils/Formatter';

const Step2Module = ({ userData, onStep }) => {
  const { t } = useTranslation('authentication');
  const [bank, setBank] = useState([DEFAULTS.bank]);
  const [banks, setBanks] = useState([]);
  const [card, setCard] = useState([['', '', '', '']]);
  const [isSubmit, setIsSubmit] = useState(false);
  const localBankAPI = new BankAPI();
  const toast = useToast();

  useEffect(() => {
    let _mounted = true;
    if (_mounted) {
      onGetUserBanks();
      onGetBanks();
    }

    return () => {
      _mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onBackStep = () => {
    onStep(t(STEPS.find(_step => _step.id === 1)?.name));
  };

  const onHandleStep = () => {
    if (!!onStep && onValidation()) {
      setIsSubmit(true);
      window.location.reload();
      setTimeout(() => {
        onStep(t(STEPS.find(_step => _step.id === 3)?.name));
      }, 1000);
    }
  };

  const onValidation = () =>
    bank.length > 0 &&
    bank.every(
      _bank =>
        _bank.id &&
        _bank.bankId !== 0 &&
        _bank.cardId.length === 16 &&
        _bank.sheba.length === 24 &&
        _bank.saved !== undefined &&
        _bank.saved !== null &&
        _bank.saved === true
    );

  const onAddRow = () => {
    if (bank.length < 11) {
      const _bank = [...bank];
      const _card = [...card];
      _bank.push(DEFAULTS.bank);
      _card.push(['', '', '', '']);
      setBank(_bank);
      setCard(_card);
    }
  };

  const onRemoveRow = _index => {
    const _bank = bank.filter((_item, _i) => _i !== _index);
    const _card = card.filter((_item, _i) => _i !== _index);
    setBank(_bank);
    setCard(_card);
  };

  const onHandleChange = event => {
    if (!event.target.disabled) {
      const _mode = event.target.inputMode;
      const _name = event.target.name;
      const _row = event.target.getAttribute('data-row');
      const _value =
        _mode === 'numeric'
          ? Formatter.changeNumberToEn(event.target.value).replace(/\D/, '')
          : event.target.value;

      if (_name.includes('cardId')) {
        const _card = [...card];
        _card[_row][_name.replace('cardId', '') - 1] = _value;
        setCard(_card);
        if (_value.length === 4 && parseInt(_name.replace('cardId', '')) < 4) {
          document
            .getElementById(
              `cardId${parseInt(_name.replace('cardId', '')) + 1}${_row}`
            )
            .focus();
        }

        const _bank = [...bank];
        if (
          document.getElementById(`cardId1${_row}`).value.length === 4 &&
          document.getElementById(`cardId2${_row}`).value.length === 4 &&
          document.getElementById(`cardId3${_row}`).value.length === 4 &&
          document.getElementById(`cardId4${_row}`).value.length === 4
        ) {
          _bank[_row] = {
            ..._bank[_row],
            cardId:
              document.getElementById(`cardId1${_row}`).value +
              document.getElementById(`cardId2${_row}`).value +
              document.getElementById(`cardId3${_row}`).value +
              document.getElementById(`cardId4${_row}`).value
          };
          setBank(_bank);
        } else {
          _bank[_row] = {
            ..._bank[_row],
            cardId: ''
          };
          setBank(_bank);
        }
      } else {
        const _bank = [...bank];
        _bank[_row] = { ..._bank[_row], [_name]: _value };
        setBank(_bank);
      }
    }
  };

  const onGetBanks = () => {
    localBankAPI.fetchBanks().then(response => {
      if (response && Array.isArray(response)) setBanks(response);
      else toast(t('notification.bank.error'), 'error');
    });
  };

  const onGetUserBanks = () => {
    localBankAPI.fetchBank().then(response => {
      if (response && Array.isArray(response)) {
        const _bank = [];
        const _card = [];
        response.forEach((_item, _index) => {
          _card.push([
            _item.cardId.substring(0, 4),
            _item.cardId.substring(4, 8),
            _item.cardId.substring(8, 12),
            ''
          ]);
          _bank.push({
            id: _item.id,
            bankId: _item.bankId,
            sheba: _item.sheba.replace('IR', ''),
            cardId: _item.cardId.substr(0, 12)
          });
        });
        setBank(_bank.length > 0 ? _bank : [DEFAULTS.bank]);
        setCard(_card.length > 0 ? _card : [['', '', '', '']]);
      } else {
        toast(t('notification.bank.error'), 'error');
      }
    });
  };

  const onAddBank = (_index, _bId, _card, _sheba) => {
    const _body = {
      bankId: parseInt(_bId),
      sheba: `IR${_sheba}`,
      cardId: _card,
      auth: true
    };
    localBankAPI.addBank(_body).then(response => {
      if (response && response.id) {
        const _bank = [...bank];
        _bank[_index] = {
          id: response.id,
          bankId: response.bankId,
          sheba: response.sheba.replace('IR', ''),
          cardId: response.cardId,
          saved: true
        };
        setBank(_bank);
        toast(t('notification.bank.add.success'), 'success');
      } else {
        if (response.data && Array.isArray(response.data)) {
          response.data.forEach(error => toast(error, 'error'));
        } else if (!response.data && response.msg) {
          toast(response.msg, 'error');
        } else {
          toast(t('notification.bank.add.error'), 'error');
        }
      }
    });
  };

  const onEditBank = (_index, _id, _bId, _card, _sheba) => {
    const _body = {
      id: _id,
      bankId: parseInt(_bId),
      sheba: `IR${_sheba}`,
      cardId: _card,
      auth: true
    };
    localBankAPI.editBank(_body).then(response => {
      if (response && response.id) {
        const _bank = [...bank];
        _bank[_index] = {
          id: response.id,
          bankId: response.bankId,
          sheba: response.sheba.replace('IR', ''),
          cardId: response.cardId,
          saved: true
        };
        setBank(_bank);
        toast(t('notification.bank.edit.success'), 'success');
      } else {
        if (response.data && Array.isArray(response.data)) {
          response.data.forEach(error => toast(error, 'error'));
        } else if (!response.data && response.msg) {
          toast(response.msg, 'error');
        } else {
          toast(t('notification.bank.edit.error'), 'error');
        }
      }
    });
  };

  const onDeleteBank = (_id, _bId, _card, _sheba, _b) => {
    const _body = {
      id: _id,
      // bankId: parseInt(_bId),
      // sheba: `IR${_sheba}`,
      // cardId: _card,
      auth: true
    };
    localBankAPI.deleteBank(_body).then(response => {
      if (response && response.code === 200) {
        const _bank = bank.filter(_item => _item.bankId !== _bId);
        const _card = card.filter((_item, _i) => _i !== _b);
        setBank(_bank);
        setCard(_card);
      } else {
        if (response.data && Array.isArray(response.data)) {
          response.data.forEach(error => toast(error, 'error'));
        } else if (!response.data && response.msg) {
          toast(response.msg, 'error');
        } else {
          toast(t('notification.bank.delete.error'), 'error');
        }
      }
    });
  };

  return (
    <>
      <Styled.Box>
        <Styled.Content>
          <Styled.Title>{t('form.bank.title')}</Styled.Title>
          <Styled.Description>
            <p>
              {t('form.bank.description.1')}
              <br />
              {t('form.bank.description.2')}
              {/*<br />*/}
              {/*<strong>{t('form.bank.description.3')}</strong>*/}
            </p>
            {/*<Link to={'/'}>*/}
            {/*  {t('form.bank.help')}*/}
            {/*  <span className={'icon'} />*/}
            {/*</Link>*/}
          </Styled.Description>
          <Styled.Spacer />
          <Styled.Divider />
          <Styled.Spacer size={45} />
          {bank !== null &&
            // card.length > 0 &&
            // card[0][0].length > 0 &&
            bank.map(
              (_row, _b) =>
                card[_b] && (
                  <React.Fragment key={`bank_row_item_${_b}`}>
                    <Styled.Fields>
                      <Styled.Field field={'select'}>
                        <label htmlFor={`bankId${_b}`} className={'label'}>
                          {t('form.label.bankId')}
                        </label>
                        <select
                          id={`bankId${_b}`}
                          name={'bankId'}
                          className={'input'}
                          value={_row.bankId}
                          disabled={false}
                          data-row={_b}
                          onChange={onHandleChange}
                        >
                          <option value={0} disabled={true} hidden={true} />
                          {banks.map((_bank, _index) => (
                            <option
                              key={`bank_item_${_b}${_index}`}
                              value={_bank.id}
                            >
                              {_bank.name}
                            </option>
                          ))}
                        </select>
                      </Styled.Field>

                      <Styled.Field
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '0 10px',
                          gap: '5px'
                        }}
                      >
                        <label htmlFor={`cardId${_b}`} className={'label'}>
                          {t('form.label.cardId')}
                        </label>
                        <input
                          id={`cardId1${_b}`}
                          name={'cardId1'}
                          className={'input wide center tiny'}
                          value={card[_b][0]}
                          disabled={false}
                          maxLength={4}
                          inputMode={'numeric'}
                          data-row={_b}
                          onChange={onHandleChange}
                          style={{ order: 4 }}
                        />
                        <input
                          id={`cardId2${_b}`}
                          name={'cardId2'}
                          className={'input wide center tiny'}
                          value={card[_b][1]}
                          disabled={false}
                          maxLength={4}
                          inputMode={'numeric'}
                          data-row={_b}
                          onChange={onHandleChange}
                          style={{ order: 3 }}
                        />
                        <input
                          id={`cardId3${_b}`}
                          name={'cardId3'}
                          className={'input wide center tiny'}
                          value={card[_b][2]}
                          disabled={false}
                          maxLength={4}
                          inputMode={'numeric'}
                          data-row={_b}
                          onChange={onHandleChange}
                          style={{ order: 2 }}
                        />
                        <input
                          id={`cardId4${_b}`}
                          name={'cardId4'}
                          className={'input wide center tiny'}
                          value={card[_b][3]}
                          disabled={false}
                          maxLength={4}
                          inputMode={'numeric'}
                          data-row={_b}
                          onChange={onHandleChange}
                          style={{ order: 1 }}
                        />
                      </Styled.Field>

                      <Styled.Field field={'sheba'}>
                        <label htmlFor={`sheba${_b}`} className={'label'}>
                          {t('form.label.sheba')}
                        </label>
                        <input
                          id={`sheba${_b}`}
                          name={'sheba'}
                          className={'input wide'}
                          value={_row.sheba}
                          disabled={false}
                          maxLength={24}
                          inputMode={'numeric'}
                          data-row={_b}
                          onChange={onHandleChange}
                        />
                      </Styled.Field>

                      <div className={'actions'}>
                        <span
                          className={`save ${
                            _row.bankId === 0 ||
                            _row.cardId.length !== 16 ||
                            _row.sheba.length !== 24
                              ? 'disabled'
                              : ''
                          }`}
                          onClick={() => {
                            if (
                              _row.bankId !== 0 &&
                              _row.cardId.length === 16 &&
                              _row.sheba.length === 24
                            )
                              _row.id
                                ? onEditBank(
                                    _b,
                                    _row.id,
                                    _row.bankId,
                                    _row.cardId,
                                    _row.sheba
                                  )
                                : onAddBank(
                                    _b,
                                    _row.bankId,
                                    _row.cardId,
                                    _row.sheba
                                  );
                          }}
                        >
                          {t('action.save_bank')}
                        </span>
                        <span
                          className={'delete'}
                          onClick={() => {
                            _row.id
                              ? onDeleteBank(
                                  _row.id,
                                  _row.bankId,
                                  _row.cardId,
                                  _row.sheba,
                                  _b
                                )
                              : onRemoveRow(_b);
                          }}
                        >
                          {t('action.delete_bank')}
                        </span>
                      </div>
                    </Styled.Fields>
                    <Styled.Spacer size={35} />
                  </React.Fragment>
                )
            )}
          {bank.length < 11 && (
            <Styled.AddBankButton onClick={onAddRow}>
              <span className={'icon'} />
              {t('action.add_bank')}
            </Styled.AddBankButton>
          )}
        </Styled.Content>
      </Styled.Box>
      <Styled.StepButtons>
        <Styled.StepButton onClick={onBackStep} isDisabled={false}>
          {t('action.prev_step')}
        </Styled.StepButton>
        <Styled.StepButton onClick={onHandleStep} isDisabled={!onValidation()}>
          {isSubmit ? <Loading /> : t('action.next_step')}
        </Styled.StepButton>
      </Styled.StepButtons>
    </>
  );
};

export default Step2Module;
