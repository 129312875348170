import Intro from './intro';
import Buy from './buy';
import Result from './result';

const Stock = {
  Intro,
  Buy,
  Result
};

export default Stock;
