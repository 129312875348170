import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const Public = ({ component: Component, auth: isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Redirect to={'/dashboard/'} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default Public;
