import Header from 'layouts/Header';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Styled from 'components/Stock/styles';

const ResultStock = () => {
  const { t } = useTranslation('stock');
  const { status } = useParams();

  return (
    <Styled.Container>
      <Styled.Spacer size={100} />
      <Header
        title={{
          color:
            typeof status === 'string' && parseInt(status) === 1
              ? t('title.result_final_success.color')
              : t('title.result_final_fail.color')
        }}
        subtitle={
          typeof status === 'string' && parseInt(status) === 1
            ? t('subtitle.is_authenticated')
            : ''
        }
        star={true}
      />
      <>
        <Styled.ResultActions>
          <Link className={'button primary'} to={'/dashboard/'}>
            {t('action.result.dashboard')}
          </Link>
          <Link className={'button secondary'} to={'/news/'}>
            {t('action.result.news')}
          </Link>
        </Styled.ResultActions>
        <Styled.Spacer size={150} />
      </>
    </Styled.Container>
  );
};

export default ResultStock;
