import Header from 'layouts/Header';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styles';
import Module from './modules';

const StockIntro = () => {
  const { t } = useTranslation('stock');
  return (
    <Styled.Container>
      <Header
        title={{ color: t('title.start.color') }}
        subtitle={t('subtitle.start')}
        star={true}
      />
      <Module.Steps />
      <Styled.Spacer size={110} />
      <Styled.HelpLink href={'#'} target={'_blank'} rel={'noreferrer'}>
        {t('action.steps_help')}
      </Styled.HelpLink>
      <Styled.Spacer size={80} />
      <Module.Packages />
      <Styled.Spacer size={60} />
    </Styled.Container>
  );
};

export default StockIntro;
