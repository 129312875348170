import ApiHelper from 'services/config/http';
import { Location } from 'services/config/url';

class LocationAPI extends ApiHelper {
  fetchProvinces = async () => {
    let result = {};
    await this.get(Location.fetchProvinces)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchShahrestans = async body => {
    let result = {};
    await this.get(`${Location.fetchProvinces}/${body}/shahrestan`)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchBakhshs = async body => {
    let result = {};
    await this.get(`${Location.fetchShahrestans}/${body}/bakhsh`)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchDehestans = async body => {
    let result = {};
    await this.get(`${Location.fetchBakhshs}/${body}/dehestan`)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
}

export default LocationAPI;
