export const DEFAULTS = {
  data: {
    captcha: '',
    national_code: '',
    mobile: '',
    company_type: '',
    password: '',
    first_name: '',
    last_name: '',
    father_name: '',
    ostan: 0,
    above_18: false,
    accept_rules: false,
    referral_code: '',
    company_ssn: '',
    company_name: ''
  },
  validation: {
    national_code: undefined,
    mobile: undefined,
    password: undefined,
    first_name: undefined,
    last_name: undefined,
    father_name: undefined,
    ostan: undefined,
    pin: undefined
  },
  password: {
    lowerCase: false,
    upperCase: false,
    number: false,
    charLength: false
  }
};
