import Modules from 'components/DashboardStock/modules';
import Styled from 'components/DashboardStock/styles';
import { DEFAULTS } from 'components/Stock/constants';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StocksAPI from 'services/apis/StocksAPI';
import CustomTable from 'uikit/table';
import { Spacer } from 'components/Register/styles';
import Loading from '../../../layouts/Loading';

const Tab2Module = ({ stats }) => {
  const { t } = useTranslation('dashboardStock');
  const [user, setUser] = useState(DEFAULTS.user);
  const [rule, setRule] = useState({ dialog: false, accept: false });
  const userType = useSelector(state => state.User.user?.userType);
  const localStocksAPI = new StocksAPI();
  const toast = useToast();

  const [memberType, setMemberType] = useState('None');
  const [memberTypeLoading, setMemberTypeLoading] = useState(false);
  const [showReceiptBox, setShowReceiptBox] = useState(false);

  const [tableData, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableQuery, setTableQuery] = useState({});
  const [tableTotalElements, setTableTotalElements] = useState(0);
  const tableColumns = [
    {
      title: 'تاریخ‌درخواست',
      key: 'date',
      dataIndex: 'date',
      sorter: true,
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs']
    },
    {
      title: 'نوع سهام‌داری',
      dataIndex: 'userType',
      key: 'userType',
      responsive: ['xxl', 'xl', 'lg']
    },
    {
      title: 'تعداد سهم',
      key: 'amount',
      dataIndex: 'amount',
      responsive: ['xxl', 'xl', 'lg', 'md'],
      render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'ارزش اسمی سهام (ریال)',
      key: 'parValuePerStock',
      dataIndex: 'parValuePerStock',
      responsive: ['xxl', 'xl', 'lg'],
      render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'نوع تراکنش',
      key: 'transactionType',
      dataIndex: 'transactionType',
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
      render: item => (
        <span
          style={{
            color: item === 'TRANSFER_BUY' ? '#64BC26' : '#EA1601'
          }}
        >
          {t('transactionType.' + item)}
        </span>
      )
    },
    {
      title: 'مبلغ پرداختی (ریال)',
      key: 'totalPrice',
      dataIndex: 'totalPrice',
      responsive: ['xxl', 'xl', 'lg'],
      render: item => (
        <span
          style={{
            color:
              item && item.transactionType === 'TRANSFER_BUY'
                ? '#64BC26'
                : '#EA1601'
          }}
        >
          {item.totalPrice}
          {item.transactionType === 'TRANSFER_BUY' ? ' + ' : ' - '}
        </span>
      )
    }
  ];
  const receiptsTableColumns = [...tableColumns];
  // receiptsTableColumns.push({
  //   title: 'اقدامات',
  //   key: 'actions',
  //   dataIndex: 'actions',
  //   responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
  //   render: item => (
  //     <>
  //       {/* <button>ثبت فیش</button> */}
  //       <button>انصراف</button>
  //     </>
  //   )
  // });
  useEffect(() => {
    getOfferings();
  }, []);

  useEffect(() => {
    onGetUserStocks(userType !== 'None');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  useEffect(() => {
    if (!user.flag) getUserTypeStocks();
    // eslint-disable-next-line
  }, [user]);

  const getOfferings = (
    { page, size, sort, asc } = {
      page: 0,
      size: 5,
      sort: 'date',
      asc: false
    }
  ) => {
    const query = {
      page: page ? page : 0,
      size: size ? size : 5,
      sort: sort ? sort : 'date',
      asc: asc ? asc : false
    };

    setTableQuery(query);
    setTableLoading(true);
    localStocksAPI
      .fetchOfferings(query)
      .then(response => {
        response.msg = 'خطا در دریافت اطلاعات';
        if (response && response.content) {
          if (Object(response).hasOwnProperty('totalElements'))
            setTableTotalElements(response.totalElements);
          setDataTable(
            response.content?.map(item => ({
              date: item.date?.toString().replace(/-/g, '/'),
              userType: t(`userType.${item.userType}`),
              totalPrice: item.totalPrice,
              parValuePerStock: item.parValuePerStock,
              amount: item.amount,
              transactionType: item.transactionType
            }))
          );
        } else {
          toast(response.msg, 'error');
        }
        setTableLoading(false);
      })
      .catch(resp => {
        toast(resp.msg, 'error');
        setTableLoading(false);
      });
  };

  const reloadOfferings = () => {
    getOfferings(tableQuery);
  };

  const onGetUserStocks = (_flag = true) => {
    localStocksAPI.fetchUser().then(response => {
      if (response && response.parValue) {
        setUser({ ...response, flag: _flag });
      } else {
        toast(response.msg, 'error');
      }
    });
  };

  const getUserTypeStocks = () => {
    localStocksAPI.fetchUserType().then(response => {
      if (
        response &&
        response.maxMember &&
        response.minMember &&
        response.maxNonMember &&
        response.minNonMember
      ) {
        setUser({
          ...user,
          // eslint-disable-next-line
          max: response.maxNonMember,
          // eslint-disable-next-line
          min: response.minNonMember,
          flag: true
        });
      } else {
        toast(response.msg, 'error');
      }
    });
  };

  const getUserType = type => {
    localStocksAPI.fetchUserType().then(response => {
      if (type === 'Member') {
        const tempUser = {
          max: response.maxMember,
          min: response.minMember,
          parValue: response.parValueMember,
          pending: user.pending,
          owned: user.owned,
          flag: user.flag
        };
        setUser(tempUser);
      } else if (type === 'NonMember') {
        const tempUser = {
          max: response.maxNonMember,
          min: response.minNonMember,
          parValue: response.parValueNonMember,
          pending: user.pending,
          owned: user.owned,
          flag: user.flag
        };
        setUser(tempUser);
      } else if (type === 'NonMemberInvestor') {
        const tempUser = {
          max: response.maxNonMemberInvestor,
          min: response.minNonMemberInvestor,
          parValue: response.parValueNonMemberInvestor,
          pending: user.pending,
          owned: user.owned,
          flag: user.flag
        };
        setUser(tempUser);
      }
    });
  };
  const handleMemberType = event => {
    setMemberTypeLoading(true);
    setMemberType(event.target.value);
    getUserType(event.target.value);

    setTimeout(() => {
      setMemberTypeLoading(false);
    }, 500);
  };
  return (
    <>
      <ConfigProvider direction="rtl">
        {memberTypeLoading ? (
          <Loading />
        ) : (stats && stats.userType !== 'None') || memberType !== 'None' ? (
          <>
            <Modules.Invoice
              tab={
                stats && stats.userType && stats.userType !== 'None'
                  ? stats.userType
                  : memberType
              }
              price={user.parValue}
              min={user.min}
              enabled={user.max}
              max={user.max + user.owned + user.pending}
              owned={user.owned}
              reserved={user.pending}
              rule={rule}
              onRule={(_dialog, _accept) =>
                setRule({ dialog: _dialog, accept: _accept })
              }
            />
            <Spacer size={50} />
            <Styled.StatsContainer>
              <div style={{ width: '100%' }}>
                <b>تراکنش های در انتظار ثبت فیش</b>
                <Spacer size={20} />
                <CustomTable
                  paginationBottom={'bottomCenter'}
                  className="special-styled-table"
                  data={tableData}
                  columns={receiptsTableColumns}
                  loading={tableLoading}
                  onChange={getOfferings}
                  total={tableTotalElements}
                  defaultSort={'date'}
                  onFinish={reloadOfferings}
                />
              </div>
            </Styled.StatsContainer>
            <Spacer size={50} />
            <Styled.StatsContainer>
              <div className={'text'}>
                <h3 className={'title'}>سوابق خرید و خروج از سهامداری</h3>
                <div style={{ width: '100%' }}>
                  <Spacer size={20} />
                  <CustomTable
                    paginationBottom={'bottomCenter'}
                    data={tableData}
                    columns={tableColumns}
                    loading={tableLoading}
                    onChange={getOfferings}
                    total={tableTotalElements}
                    defaultSort={'date'}
                    onFinish={reloadOfferings}
                  />
                </div>
              </div>
            </Styled.StatsContainer>
          </>
        ) : (
          <></>
        )}

        <Spacer size={50} />

        <Modules.Stats stats={stats} />
      </ConfigProvider>
    </>
  );
};

export default Tab2Module;
