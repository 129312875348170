import Styled from 'components/DashboardStock/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  InputNumber,
  Modal,
  Row
} from 'antd';
import Modules from 'components/DashboardStock/modules';
import CustomTable from 'uikit/table';
import StocksAPI from 'services/apis/StocksAPI';
import useToast from 'hooks/useToast';
import faIR from 'antd/lib/locale/fa_IR';
import { Spacer } from 'components/Register/styles';

const Tab3Module = ({ stats }) => {
  const { t } = useTranslation('dashboardStock');
  const [tableData, setDataTable] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableQuery, setTableQuery] = useState({});
  const [tableTotalElements, setTableTotalElements] = useState(0);
  const localStocksAPI = new StocksAPI();
  const toast = useToast();
  const statuses = {
    CREATED: 'ساخته شده',
    MODIFIED: 'ویرایش شده',
    CANCELED: 'ملغی',
    EXPIRED: 'منقضی',
    PENDING_FOR_PAY: 'در انتظار پرداخت',
    PENDING_FOR_VERIFY: 'در انتظار تایید اوپراتور',
    PENDING_FOR_VERIFY_RECEIPT: 'در انتظار تایید فیش',
    PENDING_FOR_VERIFY_DOCS: 'در انتظار تایید مدارک',
    PENDING_FOR_VERIFY_MEMBERSHIP: 'در انتظار تایید عضویت',
    PENDING_FOR_VERIFY_TRANSACTION: 'در انتظار تایید تراکنش',
    PENDING_FOR_CREATE_IN_BC: 'در انتظار ساخت در بلاکچین',
    PENDING_FOR_CEO_SIGN: 'در انتظار امضا',
    PENDING_FOR_SIGN_IN_BC: 'در انتظار امضا در بلاکچین',
    REJECT_BC_SIGN: 'رد امضا بلاکچین',
    REJECT_BC: 'رد بلاکچین',
    APPROVED_BC: 'تایید بلاکچین',
    REJECT_SIGN: 'رد امضا',
    REJECT_MEMBERSHIP: 'رد عضویت'
  };
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const [targetTransactionId, setTargetTransactionId] = useState('');

  const [sellAmount, setSellAmount] = useState(0);
  const [maxSellAmount, setMaxSellAmount] = useState(1);
  const [assured, setAssured] = useState(false);
  const [targetId, setTargetId] = useState(0);
  const tableColumns = [
    {
      title: 'تاریخ‌درخواست',
      key: 'date',
      dataIndex: 'date',
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
      sorter: true
    },
    {
      title: 'نوع سهام‌داری',
      dataIndex: 'userType',
      key: 'userType',
      responsive: ['xxl', 'xl', 'lg']
    },
    {
      title: 'کل سهم',
      dataIndex: 'amount',
      key: 'amount',
      responsive: ['xxl', 'xl', 'lg'],
      render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'مبلغ پرداختی (ریال)',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      responsive: ['xxl', 'xl', 'lg', 'md'],
      render: item => item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    {
      title: 'وضعیت تایید',
      dataIndex: 'action',
      key: 'action',
      responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
      render: item => (
        <>
          {item?.status === 'APPROVED_BC' ? (
            <Button
              disabled={item.amount === 0}
              onClick={() => {
                setSellAmount(0);
                setMaxSellAmount(item.amount);
                setConfirmModalVisible(true);
                setAssured(false);
                setTargetId(item.transactionId);
              }}
            >
              درخواست خروج
            </Button>
          ) : (
            <span>{statuses[item?.status]}</span>
            // <span>{item?.status}</span>
          )}
        </>
      )
    }
  ];

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = (
    { page, size, sort, asc } = {
      page: 0,
      size: 5,
      sort: 'date',
      asc: false
    }
  ) => {
    const query = {
      page: page ? page : 0,
      size: size ? size : 5,
      sort: sort ? sort : 'date',
      asc: asc ? asc : false
    };

    setTableQuery(query);
    setTableLoading(true);
    localStocksAPI
      .fetchHistory(query)
      .then(response => {
        response.msg = 'خطا در دریافت اطلاعات';
        if (response && response.content) {
          if (Object(response).hasOwnProperty('totalElements'))
            setTableTotalElements(response.totalElements);
          setDataTable(
            response.content?.map(item => ({
              date: item.date?.replace(/-/g, '/'),
              userType: t(`userType.${item.userType}`),
              amount: item.amount,
              totalPrice: item.totalPrice,
              sellPrice: item.sellPrice,
              action: item
            }))
          );
        } else {
          toast(response.msg, 'error');
        }
        setTableLoading(false);
      })
      .catch(resp => {
        toast(resp.msg, 'error');
        setTableLoading(false);
      });
  };

  const reloadHistory = () => {
    getHistory(tableQuery);
  };

  const handleConfirmOk = () => {
    const body = {
      amount: sellAmount,
      transactionId: targetId
    };
    setConfirmButtonLoading(true);
    localStocksAPI
      .sellStock(body)
      .then(response => {
        if (response.code && response.code !== 200) {
          toast(response.msg || t('undefinedErrorOnRequest'), 'error');
        } else {
          toast('درخواست ارسال شد', 'success');
        }
        setConfirmButtonLoading(false);
        setConfirmModalVisible(false);
        reloadHistory();
      })
      .catch(response => {
        toast(response.msg, 'error');
        setConfirmModalVisible(false);
      });
  };

  return (
    <>
      <Modal
        style={{ direction: 'ltr' }}
        visible={confirmModalVisible}
        onCancel={() => setConfirmModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setConfirmModalVisible(false)}>
            لغو
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmButtonLoading}
            onClick={handleConfirmOk}
            disabled={!assured}
          >
            تایید
          </Button>
        ]}
      >
        <br />
        <div style={{ direction: 'rtl' }}>
          <Row justify={'center'}>
            <Col span={18}>
              <p>تعداد سهمی که مایل به فروش آن هستید را مشخص کنید:</p>
            </Col>
            <Col span={6}>
              <div>
                <Row
                  style={{ width: '100%' }}
                  justify={'center'}
                  align={'middle'}
                >
                  <Col span={12}>
                    <InputNumber
                      disabled={assured}
                      max={maxSellAmount}
                      min={0}
                      value={sellAmount}
                      onChange={value => {
                        if (value > maxSellAmount) setSellAmount(maxSellAmount);
                        else if (value < 0) setSellAmount(0);
                        else setSellAmount(value);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Checkbox
              disabled={sellAmount === 0}
              onChange={() => {
                setAssured(!assured);
              }}
              checked={assured}
            >
              از فروش سهام خود اطمینان دارم.
            </Checkbox>
          </Row>
        </div>
      </Modal>
      <ConfigProvider locale={faIR} direction="rtl">
        <Styled.StatsContainer>
          <div className={'text'}>
            <h3 className={'title'}>{t('box.stockHistory.title')}</h3>
            <div style={{ width: '100%' }}>
              <Styled.Spacer size={20} />
              <CustomTable
                paginationBottom={'bottomCenter'}
                data={tableData}
                columns={tableColumns}
                loading={tableLoading}
                onChange={getHistory}
                total={tableTotalElements}
                defaultSort={'date'}
                onFinish={reloadHistory}
              />
            </div>
          </div>
        </Styled.StatsContainer>
      </ConfigProvider>

      <Spacer size={50} />

      <Modules.Stats stats={stats} />
    </>
  );
};

export default Tab3Module;
