import Carousel, {
  autoplayPlugin,
  slidesToShowPlugin
} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Brand1 from 'assets/images/brand_01.png';
import Brand2 from 'assets/images/brand_02.png';
import Brand3 from 'assets/images/brand_03.png';
import Brand4 from 'assets/images/brand_04.png';
import Brand5 from 'assets/images/brand_05.png';
import Brand6 from 'assets/images/brand_06.png';
import Brand7 from 'assets/images/brand_07.png';
import Brand8 from 'assets/images/brand_08.png';
import Brand9 from 'assets/images/brand_09.png';
import Brand10 from 'assets/images/brand_10.png';
import Brand11 from 'assets/images/brand_11.png';
import Brand12 from 'assets/images/brand_12.png';
import Styled from 'components/Home/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULTS } from '../constants';
import AuthAPI from 'services/apis/AuthAPI';
import useToast from 'hooks/useToast';

const BrandsModule = () => {
  const { t } = useTranslation('home');
  const toast = useToast();

  const ocalStockDataList = new AuthAPI();
  const [stockData, setStockData] = useState(DEFAULTS.stock);

  const fetchData = async () => {
    try {
      const response = await ocalStockDataList.StockDataListIndex();
      setStockData(response);
    } catch (error) {
      toast(t('notification.bank.error'), 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Styled.Brands>
      <Carousel
        plugins={[
          'infinite',
          'arrows',
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides:
                window.innerWidth > 1200
                  ? 4
                  : window.innerWidth > 960
                  ? 3
                  : window.innerWidth > 640
                  ? 2
                  : 1
            }
          },
          {
            resolve: autoplayPlugin,
            options: {
              interval: 2000
            }
          }
        ]}
      >
        <img
          src={Brand1}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand2}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand3}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand4}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand5}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand6}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand7}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand8}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand9}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand10}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand11}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
        <img
          src={Brand12}
          alt={''}
          style={{ width: '350px', height: '180px' }}
        />
      </Carousel>

      {/* این قسمت برای بخش سوم سایت است که در مورد تعداد سهام دار میزان آب تولیدی و زمان باقی مانده عرضه است */}
      <div className={'items'}>
        <div className={'item'}>
          <h4 className={'title'}>
            <span className={'icon icon-03'} />
            {stockData && stockData.water}
          </h4>
          <div className={'text'}>{t('brands.item.text.3')}</div>
        </div>
        {/* تعدا د سهامدار عضو */}
        <div className={'item'}>
          <h4 className={'title'}>
            <span className={'icon icon-02'} />
            {stockData && stockData.member}
          </h4>
          <div className={'text'}>{t('brands.item.text.2.1')}</div>
        </div>
        {/* تعدا د سهامدار غیر عضو */}
        <div className={'item'}>
          <h4 className={'title'}>
            <span className={'icon icon-02'} />
            {stockData && stockData.nonMember}
          </h4>
          <div className={'text'}>{t('brands.item.text.2.2')}</div>
        </div>
        <div className={'item'}>
          <h4 className={'title'}>
            <span className={'icon icon-01'} />
            {stockData && stockData.lastDays >= -1
              ? stockData.lastDays
              : 'زمان عرضه پایان رسیده'}
          </h4>
          <div className={'text'}>{t('brands.item.text.1')}</div>
        </div>
      </div>
    </Styled.Brands>
  );
};

export default BrandsModule;
