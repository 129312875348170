import React, { useEffect, useState } from 'react';
import Styled from './styles';

const Steps = ({ total, current }) => {
  const [totalSteps, setTotalSteps] = useState([]);

  useEffect(() => {
    onGenerateSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGenerateSteps = () => {
    const _step = [];
    _step[total - 1] = '';
    _step.fill('');
    setTotalSteps(_step);
  };

  return (
    <Styled.Container>
      <Styled.StepBar progress={(current - 1) / (total - 1)} />
      {totalSteps.map((_step, _index) => (
        <Styled.Step
          key={`step_item_${_index}`}
          type={
            _index + 1 < current
              ? 'before'
              : _index + 1 === current
              ? 'current'
              : 'after'
          }
        >
          {_index + 1 < current ? (
            <svg viewBox="0 0 20 14">
              <path d="M18.609 0.470738C19.2278 1.0987 19.2278 2.11663 18.609 2.74428L7.98201 13.5293C7.36322 14.1569 6.36048 14.1569 5.7417 13.5293L0.682837 8.39502C0.0640542 7.76737 0.0640542 6.74944 0.682837 6.12179C1.30131 5.49383 2.30436 5.49383 2.92283 6.12179L6.8617 10.1191L16.3687 0.470738C16.9875 -0.156913 17.9905 -0.156913 18.609 0.470738Z" />
            </svg>
          ) : (
            ''
          )}
        </Styled.Step>
      ))}
    </Styled.Container>
  );
};

export default Steps;
