import Styled from 'components/Home/styles';
import Header from 'layouts/Header';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PackagesModule = () => {
  const { t } = useTranslation('home');

  return (
    <Styled.Packages>
      <Header
        title={{ color: t('packages.title.color') }}
        noPadding={true}
        star={true}
      />
      <div className={'subtitle'}>{t('packages.subtitle')}</div>
      <Styled.PackagesItems>
        <Link className={'item'} to={'/stock/buy/1/'}>
          <h4 className={'title'}>{t('packages.items.title.1')}</h4>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.1.key.1')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.1.value.1')}
            </div>
          </div>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.1.key.2')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.1.value.2')}
            </div>
          </div>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.1.key.3')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.1.value.3')}
            </div>
          </div>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.1.key.4')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.1.value.4')}
            </div>
          </div>
        </Link>

        {/* <Link className={'item'} to={'/stock/buy/2/'}>
          <h4 className={'title'}>{t('packages.items.title.2')}</h4>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.2.key.1')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.2.value.1')}
            </div>
          </div>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.2.key.2')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.2.value.2')}
            </div>
          </div>
          <div className={'property'}>
      <div className={'key'}>{t('packages.items.property.2.key.3')}</div>
      <div className={'space'} />
      <div className={'value'}>
        {t('packages.items.property.2.value.3')}
      </div>
          </div>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.2.key.4')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.2.value.4')}
            </div>
          </div>
        </Link> */}

        <Link className={'item'} to={'/stock/buy/3/'}>
          <h4 className={'title'}>{t('packages.items.title.3')}</h4>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.3.key.1')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.3.value.1')}
            </div>
          </div>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.3.key.2')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.3.value.2')}
            </div>
          </div>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.3.key.3')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.3.value.3')}
            </div>
          </div>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.3.key.4')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.3.value.4')}
            </div>
          </div>
          <div className={'property'}>
            <div className={'key'}>{t('packages.items.property.3.key.5')}</div>
            <div className={'space'} />
            <div className={'value'}>
              {t('packages.items.property.3.value.5')}
            </div>
          </div>
        </Link>
      </Styled.PackagesItems>
      <div className={'actions'}>
        <Link to={'/register/'} className={'button'}>
          {t('packages.action.button')}
        </Link>
      </div>
    </Styled.Packages>
  );
};

export default PackagesModule;
