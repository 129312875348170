import Brands from './Modules/brands';
import Features from './Modules/features';
import News from './Modules/news';
import Packages from './Modules/packages';
import Slider from './Modules/slider';
import Steps from './Modules/steps';

const Modules = {
  Brands,
  Features,
  News,
  Packages,
  Slider,
  Steps
};

export default Modules;
