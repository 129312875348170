export const DEFAULTS = {
  person: {
    birthDate: '',
    gender: '',
    state: 0,
    shahrestan: 0,
    postalCode: '',
    detail: ''
  },
  personSelect: {
    state: '',
    shahrestan: ''
  },
  company: {
    companyName: '',
    companyRegisterNumber: '',
    companySSNumber: '',
    companyEcoNumber: '',
    companyOstan: 0,
    companyShahr: 0,
    companyAddressDetail: '',
    companyPostalCode: ''
  },
  companySelect: {
    companyOstan: '',
    companyShahr: ''
  },
  password: {
    lowerCase: false,
    upperCase: false,
    number: false,
    charLength: false
  },
  bank: {
    bankId: 0,
    sheba: '',
    cardId: ''
  },
  bankReceipt: {
    orderId: -1,
    refId: '',
    amount: -1,
    saleReferenceId: -1,
    receiptImageId: '',
    bankId: '',
    bankCode: '',
    date: '',
    time: ''
  }
};

export const TABS = [
  { id: 1, name: 'tab.title.1' },
  { id: 2, name: 'tab.title.2' },
  { id: 3, name: 'tab.title.3' },
  { id: 4, name: 'tab.title.4' }
];

export const AUTHENTICATION = [
  'PERSONAL_INFO',
  'COMPANY_INFO',
  'BANK_INFO'
  // 'UPLOAD_DOC_PERSONAL_PHOTO',
  // 'UPLOAD_DOC_NATIONAL_CARD',
  // 'UPLOAD_DOC_ID_CARD',
  // 'UPLOAD_DOC_COMPANY',
  // 'UPLOAD_DOC_BANK'
  // 'PRE_BUY_STOCK'
];

export const DOCS = {
  personal: [
    'PERSONAL_PHOTO',
    'ID_CARD_P_1_2',
    'ID_CARD_P_3_4',
    'ID_CARD_P_5_6',
    'NATIONAL_CARD_P_1',
    'NATIONAL_CARD_P_2',
    'MAIN_BANK_CART',
    'DUTY_CARD_P_1',
    'DUTY_CARD_P_2'
  ],
  legal: [
    'PERSONAL_PHOTO',
    'ID_CARD_P_1_2',
    'ID_CARD_P_3_4',
    'ID_CARD_P_5_6',
    'NATIONAL_CARD_P_1',
    'NATIONAL_CARD_P_2',
    'MAIN_BANK_CART',
    'RECENT_GENERAL_ORDINARY_CHANGE',
    'RECENT_MD_CHANGE',
    'FOUNDED_AD',
    'DUTY_CARD_P_1',
    'DUTY_CARD_P_2'
  ]
};
