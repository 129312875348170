import ApiHelper from 'services/config/http';
import { Auth } from 'services/config/url';

class AuthAPI extends ApiHelper {
  loginStep1 = async body => {
    let result = {};
    await this.post(Auth.loginStep1, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  loginStep2 = async body => {
    let result = {};
    await this.post(Auth.loginStep2, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  registerStep1 = async body => {
    let result = {};
    await this.post(Auth.registerStep1, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  registerStep2 = async body => {
    let result = {};
    await this.post(Auth.registerStep2, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  verifyMobile = async body => {
    let result = {};
    await this.post(Auth.verifyMobile, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
  //برای داده های مربوط به تعداد اعضا و میزان آب و زمان باقی مانه عرضه
  StockDataListIndex = async () => {
    let result = {};
    await this.get(Auth.StockDataIndex)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
}

export default AuthAPI;
