import Styled from 'components/Stock/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const TabsModule = ({ tab }) => {
  const userType = useSelector(state => state.User.user?.userType);

  const { t } = useTranslation('stock');

  return (
    <Styled.Tabs>
      {userType === 'None' && (
        <>
          <Link
            className={`item ${tab === 1 ? 'active' : ''}`}
            to={'/stock/buy/1/'}
          >
            {t('title.package_01')}
          </Link>
          <Link
            className={`item ${tab === 3 ? 'active' : ''}`}
            to={'/stock/buy/3/'}
          >
            {t('title.package_03')}
          </Link>
        </>
      )}
      {userType === 'NonMember' && (
        <Link
          className={`item ${tab === 1 ? 'active' : ''}`}
          to={'/stock/buy/1/'}
        >
          {t('title.package_01')}
        </Link>
      )}

      {/* <Link
        className={`item ${tab === 2 ? 'active' : ''}`}
        to={'/stock/buy/2/'}
      >
        {t('title.package_02')}
      </Link> */}
      {userType === 'Member' && (
        <Link
          className={`item ${tab === 3 ? 'active' : ''}`}
          to={'/stock/buy/3/'}
        >
          {t('title.package_03')}
        </Link>
      )}
    </Styled.Tabs>
  );
};

export default TabsModule;
