import ApiHelper from 'services/config/http';
import { Bank } from 'services/config/url';

class UserAPI extends ApiHelper {
  fetchBanks = async () => {
    let result = {};
    await this.get(Bank.fetchBanks)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchBank = async () => {
    let result = {};
    await this.get(Bank.fetchBank)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  addBank = async body => {
    let result = {};
    await this.post(Bank.addBank, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  deleteBank = async body => {
    let result = {};
    await this.post(`${Bank.deleteBank}/${body.id}`, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  editBank = async body => {
    let result = {};
    await this.post(Bank.editBank, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
}

export default UserAPI;
