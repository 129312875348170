import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import MediaQuery from 'utils/MediaQuery';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  & > .top {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    background: #fafafc;
    padding: 60px 80px 30px 80px;

    ${MediaQuery.styling(
      'sm',
      css`
        padding: 40px 20px 20px 20px;
      `
    )}
  }
  & > .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #fff;
    padding: 25px 50px;
    direction: ltr;

    ${MediaQuery.styling(
      'xs',
      css`
        gap: 15px;
        padding: 15px 20px;
        flex-direction: column-reverse;
      `
    )}

    .contact {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      ${MediaQuery.styling(
        'xs',
        css`
          gap: 15px;
          flex-direction: column;
        `
      )}

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 12px;
        font-weight: 300;
        line-height: 24px;
        color: #050505;
      }
    }

    .copy {
      flex: 1;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #050505;
    }
  }
`;

const Content = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${MediaQuery.styling(
    'sm',
    css`
      flex-direction: column;
    `
  )}

  & > .menus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    min-width: 45%;

    ${MediaQuery.styling(
      'md',
      css`
        width: 60%;
        min-width: 60%;
      `
    )}

    ${MediaQuery.styling(
      'sm',
      css`
        width: 100%;
        min-width: 100%;
        flex-direction: column;
      `
    )}
  }
`;

const Menu = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: right;
  flex: 1;

  ${MediaQuery.styling(
    'sm',
    css`
      width: 100%;
      align-items: center;
      margin: 0 0 45px 0;
    `
  )}
`;

const Header = styled('h3')`
  font-size: 18px;
  font-weight: 700;
  color: #1d4371;
  margin: 0 0 45px 0;

  ${MediaQuery.styling(
    'sm',
    css`
      margin: 0 0 25px 0;
    `
  )}
`;

const Item = styled(Link)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 15px;
  color: #9c9cbd;
  transition: all ease 0.3s;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: #5d55a1;
  }
`;

const Contact = styled('div')`
  text-align: center;
  img {
    margin-bottom: 20px;
  }
  .address {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #9c9cbd;
    margin-bottom: 30px;
  }
  .social {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 23px;
      height: 23px;
      img {
        margin: 0;
      }
    }
  }
`;

const Styled = {
  Container,
  Content,
  Menu,
  Header,
  Item,
  Contact
};

export default Styled;
