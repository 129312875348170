import Image from 'assets/images/slider.jpg';
import Styled from 'components/Home/styles';
import { DEFAULTS } from 'components/Stock/constants';
import useToast from 'hooks/useToast';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AuthAPI from 'services/apis/AuthAPI';

const SliderModule = () => {
  const { t } = useTranslation('home');

  const toast = useToast();
  const ocalStockDataList = new AuthAPI();
  const [stockData, setStockData] = useState(DEFAULTS.stock);

  const fetchData = async () => {
    try {
      const response = await ocalStockDataList.StockDataListIndex();
      setStockData(response);
    } catch (error) {
      toast(t('notification.bank.error'), 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* <h2>زمان عرضه سهام به پایان رسیده است</h2> */}

      {stockData && stockData.lastDays <= -1 && (
        <p
          className="alert alert-danger"
          role="alert"
          style={{
            padding: '.75rem 1.25rem',
            margin: 0,
            width: 'inherit',
            position: 'relative',
            textAlign: 'center'
          }}
        >
          مهلت عرضه سهام به پایان رسیده است !
        </p>
      )}

      <Styled.Slider image={Image}>
        <Styled.SliderContent>
          <p className={'title'}>{t('slider.title.first')}</p>
          <p className={'title'}>{t('slider.title.second')}</p>

          <div className={'subtitle'}>
            <p>{t('slider.subtitle.first')}</p>
            <p>{t('slider.subtitle.second')}</p>
            <p>{t('slider.subtitle.third')}</p>
          </div>

          <div className={'actions'}>
            {/* <Link to={'/'} className={'button'}>
              {t('slider.action.first')}
            </Link> */}
            <Link to={'register/'} className={'button'}>
              {t('slider.action.second')}
            </Link>
          </div>
        </Styled.SliderContent>
      </Styled.Slider>
    </>
  );
};

export default SliderModule;
