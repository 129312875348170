export const DEFAULTS = {
  stock: {
    id: '',
    water: 0,
    allmember: 0,
    lastDate: 0,
    lastDays: 0,
    nonMemberDate: 0,
    nonMemberDays: 0,
    nonMemberInvestor: 0,
    nonMemberInvestorDate: 0,
    nonMemberInvestorDays: 0,
    memberDate: 0,
    memberDays: 0,
    nonMember: 0,
    member: 0
  }
};
