export const DEFAULTS = {
  data: {
    captcha: '',
    national_code: '',
    mobile: '',
    company_type: '',
    password: ''
  },
  validation: {
    national_code: undefined,
    mobile: undefined,
    password: undefined,
    pin: undefined
  },
  stock: {
    water: 0,
    allmember: 0,
    lastDate: 0,
    lastDays: 0,
    nonMemberDate: 0,
    nonMemberDays: 0,
    nonMemberInvestorDate: 0,
    nonMemberInvestorDays: 0,
    memberDate: 0,
    memberDays: 0
  }
};
