import styled, { css, keyframes } from 'styled-components';
import MediaQuery from 'utils/MediaQuery';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
  height: 240px;
  position: relative;

  ${MediaQuery.styling(
    'sm',
    css`
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;
      height: auto;
    `
  )}
  & > .dzu-dropzone {
    overflow: hidden;
    height: 100%;
    width: 60%;
    background: #ffffff;
    border: 1px solid #d0c9df;
    border-radius: 5px;
    margin: 0;

    ${MediaQuery.styling(
      'sm',
      css`
        width: 100%;
        height: 240px;
      `
    )}
  }
`;

const Label = styled('div')`
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 18px;
  font-weight: 300;
  background: #fff;
  color: #888888;
  margin: 0;
  padding: 0 10px 0 15px;
  transform: translateY(-50%);
  z-index: 1;

  ${MediaQuery.styling(
    'md',
    css`
      font-size: 16px;
    `
  )}

  ${MediaQuery.styling(
    'sm',
    css`
      font-size: 14px;
    `
  )}
`;

const Buzz = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const InputLayout = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  height: 100%;

  & > svg {
    height: 45px;
    transition: all ease 0.3s;
  }

  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    & > span {
      font-size: 14px;
      font-weight: 300;
      color: #9c9cbd;
      transition: all ease 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      &.error {
        color: #c71720;
        animation: ${Buzz} 0.5s ease infinite;
      }

      &.success {
        color: #1a873c;
      }

      &.upload {
        animation: none;
      }

      .button {
        width: 10px;
        height: 10px;
        background: #f0f0f9;
        fill: #c71720;
        box-sizing: content-box;
        padding: 4px;
        border-radius: 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
          background: #c71720;
          fill: #ffffff;
        }
      }
    }
  }

  & > .label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }
`;

const Image = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f9;
  width: 50%;
  height: 100%;
  padding: 5px;
  border-radius: 5px;

  ${MediaQuery.styling(
    'sm',
    css`
      width: 100%;
      height: 240px;
    `
  )}
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    transition: all ease 0.3s;

    ${MediaQuery.styling(
      'sm',
      css`
        object-fit: contain;
      `
    )}

    ${({ loaded }) =>
      loaded
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0.25;
          `};
  }
`;

const Styled = {
  Container,
  Label,
  InputLayout,
  Image
};

export default Styled;
