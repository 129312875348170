import styled, { css } from 'styled-components';
import MediaQuery from 'utils/MediaQuery';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1280px;
  padding: 0 20px 90px 20px;

  ${MediaQuery.styling(
    'md',
    css`
      max-width: 100%;
      padding: 0 20px 60px 20px;
    `
  )};
`;

const Box = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  background: ${({ equal }) => (equal ? '#FAFAFC' : '#ffffff')};
  border: ${({ equal }) => (equal ? 'none' : '1px solid #e5e5e5')};
  border-radius: ${({ equal }) => (equal ? '10px' : '15px')};
  position: relative;
  padding: ${({ equal }) =>
    equal ? '25px 70px 25px 55px' : '35px 48px 55px 48px'};
  ${({ equal }) =>
    equal &&
    css`
      margin-bottom: 45px;
    `};

  ${MediaQuery.styling(
    'lg',
    css`
      padding: ${({ equal }) =>
        equal ? '25px 50px 25px 35px' : '35px 38px 55px 38px'};
    `
  )};

  ${MediaQuery.styling(
    'md',
    css`
      padding: ${({ equal }) =>
        equal ? '20px 30px 20px 25px' : '25px 30px 45px 30px'};
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      padding: 40px 30px 50px 30px;
    `
  )};
`;

const Content = styled('div')`
  display: flex;
  width: 100%;
  ${({ bar }) =>
    bar
      ? css`
          flex-direction: row;
          align-items: center;
          justify-content: center;

          ${MediaQuery.styling(
            'sm',
            css`
              flex-direction: column;
            `
          )};
        `
      : css`
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        `};

  ${({ isTop }) =>
    isTop &&
    css`
      align-items: flex-start;

      ${MediaQuery.styling(
        'sm',
        css`
          align-items: center;
          justify-content: flex-start;
        `
      )};
    `};

  ${({ space }) =>
    space &&
    css`
      gap: ${space}px;

      ${MediaQuery.styling(
        'md',
        css`
          gap: ${space / 2}px;
        `
      )};

      ${MediaQuery.styling(
        'sm',
        css`
          gap: 20px;
        `
      )};
    `};
`;

const Title = styled('h3')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  font-size: 18px;
  font-weight: 700;
  color: #5d55a1;
  width: 100%;
  margin: 0 0 25px 0;

  ${MediaQuery.styling(
    'md',
    css`
      font-size: 16px;
      font-weight: 500;
      margin: 0 0 20px 0;
    `
  )};

  &:after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background: rgba(208, 201, 223, 0.73);
  }
`;

const Description = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 30px;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  text-align: right;
  color: #88888b;

  ${MediaQuery.styling(
    'md',
    css`
      font-size: 16px;
      line-height: 28px;
      gap: 20px;
    `
  )};

  & > p {
    flex: 1;
    margin: 0;
  }

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    min-width: 175px;
    width: 175px;
    height: 45px;
    font-size: 12px;
    font-weight: 400;
    color: #9c9cbd;
    background: #e5e5f5;
    border-radius: 5px;
    transition: all ease 0.3s;

    &:hover {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    }

    .icon {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-color: transparent;
      background-position: 50% 50%;
      background-size: 20px;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M17.3002 3.60554C15.4104 1.74777 12.8979 0.724609 10.2254 0.724609C7.55294 0.724609 5.04042 1.74777 3.15065 3.60554C1.26095 5.46339 0.220215 7.93347 0.220215 10.5608C0.220215 13.1882 1.26095 15.6582 3.15065 17.5161C5.04042 19.3739 7.55294 20.397 10.2254 20.397C12.8979 20.397 15.4104 19.3739 17.3002 17.5161C19.1899 15.6582 20.2306 13.1882 20.2306 10.5608C20.2306 7.93347 19.1899 5.46339 17.3002 3.60554ZM10.2254 19.2443C5.35504 19.2443 1.3927 15.3489 1.3927 10.5608C1.3927 5.7727 5.35504 1.87729 10.2254 1.87729C15.0958 1.87729 19.0581 5.7727 19.0581 10.5608C19.0581 15.3489 15.0958 19.2443 10.2254 19.2443Z' fill='%239C9CBD'/%3E%3Cpath d='M14.8677 10.0933L7.83279 5.09836C7.65434 4.97164 7.41867 4.95393 7.22267 5.05245C7.02667 5.15096 6.90332 5.34915 6.90332 5.56558V15.5555C6.90332 15.7719 7.02667 15.9701 7.22267 16.0686C7.30685 16.111 7.39834 16.1318 7.48948 16.1318C7.61052 16.1318 7.73098 16.095 7.83279 16.0227L14.8677 11.0277C15.0203 10.9194 15.1107 10.7456 15.1107 10.5605C15.1107 10.3755 15.0204 10.2017 14.8677 10.0933ZM8.07581 14.4283V6.69275L13.5232 10.5605L8.07581 14.4283Z' fill='%239C9CBD'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='20.0104' height='19.6724' fill='white' transform='translate(0.220215 0.724609)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }
`;

const Spacer = styled('div')`
  display: block;
  width: 100%;
  height: ${({ size }) => (size ? `${size}px` : '30px')};

  ${MediaQuery.styling(
    'md',
    css`
      height: ${({ size }) => (size ? `${size / 2}px` : '15px')};
    `
  )};
`;

const Divider = styled('div')`
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(208, 201, 223, 0.73);
`;

const Fields = styled('div')`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  align-items: center;
  justify-content: center;
  row-gap: 35px;
  column-gap: 40px;
  position: relative;

  ${MediaQuery.styling(
    'md',
    css`
      row-gap: 25px;
      column-gap: 30px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      row-gap: 15px;
      column-gap: 20px;
    `
  )};

  & > .actions {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 10px;
    box-shadow: 5px 0 10px 5px rgba(255, 255, 255, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.3s;

    ${MediaQuery.styling(
      'sm',
      css`
        position: relative;
        padding: 0;
        grid-column: span 6;
        opacity: 1;
        visibility: visible;
        margin: 0 30px;
      `
    )};

    & > span {
      font-size: 12px;
      font-weight: 500;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: all ease 0.3s;

      ${MediaQuery.styling(
        'sm',
        css`
          flex: 1;
          align-items: center;
          justify-content: center;
          display: flex;
        `
      )};

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }

      &.save {
        background: #1a873c;
        color: #ffffff;
      }

      &.delete {
        color: #c71720;
      }

      &:not(.disabled) {
        &:hover {
          &.delete {
            background: #c71720;
            color: #ffffff;
          }
        }
      }
    }

    &.disabled {
      display: none;
    }
  }

  &:hover {
    & > .actions {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Field = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: ${({ isActive }) =>
    isActive ? '1px solid #1d4371' : '1px solid #d0c9df'};
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  grid-column: span 2;

  ${MediaQuery.styling(
    'md',
    css`
      height: 56px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      height: 48px;
      grid-column: span 6;
    `
  )};

  & > .label {
    font-size: 16px;
    font-weight: 400;
    background: #fff;
    color: #c4c4c4;
    margin: 0;
    padding: 0 18px 0 0;
    white-space: nowrap;

    ${MediaQuery.styling(
      'md',
      css`
        font-size: 15px;
        padding: 0 15px 0 0;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        font-size: 14px;
      `
    )};

    &:after {
      content: ':';
    }
  }

  & > .input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    width: auto;
    height: 100%;
    border: none;
    background: none;
    padding: 0 15px;
    text-align: right;
    appearance: none;
    font-size: 16px;
    font-weight: 400;
    min-width: 0;

    ${MediaQuery.styling(
      'md',
      css`
        font-size: 15px;
        padding: 0 10px;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        font-size: 14px;
      `
    )};

    &.wide {
      letter-spacing: 2px;

      ${MediaQuery.styling(
        'sm',
        css`
          letter-spacing: 1px;
        `
      )};
    }
    &.tiny {
      padding: 0;
      border: 1px solid #e5e5f5;
      height: 75%;
      border-radius: 5px;
    }
    &.ltr {
      direction: ltr;
    }
    &.center {
      text-align: center;
    }
    &.active {
      color: #1d4371;
    }
    &:disabled {
      color: #88888b;
      cursor: not-allowed;

      &.active {
        color: #5d55a1;
      }
    }
    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      color: #c4c4c4;

      ${MediaQuery.styling(
        'md',
        css`
          font-size: 15px;
        `
      )};

      ${MediaQuery.styling(
        'sm',
        css`
          font-size: 14px;
        `
      )};
    }
  }

  & > .icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 60px;
    width: 28px;
    padding: 0 12px;
    box-sizing: content-box;
    fill: #d0c9df;
    transition: all ease 0.3s;
    cursor: pointer;

    ${MediaQuery.styling(
      'md',
      css`
        height: 100%;
        width: 25px;
      `
    )};

    &.on {
      fill: #1d4371;
    }
  }

  & > .action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    min-width: 25px;
    height: 100%;
    padding: 0 12px;
    box-sizing: content-box;
    cursor: pointer;

    ${MediaQuery.styling(
      'md',
      css`
        width: 20px;
        min-width: 20px;
      `
    )};

    & > svg {
      fill: #d0c9df;
      transition: all ease 0.3s;
    }

    &:hover {
      & > svg {
        fill: #88888b;
      }
    }

    &.active {
      & > svg {
        fill: #1d4371;
      }
    }
  }

  ${({ field }) =>
    field === 'password-strength'
      ? css`
          border: none;
          border-radius: 0;
          & > .strength {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 10px;
            height: 10px;

            ${MediaQuery.styling(
              'md',
              css`
                height: 8px;
                gap: 8px;
              `
            )};

            span {
              display: block;
              height: 100%;
              width: auto;
              flex: 1;
              background: #d0c9df;
              border-radius: 5px;
              transition: all ease 0.3s;

              &.on {
                background: #1d4371;
              }

              &.ok {
                background: #64bc26;
              }
            }

            .text {
              width: 60px;
              font-size: 12px;
              font-weight: 400;
              color: #d0c9df;
              text-align: left;

              &.on {
                color: #1d4371;
              }

              &.ok {
                color: #64bc26;
              }
            }
          }
        `
      : field === 'select'
      ? css`
          & > .input {
            cursor: pointer;
          }
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
            z-index: 1;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.34157 11.3565L16.6511 2.4701C17.1163 1.90482 17.1163 0.988604 16.6511 0.423604C16.1863 -0.141442 15.4324 -0.141442 14.9677 0.423604L8.49997 8.28645L2.03231 0.423604C1.56729 -0.141442 0.813503 -0.141442 0.348741 0.423604C-0.116247 0.988649 -0.116247 1.9051 0.348741 2.47037L7.65837 11.3568C7.89089 11.6392 8.1953 11.7803 8.49997 11.7803C8.80453 11.7803 9.10917 11.6389 9.34157 11.3565Z' fill='%239C9CBD'/%3E%3C/svg%3E%0A");
            background-attachment: scroll;
            background-repeat: no-repeat;
            background-position: 50% 100%;
            background-color: transparent;
            background-size: 10px;
            width: 10px;
            height: 10px;
            cursor: pointer;
            transition: all ease 0.3s;
          }
        `
      : field === 'sheba'
      ? css`
          /* padding: 0 15px 0 45px; */
          & > .input {
            padding: 0 15px 0 45px;
            ${MediaQuery.styling(
              'md',
              css`
                padding: 0 10px 0 40px;
              `
            )};
          }

          &:after {
            content: 'IR';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            position: absolute;
            top: 0;
            left: 10px;
            font-size: 24px;
            font-weight: 500;
            line-height: 62px;
            color: #5d55a1dd;

            ${MediaQuery.styling(
              'md',
              css`
                line-height: 58px;
                font-size: 20px;
                width: 28px;
              `
            )};

            ${MediaQuery.styling(
              'sm',
              css`
                line-height: 50px;
              `
            )};
          }
        `
      : field === 'calendar' &&
        css`
          cursor: text;

          &:after {
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-color: transparent;
            background-attachment: scroll;
            background-size: 28px;
            ${({ icon }) =>
              icon &&
              css`
                background-image: url(${icon});
              `};
          }

          & > .calendar {
            position: relative;
            z-index: 999;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-5px);
            transition: all ease 0.3s;
            cursor: initial;

            &.active {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
            }
          }
        `};

  ${({ size }) =>
    size === 'full'
      ? css`
          grid-column: span 6;
        `
      : size === 'half'
      ? css`
          grid-column: span 1;
        `
      : size === 'quarter' &&
        css`
          grid-column: span 5;
        `}
`;

const Action = styled(Field)`
  border: none;
  border-radius: 0;
  background: none;
  justify-content: flex-end;
`;

const SaveButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #1d4371;
  border-radius: 5px;
  width: 285px;
  height: 60px;
  font-size: 14px;
  font-weight: 400;
  color: #1d4371;
  transition: all ease 0.3s;

  ${MediaQuery.styling(
    'md',
    css`
      width: 225px;
      height: 56px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      width: 100%;
      height: 48px;
    `
  )};

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          cursor: not-allowed;
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
          background: #1d4371;
          color: #ffffff;
          &:hover {
            background: #4424a9;
            border: 1px solid #4424a9;
          }
        `};
`;

const Overlay = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 9999;

  & > .title {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    transition: all ease 0.3s;
    cursor: pointer;

    &:hover {
      color: #1d4371;
    }
  }
`;

const FingerPrint = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  min-width: 150px;
  height: 155px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(208, 201, 223, 0.5);
  border-radius: 44px;
  padding: 45px;
  position: relative;

  ${MediaQuery.styling(
    'lg',
    css`
      width: 130px;
      min-width: 130px;
      height: 135px;
      padding: 35px;
      border-radius: 34px;
    `
  )};

  ${MediaQuery.styling(
    'md',
    css`
      width: 100px;
      min-width: 100px;
      height: 105px;
      padding: 25px;
      border-radius: 24px;
    `
  )};

  & > svg {
    ${({ isActive }) =>
      isActive
        ? css`
            fill: url(#gradient);
          `
        : css`
            fill: #e5e5f5;
          `};
  }

  & > .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.14);
    border-radius: 40px;
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 10px;

    ${MediaQuery.styling(
      'lg',
      css`
        width: 35px;
        min-width: 35px;
        height: 35px;
        top: 20px;
        right: 20px;
      `
    )};

    ${MediaQuery.styling(
      'md',
      css`
        width: 30px;
        min-width: 30px;
        height: 30px;
        top: 15px;
        right: 15px;
        padding: 8px;
      `
    )};

    & > svg {
      max-width: 100%;
      max-height: 100%;
      ${({ isActive }) =>
        isActive
          ? css`
              fill: url(#gradient);
            `
          : css`
              fill: #1d4371;
            `};
    }
  }
`;

const AuthContent = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  & > .title {
    ${({ isActive }) =>
      isActive
        ? css`
            font-size: 20px;
            font-weight: 700;
            color: #1d4371;

            ${MediaQuery.styling(
              'lg',
              css`
                font-size: 18px;
              `
            )};

            ${MediaQuery.styling(
              'md',
              css`
                font-size: 16px;
              `
            )};
          `
        : css`
            font-weight: 500;
            font-size: 18px;
            color: #1d4371;

            ${MediaQuery.styling(
              'lg',
              css`
                font-size: 17px;
              `
            )};

            ${MediaQuery.styling(
              'md',
              css`
                font-size: 15px;
              `
            )};
          `};
  }

  & > .subtitle {
    font-size: 18px;
    font-weight: 300;
    color: #322e55;

    ${MediaQuery.styling(
      'lg',
      css`
        font-size: 17px;
      `
    )};
  }

  & > .reasons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 15px;

    ${MediaQuery.styling(
      'lg',
      css`
        padding-right: 10px;
      `
    )};

    span {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      color: #322e55;

      ${MediaQuery.styling(
        'lg',
        css`
          font-size: 16px;
          line-height: 24px;
        `
      )};

      &:before {
        content: '';
        display: inline-flex;
        background: #1d4371;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-left: 12px;
        vertical-align: middle;
      }
    }
  }

  & > .uploaded {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    gap: 6px;
    margin-bottom: 30px;

    &.not {
      border-top: 1px solid #d0c9df;
      padding-top: 25px;
      margin-bottom: 0;
    }

    & > .title {
      font-size: 18px;
      font-weight: 700;
      color: #1d4371;
      width: 100%;
      margin-bottom: 6px;

      ${MediaQuery.styling(
        'lg',
        css`
          font-size: 17px;
        `
      )};

      ${MediaQuery.styling(
        'md',
        css`
          font-size: 16px;
        `
      )};

      &:after {
        content: ':';
      }
    }

    &.not {
      & > .title {
        color: #1d4371;
      }
    }

    & > .item {
      width: 40%;
      font-size: 18px;
      font-weight: 300;
      color: #322e55;

      ${MediaQuery.styling(
        'lg',
        css`
          font-size: 16px;
        `
      )};

      ${MediaQuery.styling(
        'md',
        css`
          font-size: 15px;
        `
      )};

      ${MediaQuery.styling(
        'sm',
        css`
          font-size: 14px;
          width: 100%;
        `
      )};

      &:before {
        content: '';
        display: inline-flex;
        background: #1d4371;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin: 0 12px;
        vertical-align: middle;

        ${MediaQuery.styling(
          'md',
          css`
            width: 6px;
            height: 6px;
            border-radius: 6px;
            margin: 0 10px;
          `
        )};
      }
    }
  }
`;

const AuthAction = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 285px;
  min-width: 285px;
  height: 60px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  transition: all ease 0.3s;

  ${MediaQuery.styling(
    'md',
    css`
      width: 225px;
      min-width: 225px;
      height: 56px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      width: 100%;
      min-width: auto;
      height: 48px;
      font-size: 14px;
    `
  )};

  ${({ isActive }) =>
    isActive
      ? css`
          border: none;
          background: linear-gradient(
            158deg,
            rgba(29, 67, 113, 1) 0%,
            rgba(53, 162, 198, 1) 52%
          );
          color: #ffffff;
          &:hover {
            box-shadow: 0 3px 12px 0 rgb(0 0 0 / 20%);
          }
        `
      : css`
          border: 1px solid #1d4371;
          background: #ffffff;
          color: #1d4371;
          &:hover {
            background: #9c9cbd10;
          }
        `};

  ${({ isEnd }) =>
    isEnd &&
    css`
      align-self: flex-end;
    `};
`;

const AddBankButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  background: #ffffff;
  border: 1px solid #1d4371;
  border-radius: 5px;
  width: 150px;
  height: 38px;
  font-size: 14px;
  font-weight: 400;
  color: #1d4371;
  cursor: pointer;
  transition: all ease 0.3s;

  & > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: transparent;
    background-size: 15px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 7.5C0 8.32845 0.67155 9 1.5 9H6V13.5C6 14.3285 6.67155 15 7.5 15C8.32845 15 9 14.3285 9 13.5V9H13.5C14.3285 9 15 8.32845 15 7.5C15 6.67155 14.3285 6 13.5 6H9V1.5C9 0.67155 8.32845 0 7.5 0C6.67155 0 6 0.67155 6 1.5V6H1.5C0.67155 6 0 6.67155 0 7.5Z' fill='%237F55FA'/%3E%3C/svg%3E%0A");
  }

  &:hover {
    background: #1d4371;
    color: #ffffff;
    & > .icon {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 7.5C0 8.32845 0.67155 9 1.5 9H6V13.5C6 14.3285 6.67155 15 7.5 15C8.32845 15 9 14.3285 9 13.5V9H13.5C14.3285 9 15 8.32845 15 7.5C15 6.67155 14.3285 6 13.5 6H9V1.5C9 0.67155 8.32845 0 7.5 0C6.67155 0 6 0.67155 6 1.5V6H1.5C0.67155 6 0 6.67155 0 7.5Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
    }
  }
`;

const Styled = {
  Container,
  Box,
  Content,
  Title,
  Description,
  Spacer,
  Divider,
  Fields,
  Field,
  Action,
  SaveButton,
  Overlay,
  FingerPrint,
  AuthContent,
  AuthAction,
  AddBankButton
};

export default Styled;
