import Styled from 'components/DashboardStock/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-jalaali';

moment.loadPersian({ dialect: 'persian-modern' });

const Tab1Module = ({ stats }) => {
  const { t } = useTranslation('dashboardStock');
  // useEffect(() => {
  //   console.log(moment.jDaysInMonth(parseInt(moment().format('jYYYY')), 11));
  // }, []);
  return (
    <Styled.BoxContainer>
      <Styled.Box equal={true} stat={true}>
        <h3 className={'title'}>
          {t('box.stat.title.1')} ({t('subtitle.rial')})
        </h3>
        <div className={'content'}>
          <span className={'big'}>
            {stats.parValuePerStock
              ? (stats && stats.parValuePerStock)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : '-'}
          </span>
        </div>
      </Styled.Box>
      <Styled.Box equal={true} stat={true}>
        <h3 className={'title'}>
          {t('box.stat.title.2')} ({t('subtitle.rial')})
        </h3>
        <div className={'content'}>
          <span className={'big'}>
            {stats.registeredCapital
              ? (stats && stats.registeredCapital)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : '-'}
          </span>
        </div>
      </Styled.Box>
      <Styled.Box equal={true} stat={true}>
        <h3 className={'title'}>{t('box.stat.title.3')}</h3>
        <div className={'content'}>
          {stats &&
            stats.offeringPeriod &&
            stats.offeringPeriod.from &&
            stats.offeringPeriod.to && (
              <>
                <span className={'big'}>
                  {moment(stats.offeringPeriod.from).format('jD')} تا{' '}
                  {moment(stats.offeringPeriod.to).format('jD')}
                </span>
                <span className={'small'}>
                  {moment(stats.offeringPeriod.from).jYear() ===
                  moment(stats.offeringPeriod.to).jYear()
                    ? moment(stats.offeringPeriod.from).jMonth() ===
                      moment(stats.offeringPeriod.to).jMonth()
                      ? moment(stats.offeringPeriod.from).format('jMMMM jYYYY')
                      : `${moment(stats.offeringPeriod.from).format(
                          'jMMMM'
                        )} تا ${moment(stats.offeringPeriod.to).format(
                          'jMMMM jYYYY'
                        )}`
                    : `${moment(stats.offeringPeriod.from).format(
                        'jMMMM jYYYY'
                      )} تا ${moment(stats.offeringPeriod.to).format(
                        'jMMMM jYYYY'
                      )}`}
                </span>
              </>
            )}
        </div>
      </Styled.Box>
      <Styled.Box equal={true} stat={true}>
        <h3 className={'title'}>
          {t('box.stat.title.4')}
          <div className={'date'}>{moment().format('jMMMM jYYYY')}</div>
        </h3>
        <div className={'content'}>
          <div className={'calendar'}>
            {Array.from(
              Array(
                moment.jDaysInMonth(
                  parseInt(moment().format('jYYYY')),
                  parseInt(moment().format('jMM')) - 1
                )
              )
            ).map((_d, _i) => (
              <div
                key={`day_${_i}`}
                className={`day ${moment().jDate() === _i + 1 ? 'active' : ''}`}
              >
                {_i + 1}
              </div>
            ))}
          </div>
        </div>
      </Styled.Box>
    </Styled.BoxContainer>
  );
};

export default Tab1Module;
