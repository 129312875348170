import Step01 from 'assets/images/step_01.svg';
import Step02 from 'assets/images/step_02.svg';
import Step03 from 'assets/images/step_03.svg';
import Step04 from 'assets/images/step_04.svg';
import Styled from 'components/Home/styles';
import Header from 'layouts/Header';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StepsModule = () => {
  const { t } = useTranslation('home');

  return (
    <Styled.Steps>
      <Header
        title={{
          color: t('steps.title.color'),
          solid: t('steps.title.solid')
        }}
        noPadding={true}
      />
      <Styled.StepsItems>
        <div className={'item'}>
          <div className={'icon'}>
            <img src={Step01} alt={''} />
          </div>
          <h3 className={'title'}>{t('steps.items.title.1')}</h3>
          <div className={'description'}>{t('steps.items.description.1')}</div>
        </div>

        <div className={'item'}>
          <div className={'icon'}>
            <img src={Step02} alt={''} />
          </div>
          <h3 className={'title'}>{t('steps.items.title.2')}</h3>
          <div className={'description'}>{t('steps.items.description.2')}</div>
        </div>

        <div className={'item'}>
          <div className={'icon'}>
            <img src={Step03} alt={''} />
          </div>
          <h3 className={'title'}>{t('steps.items.title.3')}</h3>
          <div className={'description'}>{t('steps.items.description.3')}</div>
        </div>

        <div className={'item'}>
          <div className={'icon'}>
            <img src={Step04} alt={''} />
          </div>
          <h3 className={'title'}>{t('steps.items.title.4')}</h3>
          <div className={'description'}>{t('steps.items.description.4')}</div>
        </div>
      </Styled.StepsItems>
      <div className={'actions'}>
        <Link to={'/register/'} className={'button'}>
          {t('steps.action.button')}
        </Link>
      </div>
    </Styled.Steps>
  );
};

export default StepsModule;
