import ApiHelper from 'services/config/http';
import { Docs } from 'services/config/url';

class DocsAPI extends ApiHelper {
  submitDocs = async body => {
    let result = {};
    await this.post(Docs.submitDocs, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchTypes = async () => {
    let result = {};
    await this.get(Docs.fetchTypes)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchDocs = async () => {
    let result = {};
    await this.get(Docs.fetchDocs)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchStatus = async () => {
    let result = {};
    await this.get(Docs.fetchStatus)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
}

export default DocsAPI;
