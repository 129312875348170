import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const Private = ({ component: Component, auth: isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated ? <Redirect to={'/login/'} /> : <Component {...props} />
    }
  />
);

export default Private;
