import Stats from './Modules/stats';
import Invoice from './Modules/invoice';
import Rules from './Modules/rules';
import Tab1 from './Modules/tab1';
import Tab2 from './Modules/tab2';
import Tab3 from './Modules/tab3';
import Tab4 from './Modules/tab4';

const Modules = {
  Stats,
  Invoice,
  Rules,
  Tab1,
  Tab2,
  Tab3,
  Tab4
};

export default Modules;
