import Loading from 'layouts/Loading/loading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import UserAPI from 'services/apis/UserAPI';
import UserActions from 'store/stores/User/actions';
import Tab from 'uikit/Tab';
import { AUTHENTICATION, TABS } from './constants';
import Modules from './modules';
import Styled from './styles';

const Dashboard = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useDispatch();
  const [tab, setTab] = useState('');
  const userData = useSelector(state => state.User.user);
  const localUserAPI = new UserAPI();

  useEffect(() => {
    let _mounted = true;
    if (_mounted) onGetUserData();
    setTimeout(() => {
      if (_mounted) setTab(t(TABS[0].name));
    }, 1000);

    return () => {
      _mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [tab]);

  const onHandleAuthentication = () => {
    return !AUTHENTICATION.includes(userData?.authStep);
  };

  const onHandleTab = _tab => {
    setTab(_tab);
  };

  const onDisplayTab = _tab => {
    const _key = TABS.find(_step => t(_step.name) === _tab)?.id;
    const _tabs = {
      1: (
        <Modules.Tab1
          userData={userData}
          onUpdate={onGetUserData}
          isAuth={onHandleAuthentication}
        />
      ),
      2: (
        <>
          {onHandleAuthentication() ? (
            <Modules.Tab2
              userData={userData}
              onUpdate={onGetUserData}
              isAuth={onHandleAuthentication}
            />
          ) : (
            <Redirect to={'/authentication/'} />
          )}
        </>
      ),
      3: (
        <>
          {onHandleAuthentication() ? (
            <Modules.Tab3
              userData={userData}
              onUpdate={onGetUserData}
              isAuth={onHandleAuthentication}
            />
          ) : (
            <Redirect to={'/authentication/'} />
          )}
        </>
      ),
      4: (
        <>
          {onHandleAuthentication() ? (
            <Modules.Tab4
              userData={userData}
              onUpdate={onGetUserData}
              isAuth={onHandleAuthentication}
            />
          ) : (
            <Redirect to={'/authentication/'} />
          )}
        </>
      )
    };
    return _tabs[_key];
  };

  const onGetUserData = () => {
    localUserAPI.fetchProfile().then(response => {
      if (response && response.id) {
        dispatch(UserActions.PatchUserData(response));
      }
    });
  };

  return (
    <Styled.Container>
      {tab.length === 0 ? (
        <Loading />
      ) : (
        <>
          <Tab init={tab} border={false} big={false} onSelect={onHandleTab}>
            {TABS.map((_tab, _index) => (
              <div key={`tab_item_${_index}`} label={t(_tab.name)} />
            ))}
          </Tab>

          {userData && onDisplayTab(tab)}
        </>
      )}
    </Styled.Container>
  );
};

export default Dashboard;
