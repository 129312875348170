import Company from './Modules/company';
import Password from './Modules/password';
import Personal from './Modules/personal';
import Tab1 from './Modules/tab1';
import Tab2 from './Modules/tab2';
import Tab3 from './Modules/tab3';
import Tab4 from './Modules/tab4';

const Modules = {
  Company,
  Password,
  Personal,
  Tab1,
  Tab2,
  Tab3,
  Tab4
};

export default Modules;
