import styled, { css } from 'styled-components';
import MediaQuery from 'utils/MediaQuery';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
  margin-bottom: 55px;

  ${MediaQuery.styling(
    'md',
    css`
      gap: 20px;
      margin-bottom: 45px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      gap: 15px;
      flex-direction: column;
      margin-bottom: 35px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      gap: 10px;
    `
  )};
`;

const Item = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #1d4371;
  background: #e5e5f5;
  border: ${({ isBorder }) => (isBorder ? '1px solid #1d4371' : 'none')};
  border-radius: 5px;
  cursor: pointer;
  padding: 0 20px;
  flex-basis: ${({ count }) => (count ? `${count}%` : 'auto')};
  transition: all ease 0.3s;

  ${MediaQuery.styling(
    'sm',
    css`
      width: 100%;
      flex-basis: auto;
    `
  )};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
    `};
  ${({ isBig }) =>
    isBig
      ? css`
          height: 68px;
          font-size: 18px;
          font-weight: 700;

          ${MediaQuery.styling(
            'md',
            css`
              height: 60px;
              font-size: 16px;
              font-weight: 500;
            `
          )};

          ${MediaQuery.styling(
            'sm',
            css`
              height: 48px;
            `
          )};

          ${MediaQuery.styling(
            'xs',
            css`
              font-size: 14px;
            `
          )};
        `
      : css`
          height: 62px;
          font-size: 15px;
          font-weight: 400;

          ${MediaQuery.styling(
            'md',
            css`
              height: 56px;
              font-size: 14px;
            `
          )};

          ${MediaQuery.styling(
            'sm',
            css`
              height: 42px;
            `
          )};
        `};

  ${({ isActive }) =>
    isActive &&
    css`
      color: #ffffff;
      background: #1d4371;
      cursor: default;
      border: ${({ isBorder }) => (isBorder ? '1px solid #1d4371' : 'none')};
    `};
`;

const Styled = {
  Container,
  Item
};

export default Styled;
