import Styled from 'components/Stock/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StepsModule = () => {
  const { t } = useTranslation('stock');

  return (
    <Styled.Steps>
      <Styled.Step>
        <div className={'icon'}>
          <svg viewBox="0 0 77 65">
            <rect
              x="10.4453"
              width="55.1395"
              height="55.1395"
              rx="5"
              transform="rotate(10.9199 10.4453 0)"
              fill="#D8D8E7"
            />
            <rect
              x="14.459"
              y="9.00586"
              width="55.1395"
              height="55.1395"
              rx="5"
              transform="rotate(-8.32388 14.459 9.00586)"
              fill="#E5E5F5"
            />
            <circle
              cx="37.0363"
              cy="23.672"
              r="6.13294"
              stroke="#7754FF"
              strokeWidth="5"
            />
            <path
              d="M67.7422 34.1823C68.0855 33.7499 68.0134 33.1211 67.5812 32.7777L66.0644 31.5725C65.6318 31.2288 65.0025 31.301 64.659 31.7338L61.0073 36.3344C60.6638 36.7672 60.0344 36.8394 59.6019 36.4957L55.0037 32.8421C54.5712 32.4984 53.9418 32.5706 53.5983 33.0034L52.3938 34.521C52.0505 34.9534 52.1226 35.5822 52.5548 35.9256L57.1547 39.5806C57.5869 39.924 57.659 40.5528 57.3157 40.9852L53.6627 45.5875C53.3194 46.0199 53.3915 46.6487 53.8238 46.9921L55.3406 48.1973C55.7731 48.541 56.4024 48.4688 56.7459 48.036L60.3976 43.4354C60.7411 43.0026 61.3705 42.9304 61.803 43.2741L66.4012 46.9277C66.8338 47.2714 67.4631 47.1992 67.8066 46.7664L69.0112 45.2488C69.3544 44.8164 69.2823 44.1876 68.8501 43.8442L64.2503 40.1892C63.818 39.8458 63.7459 39.217 64.0892 38.7846L67.7422 34.1823Z"
              fill="#FC62BC"
            />
          </svg>
        </div>
        <div className={'title'}>{t('title.step1')}</div>
        <div className={'subtitle'}>{t('subtitle.step1')}</div>
      </Styled.Step>
      <Styled.Step>
        <div className={'icon'}>
          <svg viewBox="0 0 56 64">
            <rect x="46.5947" width="5" height="64" rx="2.5" fill="#E5E5F5" />
            <rect x="24.8076" width="5" height="64" rx="2.5" fill="#E5E5F5" />
            <rect x="3.02051" width="5" height="64" rx="2.5" fill="#E5E5F5" />
            <ellipse
              cx="49.3653"
              cy="16.6818"
              rx="5.78424"
              ry="5.78723"
              fill="#F461B7"
            />
            <ellipse
              cx="27.5889"
              cy="49.3615"
              rx="5.78424"
              ry="5.78723"
              fill="#008BFF"
            />
            <ellipse
              cx="5.81354"
              cy="26.213"
              rx="5.78424"
              ry="5.78723"
              fill="#7754FF"
            />
          </svg>
        </div>
        <div className={'title'}>{t('title.step2')}</div>
        <div className={'subtitle'}>{t('subtitle.step2')}</div>
      </Styled.Step>
      <Styled.Step>
        <div className={'icon'}>
          <svg viewBox="0 0 77 77">
            <g clipPath="url(#clip0)">
              <path
                d="M65.8942 6.66406H11.1057C4.98199 6.66406 0 11.6461 0 17.7698V59.2313C0 65.355 4.98199 70.337 11.1057 70.337H65.8942C72.018 70.337 77 65.355 77 59.2313V17.7698C77 11.6461 72.018 6.66406 65.8942 6.66406Z"
                fill="#E5E5F5"
              />
              <path
                d="M45 38C45 31.9249 49.9249 27 56 27H77V49H56C49.9249 49 45 44.0751 45 38Z"
                fill="#D0C9DF"
              />
              <path
                d="M59.2305 36.2793H56.269C55.0422 36.2793 54.0479 37.2738 54.0479 38.5004C54.0479 39.7271 55.0424 40.7215 56.269 40.7215H59.2305C60.4572 40.7215 61.4516 39.727 61.4516 38.5004C61.4516 37.2737 60.4572 36.2793 59.2305 36.2793Z"
                fill="#7754FF"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="77" height="77" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className={'title'}>{t('title.step3')}</div>
        <div className={'subtitle'}>{t('subtitle.step3')}</div>
      </Styled.Step>
      <Styled.Step>
        <div className={'icon'}>
          <svg viewBox="0 0 64 64">
            <rect width="64" height="64" rx="8" fill="#E5E5F5" />
            <path
              d="M27.8181 45.2494C28.2921 45.7124 28.2921 46.463 27.8181 46.9258L19.6768 54.8779C19.2028 55.3406 18.4346 55.3406 17.9606 54.8779L14.085 51.0922C13.611 50.6295 13.611 49.8789 14.085 49.4161C14.5588 48.9531 15.3273 48.9531 15.8011 49.4161L18.8186 52.3634L26.1018 45.2494C26.5758 44.7866 27.3442 44.7866 27.8181 45.2494Z"
              fill="#F461B7"
            />
            <path
              d="M8.93227 49.1303L6.49937 46.2919C6.32741 46.0913 6.34123 45.7915 6.53092 45.6076L22.8811 29.7529C23.0736 29.5662 23.3791 29.5646 23.5735 29.7493L33.2057 38.8999C33.3979 39.0825 33.6992 39.0833 33.8925 38.9018L49.6603 24.0897C49.9904 23.7795 49.7709 23.2252 49.3179 23.2252H43.3385C43.0624 23.2252 42.8385 23.0014 42.8385 22.7252V19.0801C42.8385 18.8039 43.0624 18.5801 43.3385 18.5801H57.3063C57.5824 18.5801 57.8063 18.8039 57.8063 19.0801V33.564C57.8063 33.8401 57.5824 34.064 57.3063 34.064H54.1772C53.9011 34.064 53.6772 33.8401 53.6772 33.564V27.464C53.6772 27.0281 53.1584 26.801 52.8381 27.0966L33.8923 44.5851C33.6987 44.7637 33.3997 44.7616 33.2087 44.5802L23.5854 35.438C23.3863 35.2488 23.0718 35.2557 22.8811 35.4535L9.67183 49.152C9.46715 49.3643 9.12417 49.3542 8.93227 49.1303Z"
              fill="#7754FF"
            />
          </svg>
        </div>
        <div className={'title'}>{t('title.step4')}</div>
        <div className={'subtitle'}>{t('subtitle.step4')}</div>
      </Styled.Step>
    </Styled.Steps>
  );
};

export default StepsModule;
