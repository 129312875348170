import React, { useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import Styled from './styles';

const Captcha = ({ isActive, isDisabled, onCaptcha, captcha }) => {
  const { t } = useTranslation('uikits');
  const captchaRef = useRef(undefined);

  useEffect(() => {
    if (captcha === '') {
      onResetCaptcha();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captcha]);

  const onHandleCaptcha = () => {
    if (!isDisabled) {
      !isActive ? onExecuteCaptcha() : onResetCaptcha();
    }
  };

  const onExecuteCaptcha = async () => {
    const _captcha = await captchaRef.current.executeAsync();
    if (!!onCaptcha) onCaptcha(_captcha);
  };

  const onResetCaptcha = () => {
    captchaRef.current.reset();
    if (!!onCaptcha) onCaptcha('');
  };

  return (
    <Styled.Container isDisabled={isDisabled}>
      <Styled.Radio
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={onHandleCaptcha}
      >
        <span className={'box'} />
        <span className={'text'}>{t('captcha.not_robot')}</span>
      </Styled.Radio>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={'6LcEunkaAAAAAGC1rE_dq81qDfJkPaAvBswkX06P'}
        size={'invisible'}
        badge={'bottomright'}
      />
    </Styled.Container>
  );
};

export default Captcha;
