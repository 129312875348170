import ApiHelper from 'services/config/http';
import { Stocks } from 'services/config/url';

class StocksAPI extends ApiHelper {
  fetchUser = async () => {
    let result = {};
    await this.get(Stocks.fetchUser)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchUserType = async () => {
    let result = {};
    await this.get(Stocks.fetchUserType)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchTotal = async () => {
    let result = {};
    await this.get(Stocks.fetchTotal)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  buyStock = async body => {
    let result = {};
    await this.post(Stocks.buyStock, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
  submitReceipt = async body => {
    let result = {};
    await this.post(Stocks.submitReceipt, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
  cancelReceipt = async orderId => {
    let result = {};
    await this.post(Stocks.cancelBankReceipt + '/' + orderId)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
  fetchOfferings = async ({ page, size, sort, asc }) => {
    let result = [];
    await this.get(Stocks.fetchOfferings, {
      page,
      size,
      sort,
      asc
    })
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
  receiveWaitingReceipt = async ({ page, size, sort, asc }) => {
    let result = [];
    await this.get(Stocks.receiveWaitingReceipt, {
      page,
      size,
      sort,
      asc
    })
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchStats = async () => {
    let result = {};
    await this.get(Stocks.fetchStats)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchPendingStocks = async ({ page, size, sort, asc }) => {
    let result = {};
    await this.get(Stocks.fetchPendingStocks, { page, size, sort, asc })
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchHistory = async body => {
    let result = [];
    await this.get(Stocks.fetchHistory, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  fetchPapersList = async body => {
    let result = [];
    await this.get(Stocks.fetchPapersList, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  paperRequest = async body => {
    let result = {};
    await this.post(Stocks.paperRequest, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
  sellStock = async body => {
    let result = {};
    await this.post(Stocks.sellStock, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
}

export default StocksAPI;
