import React, { useEffect, useState } from 'react';
import Styled from './styles';

const Tab = ({ init, onSelect, border, big, allowed, children }) => {
  const [isActive, setIsActive] = useState('');

  const onHandleTabSelect = _tab => {
    if ((allowed && allowed.includes(_tab)) || !allowed) {
      setIsActive(_tab);
      if (!!onSelect) onSelect(_tab);
    }
  };

  useEffect(() => {
    onHandleTabSelect(init);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  return (
    <Styled.Container>
      {React.Children.toArray(children).map((_tab, _index) => {
        const { label } = _tab.props;
        return (
          <Styled.Item
            key={`tab_item_${_index}`}
            isActive={isActive === label}
            isDisabled={allowed && !allowed.includes(label)}
            isBorder={!!border}
            isBig={!!big}
            count={(1 / React.Children.toArray(children).length) * 100}
            onClick={() => onHandleTabSelect(label)}
          >
            {label}
          </Styled.Item>
        );
      })}
    </Styled.Container>
  );
};

export default Tab;
