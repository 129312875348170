export const DEFAULTS = {
  person: {
    birthDate: null,
    gender: '',
    ostan: 0,
    shahrestan: 0,
    bakhsh: 0,
    dehestan: 0,
    maritalStatus: '',
    educationLevel: '',
    sacrificeStatus: '',
    dutyStatus: '',
    phone: '',
    postalCode: '',
    detail: '',
    idCardNumber: '',
    ssnAddress: ''
  },
  company: {
    companyName: '',
    companyRegisterNumber: '',
    companySSNumber: '',
    companyEcoNumber: '',
    companyOstan: 0,
    companyShahr: 0,
    companyAddressDetail: '',
    companyPostalCode: '',
    companyType: ''
  },
  bank: {
    bankId: 0,
    sheba: '',
    cardId: ''
  }
};

export const STEPS = [
  { id: 1, name: 'step.title.1', step: ['PERSONAL_INFO', 'COMPANY_INFO'] },
  { id: 2, name: 'step.title.2', step: ['BANK_INFO'] },
  {
    id: 3,
    name: 'step.title.3',
    step: [
      'UPLOAD_DOC_PERSONAL_PHOTO',
      'UPLOAD_DOC_NATIONAL_CARD',
      'UPLOAD_DOC_ID_CARD',
      'UPLOAD_DOC_COMPANY',
      'UPLOAD_DOC_BANK'
    ]
  },
  { id: 4, name: 'step.title.4', step: ['PRE_BUY_STOCK'] }
];

export const UPLOAD = [
  {
    id: 1,
    isPerson: true,
    isLegal: true,
    name: 'UPLOAD_DOC_PERSONAL_PHOTO',
    type: ['PERSONAL_PHOTO'],
    files: ['form.label.personal_photo']
  },
  {
    id: 2,
    isPerson: true,
    isLegal: true,
    name: 'UPLOAD_DOC_NATIONAL_CARD',
    type: ['NATIONAL_CARD_P_1', 'NATIONAL_CARD_P_2'],
    files: ['form.label.national_card_front', 'form.label.national_card_back'],
    maleType: true
  },
  {
    id: 3,
    isPerson: true,
    isLegal: true,
    name: 'UPLOAD_DOC_ID_CARD',
    type: ['ID_CARD_P_1_2', 'ID_CARD_P_3_4', 'ID_CARD_P_5_6'],
    files: [
      'form.label.id_card_1_2',
      'form.label.id_card_3_4',
      'form.label.id_card_5_6'
    ]
  },
  {
    id: 4,
    isPerson: false,
    isLegal: true,
    name: 'UPLOAD_DOC_COMPANY',
    type: ['FOUNDED_AD', 'RECENT_GENERAL_ORDINARY_CHANGE', 'RECENT_MD_CHANGE'],
    files: [
      'form.label.company_ads_1',
      'form.label.company_ads_2',
      'form.label.company_ads_3'
    ]
  },
  {
    id: 5,
    isPerson: true,
    isLegal: true,
    name: 'UPLOAD_DOC_BANK',
    type: ['MAIN_BANK_CART'],
    files: ['form.label.bank_card'],
    educationType: true
  }
];
