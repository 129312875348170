import styled from 'styled-components';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
`;

const Styled = {
  Container
};

export default Styled;
