import Styled from 'components/Authentication/styles';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationAPI from 'services/apis/LocationsAPI';
import Formatter from 'utils/Formatter';
import Loading from '../../../layouts/Loading';

const CompanyModule = ({ user, data, onData }) => {
  const { t } = useTranslation('authentication');
  const [provinces, setProvinces] = useState([]);
  const [shahrestans, setShahrestans] = useState([]);
  const [shahrestansLoading, setShahrestansLoading] = useState(false);
  const [provincesLoading, setProvincesLoading] = useState(true);
  const localLocationAPI = new LocationAPI();
  const toast = useToast();

  const embeddedLoadingStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  };
  useEffect(() => {
    onLoadData();
    let _mounted = true;
    if (_mounted) {
      onGetProvinces();
    }

    return () => {
      _mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.companyOstan !== 0) {
      if (!user?.companyAddress?.shahrestan?.id) {
        onData({ ...data, companyShahr: 0 }, 'debug1');
      }
      getShahrestans(data.companyOstan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.companyOstan]);

  const onLoadData = () => {
    let _data = { ...data };
    if (user?.companyName) _data['companyName'] = user.companyName;
    if (user?.companySSNumber) _data['companySSNumber'] = user.companySSNumber;
    if (user?.companyRegisterNumber)
      _data['companyRegisterNumber'] = user.companyRegisterNumber;
    if (user?.companyType) _data['companyType'] = user.companyType;
    if (!!user?.stateId && user?.stateId !== 0)
      _data['companyOstan'] = user.stateId;
    onData(_data, 'debug0');
  };

  const onHandleChange = event => {
    if (!event.target.disabled) {
      const _mode = event.target.inputMode;
      const _name = event.target.name;
      const _value =
        _mode === 'numeric'
          ? Formatter.changeNumberToEn(event.target.value).replace(/\D/, '')
          : event.target.value;

      onData({ ...data, [_name]: _value }, 'debug2');
    }
  };

  const onGetProvinces = () => {
    setProvincesLoading(true);
    localLocationAPI
      .fetchProvinces()
      .then(response => {
        if (response && Array.isArray(response)) {
          setProvinces(response);
        } else toast(t('notification.province.error'), 'error');
        setProvincesLoading(false);
      })
      .catch(() => {
        setProvincesLoading(false);
      });
  };

  const getShahrestans = _province => {
    setShahrestansLoading(true);
    localLocationAPI
      .fetchShahrestans(_province)
      .then(response => {
        if (response && Array.isArray(response)) setShahrestans(response);
        else toast(t('notification.shahrestan.error'), 'error');
        setShahrestansLoading(false);
      })
      .catch(() => {
        setProvincesLoading(false);
      });
  };

  return (
    <Styled.Content>
      <Styled.Title>{t('form.company.title')}</Styled.Title>
      <Styled.Fields>
        <Styled.Field>
          <label htmlFor={'companyName'} className={'label'}>
            {t('form.label.companyName')}
          </label>
          <input
            id={'companyName'}
            name={'companyName'}
            className={'input'}
            value={user?.companyName ? user.companyName : data.companyName}
            disabled={!!user?.companyName}
            onChange={onHandleChange}
          />
        </Styled.Field>
        <Styled.Field>
          <label htmlFor={'companySSNumber'} className={'label'}>
            {t('form.label.companySSNumber')}
          </label>
          <input
            id={'companySSNumber'}
            name={'companySSNumber'}
            className={'input wide'}
            value={
              user?.companySSNumber
                ? user.companySSNumber
                : data.companySSNumber
            }
            disabled={!!user?.companySSNumber}
            inputMode={'numeric'}
            onChange={onHandleChange}
          />
        </Styled.Field>
        <Styled.Field>
          <label htmlFor={'companyRegisterNumber'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.companyRegisterNumber')}
          </label>
          <input
            id={'companyRegisterNumber'}
            name={'companyRegisterNumber'}
            className={'input wide'}
            value={
              user?.companyRegisterNumber
                ? user.companyRegisterNumber
                : data.companyRegisterNumber
            }
            disabled={!!user?.companyRegisterNumber}
            inputMode={'numeric'}
            onChange={onHandleChange}
          />
        </Styled.Field>
        <Styled.Field field={'select'}>
          <label htmlFor={'companyType'} className={'label'}>
            {t('form.label.companyType')}
          </label>
          <select
            id={'companyType'}
            name={'companyType'}
            className={'input'}
            value={user?.companyType ? user.companyType : data.companyType}
            disabled={!!user?.companyType}
            onChange={onHandleChange}
          >
            <option value={''} disabled={true} hidden={true} />
            <option value={'PrivateHeld'}>
              {t('form.companyType.private')}
            </option>
            <option value={'Cooperative'}>
              {t('form.companyType.cooperative')}
            </option>
          </select>
        </Styled.Field>

        <Styled.Field field={'select'}>
          <label htmlFor={'companyOstan'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.companyOstan')}
          </label>
          {provincesLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'companyOstan'}
              name={'companyOstan'}
              className={'input'}
              value={
                !!user?.stateId && user?.stateId !== 0
                  ? user.stateId
                  : data.companyOstan
              }
              disabled={!!user?.stateId && user?.stateId !== 0}
              onChange={onHandleChange}
            >
              <option value={0} disabled={true} hidden={true} />
              {provinces.map((_province, _index) => (
                <option
                  key={`companyState_item_${_index}`}
                  value={_province.id}
                >
                  {_province.name}
                </option>
              ))}
            </select>
          )}
        </Styled.Field>

        <Styled.Field field={'select'}>
          <label htmlFor={'companyShahr'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.companyShahr')}
          </label>
          {shahrestansLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'companyShahr'}
              name={'companyShahr'}
              className={'input'}
              value={data.companyShahr}
              disabled={data.companyOstan === 0}
              onChange={onHandleChange}
            >
              <option value={0} disabled={true} hidden={true} />
              {shahrestans.map((shahrestan, _index) => (
                <option key={`shahrestan_item_${_index}`} value={shahrestan.id}>
                  {shahrestan.name}
                </option>
              ))}
            </select>
          )}
        </Styled.Field>

        <Styled.Field>
          <label htmlFor={'companyPostalCode'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.companyPostalCode')}
          </label>
          <input
            id={'companyPostalCode'}
            name={'companyPostalCode'}
            className={'input wide'}
            value={data.companyPostalCode}
            disabled={false}
            maxLength={10}
            inputMode={'numeric'}
            onChange={onHandleChange}
          />
        </Styled.Field>
        <Styled.Field size={'threeQuarter2'}>
          <label htmlFor={'companyAddressDetail'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.companyAddressDetail')}
          </label>
          <input
            id={'companyAddressDetail'}
            name={'companyAddressDetail'}
            className={'input'}
            value={data.companyAddressDetail}
            disabled={false}
            onChange={onHandleChange}
          />
        </Styled.Field>
      </Styled.Fields>
    </Styled.Content>
  );
};

export default CompanyModule;
