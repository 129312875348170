import { ToastProvider } from 'hooks/useToast';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './assets/styles/global.css';
import './i18n';
import Routes from './routes';
import { unregister } from './serviceWorker';
import Persistor from './store/config/persistor';
import { ConfigProvider } from 'antd';
import faIR from 'antd/lib/locale/fa_IR';

const { store, persistor } = Persistor();

const Root = () => (
  <ConfigProvider locale={faIR}>
    <Provider store={store}>
      <PersistGate loading={undefined} persistor={persistor}>
        <ToastProvider />
        <Routes />
      </PersistGate>
    </Provider>
  </ConfigProvider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

unregister();
