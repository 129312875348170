class Formatter {
  static changeNumberToEn = input => {
    const map = {
      // arabic
      '٠': '0',
      '١': '1',
      '٢': '2',
      '٣': '3',
      '٤': '4',
      '٥': '5',
      '٦': '6',
      '٧': '7',
      '٨': '8',
      '٩': '9',
      // persian
      '۰': '0',
      '۱': '1',
      '۲': '2',
      '۳': '3',
      '۴': '4',
      '۵': '5',
      '۶': '6',
      '۷': '7',
      '۸': '8',
      '۹': '9'
    };
    const numbers = input.split('');
    for (let i = 0; i < numbers.length; i++) {
      if (map.hasOwnProperty(numbers[i])) {
        numbers[i] = map[numbers[i]];
      }
    }
    return numbers.join('');
  };

  static isNationalCode = value => {
    let sum = 0;
    let r = 0;
    let checkDigit = 0;
    let nationalCodeStr = value.toString();
    if (
      (nationalCodeStr &&
        nationalCodeStr.length === 10 &&
        nationalCodeStr.split('').every(char => char === nationalCodeStr[0])) ||
      nationalCodeStr.length > 10
    ) {
      return ['کد ملی نامعتبر است', true];
    }
    if (nationalCodeStr.length <= 8 || nationalCodeStr * 1 <= 0x98968a) {
      return ['کد ملی نامعتبر است', true];
    }
    nationalCodeStr = ['00', nationalCodeStr.trim()].join('');
    nationalCodeStr = nationalCodeStr.substring(
      nationalCodeStr.length - 10,
      nationalCodeStr.length
    );
    checkDigit = nationalCodeStr.substring(9, 10) * 1;
    nationalCodeStr = nationalCodeStr.split('');
    for (let i = 0; i < 9; i += 1) {
      sum += nationalCodeStr[i] * (10 - i);
    }
    r = sum % 11;
    if ((r > 1 ? 11 - r : r) === checkDigit) {
      return ['', false];
    } else {
      return ['کد ملی نامعتبر است', true];
    }
  };

  static isMobileNumber = value => {
    if (value && value.length > 0 && value.match(/((9|9|9)[0-9]{9})/gi)) {
      const v = value.match(/((9|9|9)[0-9]{9})/gi);

      return [
        '',
        !(
          !!v &&
          v !== undefined &&
          v.length > 0 &&
          v[0].length === 10 &&
          (v[0] === value || ['0', v[0]].join('') === value)
        )
      ];
    }
    return ['لطفا شماره تلفن همراه را به صورت صحیح وارد کنید', true];
  };

  static formatPhoneNumber = (
    phoneNumber,
    removeZero = false,
    countryCode = undefined
  ) => {
    let formatedPhoneNumber = '';
    for (let i = phoneNumber.length - 1; i >= 0; i--) {
      if (
        (removeZero &&
          ((i === 0 && phoneNumber.charAt(i) !== '0') || i !== 0)) ||
        !removeZero
      ) {
        if (
          i === phoneNumber.length - 4 ||
          i === phoneNumber.length - 7 ||
          (countryCode !== undefined && i === phoneNumber.length - 10)
        ) {
          formatedPhoneNumber = ` ${phoneNumber.charAt(
            i
          )}${formatedPhoneNumber}`;
          continue;
        }
        formatedPhoneNumber = phoneNumber.charAt(i) + formatedPhoneNumber;
      }
    }
    if (countryCode !== undefined) {
      formatedPhoneNumber = countryCode + formatedPhoneNumber;
    }

    return formatedPhoneNumber;
  };

  static thousandSeparator = num => {
    if (num || num === 0)
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };
}

export default Formatter;
