import Company from './Modules/company';
import Personal from './Modules/personal';
import Step1 from './Modules/step1';
import Step2 from './Modules/step2';
import Step3 from './Modules/step3';

const Modules = {
  Company,
  Personal,
  Step1,
  Step2,
  Step3
};

export default Modules;
