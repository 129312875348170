import CalendarIcon from 'assets/images/calendar.svg';
import Styled from 'components/Authentication/styles';
import useClick from 'hooks/useClick';
import useToast from 'hooks/useToast';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LocationAPI from 'services/apis/LocationsAPI';
import UserAPI from 'services/apis/UserAPI';
import DatePicker from 'uikit/DatePicker';
import Formatter from 'utils/Formatter';
import Loading from '../../../layouts/Loading';

const PersonalModule = ({ user, isPerson, data, validations, onData }) => {
  const { t } = useTranslation('authentication');
  const calendarRef = useRef('');
  const [isCalendar, setIsCalendar] = useClick(calendarRef);
  const [provinces, setProvinces] = useState([]);
  const [sacrificeStatuses, setSacrificeStatuses] = useState({});
  const [maritalStatuses, setMaritalStatuses] = useState({});
  const [educationLevels, setEducationLevels] = useState({});
  const [shahrestans, setShahrestans] = useState([]);
  const [bakhshs, setBakhshs] = useState([]);
  const [dehestans, setDehestans] = useState([]);
  const [ssnSharestans, setSsnShahrestans] = useState([]);
  const [provincesLoading, setProvincesLoading] = useState(false);
  const [educationLevelsLoading, setEducationLevelsLoading] = useState(false);
  const [maritalStatusesLoading, setMaritalStatusesLoading] = useState(false);
  const [sacrificeStatusesLoading, setSacrificeStatusesLoading] =
    useState(false);
  const [bakhshsLoading, setBakhshsLoading] = useState(false);
  const [dehestansLoading, setDehestansLoading] = useState(false);
  const [ssnSharestansLoading, setSsnSharestansLoading] = useState(false);
  const [shahrestansLoading, setShahrestansLoading] = useState(false);

  const localLocationAPI = new LocationAPI();
  const localUserAPI = new UserAPI();
  const toast = useToast();

  const embeddedLoadingStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  };
  useEffect(() => {
    let _mounted = true;
    if (_mounted) {
      getProvinces();
      getSacrificeStatuses();
      getEducationLevels();
      getMaritalStatuses();
    }

    return () => {
      _mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.ostan !== 0) {
      if (!user?.address?.shahrestan?.id)
        onData({ ...data, shahrestan: 0, bakhsh: 0, dehestan: 0 });
      getShahrestans(data.ostan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.ostan]);
  useEffect(() => {
    if (data.ssnOstan !== 0) {
      if (!user?.ssnAddress?.shahrestan?.id)
        onData({ ...data, ssnShahrestan: 0 });
      getSsnShahrestans(data.ssnOstan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.ssnOstan]);

  useEffect(() => {
    if (data.shahrestan !== 0) {
      if (!user?.address?.bakhsh?.id)
        onData({ ...data, bakhsh: 0, dehestan: 0 });
      getBakhshs(data.shahrestan);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.shahrestan]);

  useEffect(() => {
    if (data.bakhsh !== 0) {
      if (!user?.address?.dehestan?.id) onData({ ...data, dehestan: 0 });
      getDehestans(data.bakhsh);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.bakhsh]);
  // useEffect(() => {
  //   alert(JSON.stringify(data));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  const onHandleChange = event => {
    if (!event.target.disabled) {
      const _mode = event.target.inputMode;
      const _name = event.target.name;
      const _value =
        _mode === 'numeric'
          ? Formatter.changeNumberToEn(event.target.value).replace(/\D/, '')
          : event.target.value;
      onData({ ...data, [_name]: _value });
    }
  };

  const onHandleDate = _date => {
    onData({ ...data, birthDate: _date });
    setIsCalendar(false);
  };

  const getSacrificeStatuses = () => {
    setSacrificeStatusesLoading(true);
    localUserAPI.fetchSacrificeStatuses().then(response => {
      if (response && typeof response == 'object') {
        setSacrificeStatuses(response);
      } else {
        toast(t('notification.sacrifice.error'), 'error');
      }
      setSacrificeStatusesLoading(false);
    });
  };

  const getEducationLevels = () => {
    setEducationLevelsLoading(true);
    localUserAPI.fetchEducationLevels().then(response => {
      if (response && typeof response == 'object') {
        setEducationLevels(response);
      } else {
        toast(t('notification.educationLevels.error'), 'error');
      }
      setEducationLevelsLoading(false);
    });
  };

  const getMaritalStatuses = () => {
    setMaritalStatusesLoading(true);
    localUserAPI.fetchMaritalStatus().then(response => {
      if (response && typeof response == 'object') {
        setMaritalStatuses(response);
      } else {
        toast(t('notification.maritalStatus.error'), 'error');
      }
      setMaritalStatusesLoading(false);
    });
  };

  const getProvinces = () => {
    setProvincesLoading(true);
    localLocationAPI.fetchProvinces().then(response => {
      if (response && Array.isArray(response)) {
        setProvinces(response);
      } else {
        toast(t('notification.province.error'), 'error');
      }
      setProvincesLoading(false);
    });
  };

  const getSsnShahrestans = ostan => {
    setSsnSharestansLoading(true);
    localLocationAPI.fetchShahrestans(ostan).then(response => {
      if (response && Array.isArray(response)) {
        setSsnShahrestans(response);
      } else {
        toast(t('notification.shahrestan.error'), 'error');
      }
      setSsnSharestansLoading(false);
    });
  };

  const getShahrestans = ostan => {
    setShahrestansLoading(true);
    localLocationAPI.fetchShahrestans(ostan).then(response => {
      if (response && Array.isArray(response)) {
        setShahrestans(response);
      } else {
        toast(t('notification.shahrestan.error'), 'error');
      }
      setShahrestansLoading(false);
    });
  };

  const getBakhshs = shahrestan => {
    setBakhshsLoading(true);
    localLocationAPI.fetchBakhshs(shahrestan).then(response => {
      if (response && Array.isArray(response)) {
        setBakhshs(response);
      } else {
        toast(t('notification.bakhsh.error'), 'error');
      }
      setBakhshsLoading(false);
    });
  };

  const getDehestans = basksh => {
    setDehestansLoading(true);
    localLocationAPI.fetchDehestans(basksh).then(response => {
      if (response && Array.isArray(response)) {
        setDehestans(response);
      } else {
        toast(t('notification.dehestan.error'), 'error');
      }
      setDehestansLoading(false);
    });
  };
  return (
    <Styled.Content>
      <Styled.Title>{t('form.personal.title')}</Styled.Title>
      <Styled.Description>
        <p>{t('form.personal.description')}</p>
        {/*<Link to={'/'}>*/}
        {/*  {t('form.personal.help')}*/}
        {/*  <span className={'icon'} />*/}
        {/*</Link>*/}
      </Styled.Description>
      <Styled.Spacer size={20} />
      <Styled.Description>
        <p style={{ color: 'red' }}>{t('form.personal.description2')}</p>
      </Styled.Description>
      <Styled.Spacer size={45} />
      <Styled.Fields>
        <Styled.Field>
          <label htmlFor={'firstName'} className={'label'}>
            {t(
              isPerson ? 'form.label.firstName' : 'form.label.firstName.legal'
            )}
          </label>
          <input
            id={'firstName'}
            name={'firstName'}
            className={'input'}
            value={user?.firstName}
            disabled={true}
          />
        </Styled.Field>
        <Styled.Field>
          <label htmlFor={'lastName'} className={'label'}>
            {t(isPerson ? 'form.label.lastName' : 'form.label.lastName.legal')}
          </label>
          <input
            id={'lastName'}
            name={'lastName'}
            className={'input'}
            value={user?.lastName}
            disabled={true}
          />
        </Styled.Field>
        <Styled.Field>
          <label htmlFor={'fatherName'} className={'label'}>
            {t('form.label.fatherName')}
          </label>
          <input
            id={'fatherName'}
            name={'fatherName'}
            className={'input'}
            value={user?.fatherName}
            disabled={true}
          />
        </Styled.Field>
        <Styled.Field>
          <label htmlFor={'nationalCode'} className={'label'}>
            {t('form.label.nationalCode')}
          </label>
          <input
            id={'nationalCode'}
            name={'nationalCode'}
            className={'input wide'}
            value={user?.nationalCode}
            disabled={true}
          />
        </Styled.Field>
        <Styled.Field isWrong={!validations?.person.idCardNumber}>
          <label htmlFor={'idCardNumber'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.idCardNumber')}
          </label>
          <input
            id={'idCardNumber'}
            name={'idCardNumber'}
            className={'input wide'}
            value={user?.idCardNumber}
            disabled={false}
            maxLength={10}
            inputMode={'numeric'}
            onChange={onHandleChange}
          />
        </Styled.Field>
        <Styled.Field field={'select'} isWrong={!validations?.person.gender}>
          <label htmlFor={'gender'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.gender')}
          </label>
          <select
            id={'gender'}
            name={'gender'}
            className={'input'}
            value={data.gender}
            disabled={false}
            onChange={onHandleChange}
          >
            <option value={''} disabled={true} hidden={true} />
            <option value={'male'}>{t('form.gender.male')}</option>
            <option value={'female'}>{t('form.gender.female')}</option>
          </select>
        </Styled.Field>
        <Styled.Field
          field={'calendar'}
          icon={CalendarIcon}
          ref={calendarRef}
          isWrong={!validations?.person.birthDate}
        >
          <label className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.birthDate')}
          </label>

          <div className={'input'} onClick={() => setIsCalendar(!isCalendar)}>
            {data.birthDate ? t('form.birthDate', data.birthDate) : ''}
          </div>

          <div className={`calendar ${isCalendar ? 'active' : ''}`}>
            <DatePicker date={data.birthDate} onSelect={onHandleDate} />
          </div>
        </Styled.Field>
        <Styled.Field field={'select'} isWrong={!validations?.person.ssnOstan}>
          <label htmlFor={'ssnOstan'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.ssnOstan')}
          </label>
          {provincesLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'ssnOstan'}
              name={'ssnOstan'}
              className={'input'}
              value={
                data.ssnOstan !== undefined && data.ssnOstan !== null
                  ? data.ssnOstan
                  : 0
              }
              disabled={false}
              onChange={onHandleChange}
            >
              <option value={0} disabled={true} hidden={true} />
              {provinces.map((_province, _index) => (
                <option key={`ssnOstan_item_${_index}`} value={_province.id}>
                  {_province.name}
                </option>
              ))}
            </select>
          )}
        </Styled.Field>
        <Styled.Field
          field={'select'}
          isWrong={!validations?.person.ssnShahrestan}
        >
          <label htmlFor={'ssnShahrestan'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.ssnShahrestan')}
          </label>
          {ssnSharestansLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'ssnShahrestan'}
              name={'ssnShahrestan'}
              className={'input'}
              value={data.ssnShahrestan}
              disabled={data.ssnOstan === 0}
              onChange={onHandleChange}
            >
              <option value={0} disabled={true} hidden={true} />
              {ssnSharestans.map((shahrestan, _index) => (
                <option key={`shahrestan_item_${_index}`} value={shahrestan.id}>
                  {shahrestan.name}
                </option>
              ))}
            </select>
          )}
        </Styled.Field>
        <Styled.Field
          field={'select'}
          isWrong={!validations?.person.maritalStatus}
        >
          <label htmlFor={'maritalStatus'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.maritalStatus')}
          </label>
          {maritalStatusesLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'maritalStatus'}
              name={'maritalStatus'}
              className={'input'}
              value={data.maritalStatus}
              disabled={false}
              onChange={onHandleChange}
            >
              <option value={''} disabled={true} hidden={true} />
              {Object.entries(maritalStatuses).map(item => (
                <option key={item[0]} value={item[0]}>
                  {item[1]}
                </option>
              ))}
            </select>
          )}
        </Styled.Field>
        <Styled.Field
          field={'select'}
          isWrong={!validations?.person.educationLevel}
        >
          <label htmlFor={'educationLevel'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.educationLevel')}
          </label>
          {educationLevelsLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'educationLevel'}
              name={'educationLevel'}
              className={'input'}
              value={data.educationLevel}
              disabled={false}
              onChange={onHandleChange}
            >
              <option value={''} disabled={true} hidden={true} />
              {Object.entries(educationLevels).map(item => (
                <option value={item[0]}>{item[1]}</option>
              ))}
            </select>
          )}
        </Styled.Field>
        <Styled.Field
          field={'select'}
          isWrong={!validations?.person.sacrificeStatus}
        >
          <label htmlFor={'sacrificeStatus'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.sacrificeStatus')}
          </label>
          {sacrificeStatusesLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'sacrificeStatus'}
              name={'sacrificeStatus'}
              className={'input'}
              value={data.sacrificeStatus}
              disabled={false}
              onChange={onHandleChange}
              placeholder={t('form.sacrifice.none')}
            >
              <option value={''} disabled={true} hidden={true} />
              {Object.entries(sacrificeStatuses).map(item => (
                <option value={item[0]}>{item[1]}</option>
              ))}
            </select>
          )}
        </Styled.Field>
        <Styled.Field field={'select'} isWrong={!validations?.person.ostan}>
          <label htmlFor={'ostan'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.ostan')}
          </label>
          {provincesLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'ostan'}
              name={'ostan'}
              className={'input'}
              value={data.ostan}
              disabled={false}
              onChange={onHandleChange}
            >
              <option value={0} disabled={true} hidden={true} />
              {provinces.map((_province, _index) => (
                <option key={`ostan_item_${_index}`} value={_province.id}>
                  {_province.name}
                </option>
              ))}
            </select>
          )}
        </Styled.Field>
        <Styled.Field
          field={'select'}
          isWrong={!validations?.person.shahrestan}
        >
          <label htmlFor={'shahrestan'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.shahrestan')}
          </label>
          {shahrestansLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'shahrestan'}
              name={'shahrestan'}
              className={'input'}
              value={data.shahrestan}
              disabled={data.ostan === 0}
              onChange={onHandleChange}
            >
              <option value={0} disabled={true} hidden={true} />
              {shahrestans.map((shahrestan, _index) => (
                <option key={`shahrestan_item_${_index}`} value={shahrestan.id}>
                  {shahrestan.name}
                </option>
              ))}
            </select>
          )}
        </Styled.Field>
        <Styled.Field field={'select'}>
          <label htmlFor={'bakhsh'} className={'label'}>
            {t('form.label.bakhsh')}
          </label>
          {bakhshsLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'bakhsh'}
              name={'bakhsh'}
              className={'input'}
              value={data.bakhsh}
              disabled={data.shahrestan === 0}
              onChange={onHandleChange}
            >
              <option value={0} disabled={true} hidden={true} />
              {bakhshs.map((bakhsh, _index) => (
                <option key={`bakhsh_item_${_index}`} value={bakhsh.id}>
                  {bakhsh.name}
                </option>
              ))}
            </select>
          )}
        </Styled.Field>
        <Styled.Field field={'select'}>
          <label htmlFor={'dehestan'} className={'label'}>
            {t('form.label.dehestan')}
          </label>
          {dehestansLoading ? (
            <div style={embeddedLoadingStyle}>
              <Loading />
            </div>
          ) : (
            <select
              id={'dehestan'}
              name={'dehestan'}
              className={'input'}
              value={data.dehestan}
              disabled={data.bakhsh === 0}
              onChange={onHandleChange}
            >
              <option value={0} disabled={true} hidden={true} />
              {dehestans.map((dehestan, _index) => (
                <option key={`dehestan_item_${_index}`} value={dehestan.id}>
                  {dehestan.name}
                </option>
              ))}
            </select>
          )}
        </Styled.Field>

        <Styled.Field
          field={'select'}
          isWrong={!validations?.person.dutyStatus}
        >
          <label htmlFor={'dutyStatus'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.duty_status')}
          </label>
          <select
            id={'dutyStatus'}
            name={'dutyStatus'}
            className={'input'}
            value={data.dutyStatus}
            disabled={false}
            onChange={onHandleChange}
          >
            <option value={''} disabled={true} hidden={true} />
            <option value={'AcademicExemption'}>
              {t('form.duty_status.academic_exemption')}
            </option>
            <option value={'EndOfDuty'}>
              {t('form.duty_status.end_of_duty')}
            </option>
            <option value={'None'}>{t('form.duty_status.no_thing')}</option>
          </select>
        </Styled.Field>

        <Styled.Field isWrong={!validations?.person.phone}>
          <label htmlFor={'phone'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.phone')}
          </label>
          <input
            id={'phone'}
            name={'phone'}
            className={'input wide'}
            value={data.phone}
            maxLength={11}
            inputMode={'numeric'}
            onChange={onHandleChange}
          />
        </Styled.Field>
        {/*<Styled.Spacer size={45} />*/}
        <Styled.Field isWrong={!validations?.person.postalCode}>
          <label htmlFor={'postalCode'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.postalCode')}
          </label>
          <input
            id={'postalCode'}
            name={'postalCode'}
            className={'input wide'}
            value={data.postalCode}
            disabled={false}
            maxLength={10}
            inputMode={'numeric'}
            onChange={onHandleChange}
          />
        </Styled.Field>
        <Styled.Field
          size={data?.gender !== 'male' ? 'quarter' : 'threeQuarter2'}
          isWrong={!validations?.person.detail}
        >
          <label htmlFor={'detail'} className={'label'}>
            <span style={{ color: 'red' }}> * </span>
            {t('form.label.detail')}
          </label>
          <input
            id={'detail'}
            name={'detail'}
            className={'input'}
            value={data.detail}
            disabled={false}
            onChange={onHandleChange}
          />
        </Styled.Field>
      </Styled.Fields>
    </Styled.Content>
  );
};

export default PersonalModule;
