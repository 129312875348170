import styled, { css } from 'styled-components';
import MediaQuery from 'utils/MediaQuery';

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid #d0c9df;
  border-radius: 5px;
  flex: 1;

  ${MediaQuery.styling(
    'sm',
    css`
      height: 48px;
      flex: auto;
    `
  )};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.75;
    `};
`;

const Radio = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  cursor: pointer;

  ${MediaQuery.styling(
    'md',
    css`
      gap: 12px;
    `
  )};

  ${MediaQuery.styling(
    'sm',
    css`
      gap: 8px;
    `
  )};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `};

  & > .text {
    font-size: 16px;
    font-weight: 400;
    color: #35a2c6;

    ${MediaQuery.styling(
      'md',
      css`
        font-size: 15px;
      `
    )};

    ${MediaQuery.styling(
      'sm',
      css`
        font-size: 14px;
      `
    )};
  }

  & > .box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #35a2c6;
    box-sizing: border-box;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    position: relative;

    ${MediaQuery.styling(
      'sm',
      css`
        width: 20px;
        height: 20px;
      `
    )};

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5323 0.470738C19.1559 1.0987 19.1559 2.11663 18.5323 2.74428L7.82324 13.5293C7.19967 14.1569 6.18919 14.1569 5.56562 13.5293L0.467673 8.39502C-0.155891 7.76737 -0.155891 6.74944 0.467673 6.12179C1.09093 5.49383 2.10172 5.49383 2.72498 6.12179L6.69427 10.1191L16.2747 0.470738C16.8983 -0.156913 17.9091 -0.156913 18.5323 0.470738Z' fill='%2364BC26'/%3E%3C/svg%3E%0A");
      background-attachment: scroll;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: transparent;
      background-size: 16px;
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.3s;

      ${MediaQuery.styling(
        'sm',
        css`
          background-size: 12px;
        `
      )};
    }

    ${({ isActive }) =>
      isActive &&
      css`
        &:after {
          opacity: 1;
          visibility: visible;
        }
      `};
  }
`;

const Styled = {
  Container,
  Radio
};

export default Styled;
