import Module from 'components/Dashboard/modules';
import Styled from 'components/Dashboard/styles';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import LocationAPI from 'services/apis/LocationsAPI';

const Tab2Module = ({ userData, onUpdate, isAuth }) => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();
  const [provinces, setProvinces] = useState([]);
  const localLocationAPI = new LocationAPI();
  const toast = useToast();

  useEffect(() => {
    let _mounted = true;
    if (_mounted) onGetProvinces();

    return () => {
      _mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGetProvinces = () => {
    localLocationAPI.fetchProvinces().then(response => {
      if (response && Array.isArray(response)) setProvinces(response);
      else toast(t('notification.province.error'), 'error');
    });
  };

  return (
    <Styled.Box>
      {!isAuth && (
        <Styled.Overlay>
          <div
            className={'title'}
            onClick={() => history.push('/authentication/')}
          >
            {t('auth.title')}
          </div>
        </Styled.Overlay>
      )}
      <Module.Personal
        userData={userData}
        provinces={provinces}
        onUpdate={onUpdate}
      />
      {userData.accountType !== 'Natural' && (
        <Module.Company
          userData={userData}
          provinces={provinces}
          onUpdate={onUpdate}
        />
      )}
    </Styled.Box>
  );
};

export default Tab2Module;
