import Header from 'layouts/Header';
import Loading from 'layouts/Loading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import UserAPI from 'services/apis/UserAPI';
import UserActions from 'store/stores/User/actions';
import Tab from 'uikit/Tab';
import { STEPS } from './constants';
import Module from './modules';
import Styled from './styles';

const Authentication = () => {
  const { t } = useTranslation('authentication');
  const dispatch = useDispatch();
  const [step, setStep] = useState('');
  const userData = useSelector(state => state.User.user);
  const authStep = useSelector(state => state.User.user?.authStep);
  const localUserAPI = new UserAPI();

  useEffect(() => {
    let _mounted = true;
    setTimeout(() => {
      if (_mounted) onCheckStep();
    }, 1000);

    return () => {
      _mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    onGetUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const onCheckStep = () => {
    const _step = STEPS.find(_step => _step.step.includes(authStep));
    setStep(t(_step?.name));
  };

  const onHandleStep = _step => {
    setStep(_step);
  };

  const onDisplayStep = _tab => {
    const _key = STEPS.find(_step => t(_step.name) === _tab)?.id;
    const _tabs = {
      1: <Module.Step1 userData={userData} onStep={onHandleStep} />,
      2: <Module.Step2 userData={userData} onStep={onHandleStep} />,
      3: <Module.Step3 userData={userData} onStep={onHandleStep} />,
      4: <Redirect to={'/stock/'} />
    };
    return _tabs[_key];
  };

  const onGetUserData = () => {
    localUserAPI.fetchProfile().then(response => {
      if (response && response.id) {
        dispatch(UserActions.SetUserData(response));
      }
    });
  };

  return (
    <Styled.Container>
      <Header
        title={{ color: t('title.color') }}
        subtitle={t('subtitle')}
        star={true}
      />
      {step.length === 0 ? (
        <Loading />
      ) : (
        <>
          <Tab
            init={step}
            border={false}
            big={false}
            onSelect={onHandleStep}
            allowed={[step]}
          >
            {STEPS.map((_step, _index) => (
              <div key={'step_item_' + _index} label={t(_step.name)} />
            ))}
          </Tab>

          {userData && onDisplayStep(step)}
        </>
      )}
    </Styled.Container>
  );
};

export default Authentication;
