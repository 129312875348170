import React from 'react';
import Module from './modules';
import Styled from './styles';

const Home = () => (
  <Styled.Container>
    <Module.Slider />
    <Module.Brands />
    <Module.Features />
    <Module.Steps />
    <Module.Packages />
    {/* <Module.News /> */}
  </Styled.Container>
);

export default Home;
