import Authentication from 'components/Authentication';
import Dashboard from 'components/Dashboard';
import DashboardStock from 'components/DashboardStock';
import DashboardStockResult from 'components/DashboardStock/result';
import Home from 'components/Home';
import Login from 'components/Login';
import Register from 'components/Register';
import Stock from 'components/Stock';
import Container from 'layouts/Container';
import Content from 'layouts/Content';
import Footer from 'layouts/Footer';
import Loading from 'layouts/Loading';
import Navbar from 'layouts/Navbar';
import NotFound from 'layouts/NotFound';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  useLocation
} from 'react-router-dom';
import UserAPI from 'services/apis/UserAPI';
import UserActions from 'store/stores/User/actions';
import Private from './private';
import Public from './public';
import { PERMISSIONS } from '../components/Dashboard/constants';
import ContactUs from 'components/ContactUs';

const _ScrollToTop = props => {
  const { pathname } = useLocation();
  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return props.children;
};
const ScrollToTop = withRouter(_ScrollToTop);

const Routes = () => {
  const dispatch = useDispatch();
  const localUserAPI = new UserAPI();
  const isLogin = useSelector(state => state.User.isLogin);
  const userData = useSelector(state => state.User.user);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const _today = Date.now();
  //   const _deadline = Date.parse('21 Apr 2021');
  //   const _days = Math.ceil((_deadline - _today) / 86400000);
  //   const _opacity = _days > 0 ? (_days >= 100 ? 1 : _days / 100) : 0;
  //   if (_opacity < 1) document.body.style.opacity = _opacity;
  // });

  useEffect(() => {
    if (isLogin) {
      onGetUserData();
    } else {
      onHandleNotAuthenticated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  const onGetUserData = () => {
    localUserAPI.fetchProfile().then(response => {
      if (response && response.id) {
        dispatch(UserActions.SetUserData(response));
        setTimeout(() => {
          setIsAuthenticated(true);
          setIsLoading(false);
        }, 1000);
      } else {
        onHandleNotAuthenticated();
      }
    });
  };

  const onHandleNotAuthenticated = () => {
    dispatch(UserActions.SetIsLogin(false));
    setIsAuthenticated(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <>
      {isLoading ? (
        <Loading full={true} />
      ) : (
        <Router>
          <ScrollToTop>
            <Container>
              <Navbar />
              <Content>
                <Switch>
                  <Route exact={true} path={'/'} component={Home} />
                  {/* <Route
                    exact={true}
                    path={'/contactus/'}
                    component={ContactUs}
                  /> */}

                  <Public
                    exact={true}
                    path={'/login/'}
                    component={Login}
                    auth={isAuthenticated}
                  />
                  <Public
                    exact={true}
                    path={'/register/'}
                    component={Register}
                    auth={isAuthenticated}
                  />
                  {/* <Public
                    exact={true}
                    path={'/contactus/'}
                    component={ContactUs}
                    auth={isAuthenticated}
                  /> */}
                  <Private
                    exact={true}
                    path={'/authentication/'}
                    component={Authentication}
                    auth={isAuthenticated}
                  />
                  <Private
                    exact={true}
                    path={'/stock/'}
                    component={Stock.Intro}
                    auth={isAuthenticated}
                  />
                  <Private
                    exact={true}
                    path={'/stock/buy/:tab([1-3]{0,1})/'}
                    component={Stock.Buy}
                    auth={isAuthenticated}
                  />
                  <Private
                    exact={true}
                    path={'/stock/result/:status([0-1]{1})'}
                    component={Stock.Result}
                    auth={isAuthenticated}
                  />
                  {userData?.permissions.includes(PERMISSIONS[1]) ? (
                    <Private
                      exact={true}
                      path={'/dashboard/'}
                      component={DashboardStock}
                      auth={isAuthenticated}
                    />
                  ) : (
                    <Private
                      exact={true}
                      path={'/dashboard/'}
                      component={Dashboard}
                      auth={isAuthenticated}
                    />
                  )}

                  <Private
                    exact={true}
                    path={'/dashboard/'}
                    component={Dashboard}
                    auth={isAuthenticated}
                  />
                  <Private
                    exact={true}
                    path={'/dashboard/stock/result/:status([0-1]{1})'}
                    component={DashboardStockResult}
                    auth={isAuthenticated}
                  />
                  <Route
                    exact={true}
                    path={'/faq/'}
                    component={() => <h1>FAQ</h1>}
                  />
                  <Route exact={true} path={'*'} component={NotFound} />
                </Switch>
              </Content>
              <Footer />
            </Container>
          </ScrollToTop>
        </Router>
      )}
    </>
  );
};

export default Routes;
