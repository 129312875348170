import Image from 'assets/images/features.png';
import Styled from 'components/Home/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FeaturesModule = () => {
  const { t } = useTranslation('home');
  const [active, setActive] = useState(1);

  return (
    <Styled.Features>
      <Styled.FeaturesContent>
        <div className={'container'}>
          <h3 className={'title'}>{t('features.title')}</h3>
          <div className={'subtitle'}>{t('features.subtitle')}</div>
          <Styled.FeaturesList>
            {/* <div
              className={`item ${active === 1 ? 'active' : ''}`}
              onClick={() => setActive(1)}
            >
              <span className={'icon icon-01'} />
              <div className={'text'}>
                <h4 className={'title'}>{t('features.item.title.1')}</h4>
                <div className={'description'}>
                  {t('features.item.description.1')}
                </div>
              </div>
            </div> */}

            <div
              className={`item ${active === 2 ? 'active' : ''}`}
              onClick={() => setActive(2)}
            >
              <span className={'icon icon-02'} />
              <div className={'text'}>
                <h4 className={'title'}>{t('features.item.title.2')}</h4>
                <div className={'description'}>
                  {t('features.item.description.2')}
                </div>
              </div>
            </div>

            {/* <div
              className={`item ${active === 3 ? 'active' : ''}`}
              onClick={() => setActive(3)}
            >
              <span className={'icon icon-03'} />
              <div className={'text'}>
                <h4 className={'title'}>{t('features.item.title.3')}</h4>
                <div className={'description'}>
                  {t('features.item.description.3')}
                </div>
              </div>
            </div> */}

            <div
              className={`item ${active === 4 ? 'active' : ''}`}
              onClick={() => setActive(4)}
            >
              <span className={'icon icon-04'} />
              <div className={'text'}>
                <h4 className={'title'}>{t('features.item.title.4')}</h4>
                <div className={'description'}>
                  {t('features.item.description.4')}
                </div>
              </div>
            </div>
          </Styled.FeaturesList>
        </div>
      </Styled.FeaturesContent>
      <img src={Image} alt={''} />
    </Styled.Features>
  );
};

export default FeaturesModule;
