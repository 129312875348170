import ApiHelper from 'services/config/http';
import { Files } from 'services/config/url';

class FilesAPI extends ApiHelper {
  uploadFiles = async body => {
    let result = {};
    await this.post(Files.uploadFiles, body)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };

  viewFiles = async body => {
    let result = {};
    await this.get(`${Files.viewFiles}/${body}`)
      .then(response => {
        result = response.data;
      })
      .catch(error => {
        if (error.response) {
          result = error.response.data;
        } else {
          result = this.error();
        }
      });
    return result;
  };
  downloadFile = async (fileId, name) => {
    let result = false;
    await this.http
      .get(`${Files.viewFiles}/${fileId}`, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        result = true;
      })
      .catch(() => {
        result = false;
      });
    return result;
  };
}

export default FilesAPI;
