import React from 'react';
import { useTranslation } from 'react-i18next';
import Styled from 'uikit/Uploader/styles';

const InputModule = ({
  className,
  labelClassName,
  labelWithFilesClassName,
  style,
  labelStyle,
  labelWithFilesStyle,
  getFilesFromEvent,
  accept,
  multiple,
  disabled,
  content,
  withFilesContent,
  onFiles,
  files,
  extra
}) => {
  const { t } = useTranslation('uikits');

  return (
    <Styled.InputLayout>
      <svg viewBox="0 0 68 44">
        <path
          d="M55.9141 20.0551C55.9141 13.2581 49.2272 8.45551 42.7963 10.6061C40.2443 4.25744 34.0769 0 27.0605 0C16.4332 0 8.40278 9.69983 10.3931 20.156C4.53648 20.9228 0 25.9455 0 32.0078C0 38.5988 5.36217 43.9609 11.9531 43.9609C29.1677 43.9609 50.8507 43.9609 56.0469 43.9609C62.6378 43.9609 68 38.5988 68 32.0078C68 25.4357 62.6329 19.9862 55.9141 20.0551ZM56.0469 39.9766C48.4995 39.9766 29.2917 39.9766 11.9531 39.9766C7.55916 39.9766 3.98438 36.4018 3.98438 32.0078C3.98438 27.2967 8.06756 23.5949 12.7844 24.0822C14.2435 24.234 15.3655 22.8009 14.8608 21.4176C11.774 12.9597 18.0727 3.98438 27.0605 3.98438C33.0544 3.98438 38.2384 8.04366 39.6671 13.8558C39.9937 15.1842 41.5386 15.8008 42.6913 15.0481C47.3082 12.0321 53.2846 16.4355 51.6758 21.7803C51.2299 23.262 52.5752 24.6669 54.0747 24.285C59.1164 23.0027 64.0156 26.8219 64.0156 32.0078C64.0156 36.4018 60.4408 39.9766 56.0469 39.9766Z"
          fill={`${extra.reject ? '#C71720' : '#D0C9DF'}`}
        />
        {extra.reject ? (
          <>
            <path
              d="m29.3852,12.5829c-0.7752,-0.7749 -2.0378,-0.7795 -2.8173,0l-3.9844,3.9844c-0.778,0.778 -0.778,2.0393 0,2.8173c0.7779,0.7781 2.0393,0.7781 2.8174,0l0.5867,-0.5867l0,7.1501c0,1.1002 0.892,1.9922 1.9922,1.9922c1.1002,0 1.9922,-0.892 1.9922,-1.9922l0,-7.1436l0.5836,0.5834c0.7779,0.778 2.0393,0.7782 2.8173,0c0.7781,-0.778 0.7781,-2.0393 0,-2.8173c-1.9046,-1.9046 -2.7634,-2.7636 -3.9877,-3.9876z"
              fill="#C71720"
            />
            <path
              d="m47.56352,25.47055l-2.46999,-2.41869l-4.93969,4.83714l-4.9397,-4.83714l-2.46997,2.41869l4.93969,4.83714l-4.93969,4.83714l2.46997,2.41869l4.9397,-4.83712l4.93969,4.83712l2.46999,-2.41869l-4.9397,-4.83714l4.9397,-4.83714z"
              fill="#C71720"
            />
          </>
        ) : (
          <path
            d="M33.9331 21.0751C33.1579 20.3001 31.8952 20.2956 31.1157 21.0751L27.1314 25.0595C26.3533 25.8375 26.3533 27.0988 27.1314 27.8768C27.9092 28.6548 29.1707 28.6548 29.9487 27.8768L30.5355 27.2901V34.4402C30.5355 35.5404 31.4275 36.4323 32.5277 36.4323C33.6279 36.4323 34.5199 35.5404 34.5199 34.4402V27.2966L35.1034 27.88C35.8813 28.658 37.1428 28.6582 37.9208 27.88C38.6988 27.102 38.6988 25.8407 37.9208 25.0627C36.0161 23.1581 35.1574 22.2991 33.9331 21.0751Z"
            fill="#D0C9DF"
          />
        )}
      </svg>

      <div className={'content'}>
        <span className={'text'}>{t('uploader.label_text')}</span>
        <span className={`format ${extra.reject ? 'error' : ''}`}>
          {t('uploader.label_formats')}
        </span>
        <span className={'size'}>{t('uploader.label_size')}</span>
      </div>

      <label className={'label'}>
        <input
          className={className}
          style={style}
          type={'file'}
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          onChange={async e => {
            const target = e.target;
            const chosenFiles = await getFilesFromEvent(e);
            // بررسی حجم فایل
            if (chosenFiles[0].size > 500 * 1024) {
              // نمایش پیغام خطا
              alert('حجم فایل آپلود شده بیشتر از 500 کیلوبایت است!');
            } else {
              onFiles(chosenFiles);
              //@ts-ignore
              target.value = null;
            }
          }}
        />
      </label>
    </Styled.InputLayout>
  );
};

export default InputModule;
