import Steps from './Modules/steps';
import Packages from './Modules/packages';
import Invoice from './Modules/invoice';
import Tabs from './Modules/tabs';
import Rules from './Modules/rules';

const Modules = {
  Steps,
  Packages,
  Invoice,
  Tabs,
  Rules
};

export default Modules;
